import { gql } from "@apollo/client";

export const MODIFY_FOOTER_MENU = gql`
  mutation (
    $id: Int!
    $title: String!
    $size: Int!
    $sort: Int
    $status: Int!
  ) {
    modifyFooterMenu(
      id: $id
      title: $title
      size: $size
      sort: $sort
      status: $status
    )
  }
`;

export const MODIFY_FOOTER_MENU_LINK = gql`
  mutation (
    $id: Int!
    $title: String!
    $link: String!
    $target: Int!
    $estate_type: Int!
    $object_type_id: Int!
    $props: String
    $footer_menu_id: Int!
    $sort: Int
    $status: Int
    $city_id: Int
    $area_id: Int
    $place_id: Int
    $point_id: Int
  ) {
    modifyFooterMenuLink(
      id: $id
      title: $title
      link: $link
      target: $target
      estate_type: $estate_type
      object_type_id: $object_type_id
      props: $props
      footer_menu_id: $footer_menu_id
      status: $status
      sort: $sort
      city_id: $city_id
      area_id: $area_id
      place_id: $place_id
      point_id: $point_id
    )
  }
`;

export const DELETE_FOOTER_MENU = gql`
  mutation ($id: Int!) {
    deleteFooterMenu(id: $id) {
      id
      title {
        lang
        value
      }
      size
    }
  }
`;

export const DELETE_FOOTER_MENU_LINK = gql`
  mutation ($id: Int!) {
    deleteFooterMenuLink(id: $id) {
      id
      title {
        lang
        value
      }
    }
  }
`;
