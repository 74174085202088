import React, { useEffect, useState } from "react";
import { Wrapper } from "../components/Wrapper";
import { PageTitle } from "../components/PageTitle";
import { CustomTable } from "../components/CustomTable";
import { useNavigate } from "react-router-dom";
import { Notification } from "../utils/Notification";
import { useMutation, useQuery } from "@apollo/client";
import { GET_PROMO_CODES } from "../graphql/Queries/PromoCodeQuery";
import moment from "moment";
import { finder, TYPE_PROMOCODE, USER_TYPE } from "../utils/Dictionaries";
import { DELETE_PROMOCODE } from "../graphql/Mutations/PromoCodeMutation";

export const PromoCode = ({ url }) => {
  const [items, setItems] = useState([]);
  const navigator = useNavigate();

  const { data } = useQuery(GET_PROMO_CODES, {
    fetchPolicy: "no-cache",
  });
  const [drop] = useMutation(DELETE_PROMOCODE);

  useEffect(() => {
    if (data) {
      setItems(data.promoCode);
    }
  }, [data]);

  const remove = (id) => {
    drop({
      variables: {
        id: Number(id),
      },
    })
      .then((response) => {
        setItems(response.data.deletePromoCode);
      })
      .catch((error) => {
        Notification("Промокод не может быть удален после применения");
      });
  };

  const edit = (id) => {
    navigator(`${url}/form?id=${id}`);
  };

  return (
    <Wrapper>
      <PageTitle title={"Промокоды"} />
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <CustomTable
                data={items}
                columns={[
                  { key: "id", label: "ID" },
                  { key: "name", label: "Название" },
                  { key: "price", label: "Стоимость, AZN" },
                  {
                    key: `start_date`,
                    label: "Действует с",
                    callback: (item) => {
                      return moment(item).format("DD.MM.YYYY");
                    },
                  },
                  {
                    key: "end_date",
                    label: "Действует до",
                    callback: (item) => {
                      return moment(item).format("DD.MM.YYYY");
                    },
                  },
                  { key: "code", label: "Код" },
                  {
                    key: "type",
                    label: "Тип",
                    callback: (item) => {
                      return finder(TYPE_PROMOCODE, item);
                    },
                  },
                  {
                    key: "user_type",
                    label: "Тип пользователя",
                    callback: (item) => {
                      return finder(USER_TYPE, item);
                    },
                  },
                ]}
                search={true}
                sort={true}
                pagination={{
                  limit: 10,
                }}
                actions={[
                  {
                    slug: `${url}/form`,
                    icon: "fe-plus",
                    name: "Создать",
                  },
                ]}
                buttons={[
                  {
                    onClick: (row) => {
                      navigator(`${url}/stat?code_id=${row.id}`);
                    },
                    icon: "mdi mdi-chart-line",
                    title: "Статистика",
                  },
                ]}
                onClick={(id) => {
                  return false;
                }}
                onDelete={(id) => remove(id)}
              />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
