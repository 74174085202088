import i18next from "i18next";
import Nestable from "react-nestable";

export const Property = ({ item, collapseIcon, handler }) => {
  const name = item.name.find((i) => {
    return i.lang === i18next.language;
  });
  return (
    <div className="dd-list" style={{ marginBottom: 10 }}>
      <div className="dd-item">
        <div className="dd-handle" style={{ background: "#c4c6c6" }}>
          <div>
            {handler}
            {collapseIcon}
            <span>{name.value}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
