import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Sidebar } from "./Sidebar";
import { Helmet } from "react-helmet";
import Logo from "../assets/images/city.az.svg";
import LogoLight from "../assets/images/light-city.az.svg";
import { User } from "./User";
import { Lang } from "./Header/Lang";
import { Notification } from "./Header/Notification";
import { Create } from "./Header/Create";
import { useDispatch, useSelector } from "react-redux";
import { setSidebar, setTheme } from "../redux/slices/configSlice";
import useWindowDimensions from "../hooks/useWindowDimensions";

export const Wrapper = ({ children }) => {
  const config = useSelector((state) => state.config);
  const dispatch = useDispatch();
  const { height, width } = useWindowDimensions();

  useEffect(() => {
    if (width >= 1140) {
      dispatch(setSidebar("default"));
    } else if (width >= 992) {
      dispatch(setSidebar("condensed"));
    } else {
      dispatch(setSidebar("full"));
    }
  }, [width]);

  const showBackdrop = () => {
    const backdrop = document.createElement("div");
    backdrop.id = "custom-backdrop";
    backdrop.classList = "offcanvas-backdrop fade show";
    document.body.appendChild(backdrop);

    if (
      document.getElementsByTagName("html")[0]?.getAttribute("dir") != "rtl"
    ) {
      document.body.style.overflow = "hidden";
      if (window.innerWidth > 1140) {
        document.body.style.paddingRight = "15px";
      }
    }
    backdrop.addEventListener("click", function (e) {
      document.documentElement.classList.remove("sidebar-enable");
      hideBackdrop();
    });
  };

  const hideBackdrop = () => {
    const backdrop = document.getElementById("custom-backdrop");
    if (backdrop) {
      document.body.removeChild(backdrop);
      document.body.style.overflow = null;
      document.body.style.paddingRight = null;
    }
  };
  const htmlElement = document.documentElement;
  const changeLeftbarSize = (size, save = true) => {
    htmlElement.setAttribute("data-sidebar-size", size);
  };

  return (
    <>
      <Helmet>
        <html
          lang="ru"
          data-topbar-color="brand"
          data-bs-theme={config.theme}
          data-menu-color="light"
          data-sidebar-size={config.sidebar}
          data-layout-width="fluid"
          data-layout-position="fixed"
        />
      </Helmet>
      <div id="wrapper">
        {/*Topbar Start*/}
        <div className="navbar-custom">
          <div className="container-fluid">
            <ul className="list-unstyled topnav-menu float-end mb-0">
              <li className="d-none d-md-inline-block">
                <a
                  className="nav-link dropdown-toggle arrow-none waves-effect waves-light"
                  target={"_blank"}
                  href={"https://wiki.city.az"}
                >
                  <i className={"fe-book noti-icon"}></i>
                </a>
              </li>
              <li className="d-none d-md-inline-block">
                <a
                  className="nav-link dropdown-toggle arrow-none waves-effect waves-light"
                  onClick={() =>
                    dispatch(
                      setTheme(config.theme === "dark" ? "light" : "dark"),
                    )
                  }
                >
                  <i
                    className={
                      config.theme === "light"
                        ? "fe-moon noti-icon"
                        : "fe-sun noti-icon"
                    }
                  ></i>
                </a>
              </li>

              <Lang />
              <Notification />
              <User />
            </ul>

            <ul className="list-unstyled topnav-menu topnav-menu-left m-0">
              <li>
                <button
                  className="button-menu-mobile waves-effect waves-light"
                  onClick={() => {
                    const htmlElement = document.documentElement;
                    const size = htmlElement.getAttribute("data-sidebar-size");

                    if (size === "full") {
                      showBackdrop();
                    } else {
                      if (size === "condensed") {
                        changeLeftbarSize(
                          config.sidebar === "condensed"
                            ? "default"
                            : config.sidebar,
                          false,
                        );
                      } else {
                        changeLeftbarSize("condensed", false);
                      }
                    }

                    // Todo: old implementation
                    document.documentElement.classList.toggle("sidebar-enable");
                  }}
                >
                  <i className="fe-menu"></i>
                </button>
              </li>

              <li>
                {/*Mobile menu toggle (Horizontal Layout)*/}
                <a
                  className="navbar-toggle nav-link"
                  data-bs-toggle="collapse"
                  data-bs-target="#topnav-menu-content"
                >
                  <div className="lines">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </a>
                {/*End mobile menu toggle*/}
              </li>

              <Create />
            </ul>
            <div className="clearfix"></div>
          </div>
        </div>
        {/*Topbar End*/}

        {/*Left Sidebar Start*/}
        <div className="left-side-menu">
          {/*LOGO*/}
          <div className="logo-box">
            <Link to={"/home"} className="logo logo-dark text-center">
              <span className="logo-sm">
                <img src={Logo} alt="" height="24" />
              </span>
              <span className="logo-lg">
                <img src={Logo} alt="" height="20" />
              </span>
            </Link>

            <Link to={"/home"} className="logo logo-light text-center">
              <span className="logo-sm">
                <span className="logo-lg-text-light">
                  <i className="fe-home"></i>
                </span>
              </span>
              <span className="logo-lg">
                <img src={LogoLight} alt="" height="20" />
              </span>
            </Link>
          </div>

          <div className="h-100" style={{ overflowY: "auto" }}>
            <Sidebar />

            <div className="clearfix"></div>
          </div>
          {/*Sidebar -left*/}
        </div>
        {/*Left Sidebar End*/}

        {/*Start Page Content here*/}

        <div className="content-page">
          <div className="content">
            {/*Start Content*/}
            <div className="container-fluid">{children}</div>
            {/*container*/}
          </div>
          {/*content*/}
        </div>
      </div>
    </>
  );
};
