import React, { useEffect, useState } from "react";
import { Wrapper } from "../../components/Wrapper";
import { PageTitle } from "../../components/PageTitle";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Notification } from "../../utils/Notification";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Button } from "../../components/Button";
import { MultilangTextInput } from "../../components/Form/MultilangTextInput";
import { Col, Form, Row } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import i18next from "i18next";
import { Preload } from "../../components/Preload";
import { GET_DATA_OBJECT } from "../../graphql/Queries/ObjectQuery";
import { Selected } from "../../components/Form/Selected";
import {
  ESTATE_TYPE,
  HAS_COMPLEX,
  STATUS,
  TARGET,
} from "../../utils/Dictionaries";
import { MODIFY_OBJECT_TYPE } from "../../graphql/Mutations/ObjectTypeMutation";
import { FileInput } from "../../components/Form/FileInput";
import { TextInput } from "../../components/Form/TextInput";

export const TypeObjectForm = ({ id = null }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const [modify] = useMutation(MODIFY_OBJECT_TYPE);
  const { load, error, data } = useQuery(GET_DATA_OBJECT, {
    variables: {
      lang: i18next.language,
      id: Number(searchParams.get("id")),
    },
    fetchPolicy: "no-cache",
  });
  if (error) {
    toast.error(error.message);
  }
  const languages = [
    {
      url: "ru",
      label: t("Ру"),
    },
    {
      url: "az",
      label: t("Az"),
    },
    {
      url: "en",
      label: t("En"),
    },
  ];
  const formik = useFormik({
    initialValues: {
      id: 0,
      name: {
        ru: "",
        az: "",
        en: "",
      },
      target: 1,
      estate_type: 1,
      has_complex: 0,
      steps: [],
      propertyGroups: [],
      properties: [],
      enable_compare: 1,
      icon: [],
      words: {
        ru: "",
        az: "",
        en: "",
      },
      about_title: {
        ru: "",
        az: "",
        en: "",
      },
      status: 1,
      status_dev: 1,
      sort: 1,
      complexes: [],
      squad_type: 1,
    },
    validationSchema: Yup.object({
      name: Yup.object().shape({
        ru: Yup.string().required(t("Объязательное поле")),
      }),
    }),
    onSubmit: (values) => {
      setLoading(true);
      modify({
        variables: {
          id: values.id,
          name: JSON.stringify(formik.values.name),
          target: values.target,
          estate_type: values.estate_type,
          complexes: JSON.stringify(values.complexes),
          steps: JSON.stringify(formik.values.steps),
          propertyGroups: JSON.stringify(formik.values.propertyGroups),
          properties: JSON.stringify(formik.values.properties),
          enable_compare: Number(values.enable_compare),
          icon: JSON.stringify(values.icon),
          words: JSON.stringify(formik.values.words),
          about_title: JSON.stringify(formik.values.about_title),
          status: Number(values.status),
          status_dev: Number(values.status_dev),
          sort: Number(values.sort),
          squad_type: Number(values.squad_type),
        },
      })
        .then((response) => {
          if (!response.data.modifyObjectType) {
            Notification(t("Ошибка сохранения"));
          } else {
            navigate("/type-objects");
            Notification(t("Все хорошо!"), "success");
          }
        })
        .catch((error) => {
          Notification(error);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    if (data) {
      if (data.objectType[0]) {
        formik.setFieldValue("id", data.objectType[0].id);
        formik.setFieldValue("estate_type", data.objectType[0].estate_type);
        formik.setFieldValue(
          "squad_type",
          String(data.objectType[0].squad_type),
        );
        formik.setFieldValue(
          "complexes",
          data.objectType[0].complexes.map((complex) => {
            return complex.type_id;
          }),
        );
        formik.setFieldValue("sort", data.objectType[0].sort);
        formik.setFieldValue("icon", JSON.parse(data.objectType[0].icon));
        formik.setFieldValue(
          "enable_compare",
          data.objectType[0].enable_compare,
        );
        formik.setFieldValue("target", data.objectType[0].target);
        formik.setFieldValue(
          "propertyGroups",
          data.objectType[0].propertyGroups,
        );
        formik.setFieldValue("properties", data.objectType[0].properties);
        formik.setFieldValue("status", data.objectType[0].status);
        formik.setFieldValue("status_dev", data.objectType[0].status_dev);
        data.objectType[0].name.map((dic) => {
          formik.setFieldValue(`name.${dic.lang}`, dic.value);
        });
        data.objectType[0].words.map((dic) => {
          formik.setFieldValue(`words.${dic.lang}`, dic.value);
        });
        data.objectType[0].about_title.map((dic) => {
          formik.setFieldValue(`about_title.${dic.lang}`, dic.value);
        });
        const _steps = [...data.objectType[0].steps];
        data.steps.map((step) => {
          if (!_steps.includes(step.id) && step.is_system === 2) {
            _steps.push(step.id);
          }
        });
        formik.setFieldValue("steps", _steps);
      } else {
        const _steps = [];
        data.steps.map((step) => {
          if (!_steps.includes(step.id) && step.is_system === 2) {
            _steps.push(step.id);
          }
        });
        formik.setFieldValue("steps", _steps);
      }
      setSteps(data.steps);
    }
  }, [data]);

  if (!data) {
    return <Preload />;
  }

  return (
    <Wrapper>
      <PageTitle
        title={id === null ? "Добавить новый тип" : "Редактировать тип"}
        breadcrumbs={[
          {
            slug: "/type-objects",
            name: "Типы объектов",
          },
        ]}
      />
      <form className="row" onSubmit={formik.handleSubmit}>
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-4">
                  <MultilangTextInput
                    label={"Название"}
                    value={formik.values.name}
                    onChange={(text, lang) => {
                      formik.setFieldValue(`name.${lang}`, text);
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.errors.name}
                    touched={formik.touched.name}
                    name={"name"}
                  />
                </div>
                <div className="col-md-4">
                  <MultilangTextInput
                    label={"Слова для фильтрации"}
                    value={formik.values.words}
                    onChange={(text, lang) => {
                      formik.setFieldValue(`words.${lang}`, text);
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.errors.words}
                    touched={formik.touched.words}
                    name={"words"}
                  />
                </div>
                <div className="col-md-4">
                  <MultilangTextInput
                    label={"Название блока с параметрами"}
                    value={formik.values.about_title}
                    onChange={(text, lang) => {
                      formik.setFieldValue(`about_title.${lang}`, text);
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.errors.about_title}
                    touched={formik.touched.about_title}
                    name={"about_title"}
                  />
                </div>
                <div className="col-md-4">
                  <Selected
                    label={"Цель"}
                    onChange={(value) => {
                      formik.setFieldValue(`target`, value);
                    }}
                    error={formik.errors.target}
                    touched={formik.touched.target}
                    name={"target"}
                    staticOptions={TARGET}
                    style={{ paddingRight: 0 }}
                    value={formik.values.target}
                  />
                </div>
                <div className="col-md-4">
                  <Selected
                    label={"Тип недвижимости"}
                    onChange={(value) => {
                      formik.setFieldValue(`estate_type`, value);
                    }}
                    error={formik.errors.estate_type}
                    touched={formik.touched.estate_type}
                    name={"estate_type"}
                    staticOptions={ESTATE_TYPE}
                    style={{ paddingRight: 0 }}
                    value={formik.values.estate_type}
                  />
                </div>
                <div className="col-md-4">
                  <Selected
                    label={"Тип площади"}
                    onChange={(value) => {
                      formik.setFieldValue(`squad_type`, value);
                    }}
                    error={formik.errors.squad_type}
                    touched={formik.touched.squad_type}
                    name={"squad_type"}
                    staticOptions={data?.squadTypeList}
                    style={{ paddingRight: 0 }}
                    value={formik.values.squad_type}
                  />
                </div>
                <div className="col-md-6">
                  <Selected
                    label={"Данные о доме"}
                    onChange={(value) => {
                      const _arr = value.map((item) => {
                        return item.value;
                      });
                      formik.setFieldValue(`complexes`, _arr);
                    }}
                    error={formik.errors.complexes}
                    touched={formik.touched.complexes}
                    name={"complexes"}
                    staticOptions={[...data?.list]}
                    style={{ paddingRight: 0 }}
                    value={formik.values.complexes}
                    tags={true}
                  />
                </div>
                <div className="col-md-6">
                  <Selected
                    label={"Участвует в сравнении"}
                    onChange={(value) => {
                      formik.setFieldValue(`enable_compare`, value);
                    }}
                    error={formik.errors.enable_compare}
                    touched={formik.touched.enable_compare}
                    name={"enable_compare"}
                    staticOptions={HAS_COMPLEX}
                    style={{ paddingRight: 0 }}
                    value={formik.values.enable_compare}
                  />
                </div>
                {/*<div className="col-md-6">*/}
                {/*  <Selected*/}
                {/*    label={"Наличие комнат в фильтрации"}*/}
                {/*    onChange={(value) => {*/}
                {/*      formik.setFieldValue(`has_floor`, value);*/}
                {/*    }}*/}
                {/*    error={formik.errors.has_floor}*/}
                {/*    touched={formik.touched.has_floor}*/}
                {/*    name={"has_floor"}*/}
                {/*    staticOptions={[*/}
                {/*      { id: 0, name: "нет" },*/}
                {/*      { id: 1, name: "да" },*/}
                {/*    ]}*/}
                {/*    style={{ paddingRight: 0 }}*/}
                {/*    value={formik.values.has_floor}*/}
                {/*  />*/}
                {/*</div>*/}
                <div className="col-md-6">
                  <Selected
                    label={"Состояние"}
                    onChange={(value) => {
                      formik.setFieldValue(`status`, value);
                    }}
                    error={formik.errors.status}
                    touched={formik.touched.status}
                    name={"status"}
                    staticOptions={STATUS}
                    style={{ paddingRight: 0 }}
                    value={formik.values.status}
                  />
                </div>
                <div className="col-md-6">
                  <Selected
                    label={"Состояние на DEV"}
                    onChange={(value) => {
                      formik.setFieldValue(`status_dev`, value);
                    }}
                    error={formik.errors.status_dev}
                    touched={formik.touched.status_dev}
                    name={"status_dev"}
                    staticOptions={STATUS}
                    style={{ paddingRight: 0 }}
                    value={formik.values.status_dev}
                  />
                </div>
                <div className="col-md-6">
                  <TextInput
                    label={"Порядок вывода"}
                    value={formik.values.sort}
                    onChange={(e) => {
                      formik.setFieldValue(`sort`, e.target.value);
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.errors.sort}
                    touched={formik.touched.sort}
                    name={"sort"}
                  />
                </div>
                <div className="col-md-12">
                  <FileInput
                    label={"Иконка"}
                    onChange={(file, lang) => {
                      let _image = [];
                      if (formik.values.icon) {
                        _image = [...formik.values.icon];
                      }
                      _image.push(file);
                      formik.setFieldValue(`icon`, _image);
                    }}
                    value={formik.values.icon}
                    multilang={false}
                    onDelete={() => {
                      formik.setFieldValue("icon", []);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <h4>{t("Укажите шаги")}</h4>
              {data?.steps.map((step) => {
                const name = step.name.find((dic) => {
                  return dic.lang === i18next.language;
                });
                const active = formik.values.steps.includes(step.id);
                const propStep = steps.find((findModel) => {
                  return findModel.id === step.id;
                });
                return (
                  <Row key={step.id}>
                    <Col md={12}>
                      <Form.Check
                        type="switch"
                        checked={active}
                        label={name.value}
                        onChange={() => {
                          if (step.is_system === 1) {
                            const _steps = [...formik.values.steps];
                            if (!active) {
                              _steps.push(step.id);
                            } else {
                              _steps.splice(_steps.indexOf(step.id), 1);
                            }
                            formik.setFieldValue("steps", _steps);
                          }
                        }}
                      />
                    </Col>
                    {active && step.property_groups.length > 0 && (
                      <Col md={{ span: 11, offset: 1 }}>
                        <h5>{t("Укажите группы свойств")}</h5>
                        {step.property_groups.map((prop) => {
                          const name = prop.name.find((dic) => {
                            return dic.lang === i18next.language;
                          });
                          const activeProp =
                            formik.values.propertyGroups.includes(prop.id);
                          return (
                            <Row key={prop.id}>
                              <Col md={12}>
                                <Form.Check
                                  type="switch"
                                  checked={activeProp}
                                  label={name.value}
                                  onChange={() => {
                                    const _steps = [
                                      ...formik.values.propertyGroups,
                                    ];
                                    if (!activeProp) {
                                      _steps.push(prop.id);
                                    } else {
                                      _steps.splice(_steps.indexOf(prop.id), 1);
                                    }
                                    formik.setFieldValue(
                                      "propertyGroups",
                                      _steps,
                                    );
                                  }}
                                />
                              </Col>
                              {activeProp && (
                                <Col md={{ span: 11, offset: 1 }}>
                                  <h5>{t("Укажите свойства")}</h5>
                                  {prop.properties.map((item) => {
                                    const name = item.name.find((dic) => {
                                      return dic.lang === i18next.language;
                                    });
                                    const activeItem =
                                      formik.values.properties.includes(
                                        item.id,
                                      );
                                    return (
                                      <Row key={item.id}>
                                        <Col md={12}>
                                          <Form.Check
                                            type="switch"
                                            checked={activeItem}
                                            label={name.value}
                                            onChange={() => {
                                              const _steps = [
                                                ...formik.values.properties,
                                              ];
                                              if (!activeItem) {
                                                _steps.push(item.id);
                                              } else {
                                                _steps.splice(
                                                  _steps.indexOf(item.id),
                                                  1,
                                                );
                                              }
                                              formik.setFieldValue(
                                                "properties",
                                                _steps,
                                              );
                                            }}
                                          />
                                        </Col>
                                      </Row>
                                    );
                                  })}
                                </Col>
                              )}
                            </Row>
                          );
                        })}
                      </Col>
                    )}
                  </Row>
                );
              })}
              <div className="col-md-12 text-center">
                <Button
                  label={"Сохранить"}
                  loading={loading}
                  style={"btn btn-primary w-md"}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </Wrapper>
  );
};
