import { gql } from "@apollo/client";

export const GET_FOOTER_MENU = gql`
  query {
    footerMenu {
      id
      title {
        lang
        value
      }
      size
    }
  }
`;

export const GET_FOOTER_MENU_ONE = gql`
  query ($id: Int) {
    footerMenu(id: $id) {
      id
      title {
        lang
        value
      }
      size
      sort
      status
    }
  }
`;

export const GET_FOOTER_MENU_LINKS = gql`
  query ($footer_menu_id: Int) {
    footerMenuLink(footer_menu_id: $footer_menu_id) {
      id
      title {
        lang
        value
      }
    }
  }
`;

export const GET_FOOTER_MENU_LINK = gql`
  query ($id: Int!, $lang: String!) {
    footerMenuLink(id: $id) {
      id
      title {
        lang
        value
      }
      link
      object_type_id
      estate_type
      target
      props {
        property_id
        value
      }
      sort
      status
      city_id
      area_id
      place_id
      point_id
    }
    cities(country_id: 1) {
      id
      nameString(lang: $lang)
    }
    point {
      id
      nameString(lang: $lang)
    }
  }
`;
