import { gql } from "@apollo/client";

export const MODIFY_PLACE = gql`
  mutation (
    $id: Int!
    $name: String!
    $area_id: Int!
    $lat: String!
    $lng: String!
  ) {
    modifyPlace(id: $id, name: $name, area_id: $area_id, lat: $lat, lng: $lng)
  }
`;

export const DELETE_PLACE = gql`
  mutation ($id: Int!) {
    deletePlace(id: $id) {
      id
      name {
        lang
        value
      }
      area {
        name {
          lang
          value
        }
      }
    }
  }
`;
