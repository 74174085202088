import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import authReducer from "./slices/authSlice.js";
import configReducer from "./slices/configSlice.js";
import thunk from "redux-thunk";
import badgeSlice from "./slices/badgeSlice";
import tableSlice from "./slices/tableSlice";

const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: ["auth", "badge"],
  timeout: 1000,
};

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    auth: authReducer,
    config: configReducer,
    badge: badgeSlice,
    table: tableSlice,
  }),
);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: [thunk],
});

export const persistor = persistStore(store);
