import { gql } from "@apollo/client";

export const MODIFY_SERVICE = gql`
  mutation (
    $id: Int!
    $name: String!
    $name_short: String!
    $text: String!
    $key: String!
    $icon: String
    $variants: String
  ) {
    modifyService(
      id: $id
      name: $name
      name_short: $name_short
      text: $text
      key: $key
      icon: $icon
      variants: $variants
    )
  }
`;

export const DELETE_SERVICE = gql`
  mutation ($id: Int!) {
    deleteService(id: $id) {
      id
      name {
        lang
        value
      }
    }
  }
`;
