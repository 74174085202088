import React, { useEffect, useState } from "react";
import { Wrapper } from "../../components/Wrapper";
import { PageTitle } from "../../components/PageTitle";
import { useFormik } from "formik";
import { Notification } from "../../utils/Notification";
import { useTranslation } from "react-i18next";
import { Button } from "../../components/Button";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Preload } from "../../components/Preload";
import { Selected } from "../../components/Form/Selected";
import { useMutation, useQuery } from "@apollo/client";
import i18next from "i18next";
import { GET_TICKET } from "../../graphql/Queries/TicketQuery";
import { TextInput } from "../../components/Form/TextInput";
import { MODIFY_TICKET } from "../../graphql/Mutations/TicketMutation";

export const TicketForm = ({ url }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  let location = useLocation();
  const id = location.state ? location.state.id : null;
  let [searchParams, setSearchParams] = useSearchParams();
  const [modify] = useMutation(MODIFY_TICKET);
  const { data } = useQuery(GET_TICKET, {
    variables: {
      id: Number(searchParams.get("id")),
      lang: i18next.language,
    },
    fetchPolicy: "no-cache",
  });
  const formik = useFormik({
    initialValues: {
      id: 0,
      created_at: "",
      phone: "",
      type: null,
      status: null,
      step: "",
      comment: "",
      estates: [],
    },
    onSubmit: (values) => {
      setLoading(true);
      modify({
        variables: {
          id: Number(values.id),
          status: Number(values.status),
          comment: values.comment,
        },
      })
        .then((response) => {
          navigate(url);
          Notification(t("Все хорошо!"), "success");
        })
        .catch((error) => {
          Notification(error);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  useEffect(() => {
    if (data && data.ticket[0]) {
      formik.setFieldValue("id", data.ticket[0].id);
      formik.setFieldValue("created_at", data.ticket[0].created_at);
      formik.setFieldValue("phone", data.ticket[0].phone);
      formik.setFieldValue("type", String(data.ticket[0].type));
      formik.setFieldValue("status", String(data.ticket[0].status));
      formik.setFieldValue("comment", data.ticket[0].comment);
      formik.setFieldValue("step", data.ticket[0].step);
      formik.setFieldValue("estates", data.ticket[0].estates);
    }
  }, [data]);

  if (!data) {
    return <Preload />;
  }

  return (
    <Wrapper>
      <PageTitle
        title={"Просмотр обращения"}
        breadcrumbs={[
          {
            slug: url,
            name: "Обращения",
          },
        ]}
      />
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <form className="row" onSubmit={formik.handleSubmit}>
                <div className="col-md-6">
                  <div className="table-responsive mb-2">
                    <table className="table table-centered border mb-0">
                      <thead className="bg-light">
                        <tr>
                          <th colSpan="2">{t("Данные обращения")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">ID:</th>
                          <td>{formik.values.id}</td>
                        </tr>
                        <tr>
                          <th scope="row">{t("Номер телефона")}:</th>
                          <td>{formik.values.phone}</td>
                        </tr>
                        <tr>
                          <th scope="row">{t("Дата и время")}:</th>
                          <td>{formik.values.created_at}</td>
                        </tr>
                        <tr>
                          <th scope="row">{t("Тип")}:</th>
                          <td>
                            {
                              data.listType.find((item) => {
                                return (
                                  Number(item.id) === Number(formik.values.type)
                                );
                              })?.name
                            }
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">{t("Шаг")}:</th>
                          <td>{formik.values.step}</td>
                        </tr>
                      </tbody>
                    </table>

                    <table className="table table-centered border mt-2">
                      <thead className="bg-light">
                        <tr>
                          <th colSpan="2">{t("Черновики")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {formik.values.estates.map((estate) => {
                          return (
                            <tr>
                              <td>
                                <a
                                  target={"_blank"}
                                  href={"/estates/form?id=" + estate.id}
                                >
                                  {estate.id}
                                </a>
                              </td>
                              <td>
                                <a
                                  target={"_blank"}
                                  href={"/estates/form?id=" + estate.id}
                                >
                                  {estate.title}
                                </a>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="col-md-6">
                  <Selected
                    label={"Состояние"}
                    onChange={(value) => {
                      formik.setFieldValue(`status`, value);
                    }}
                    error={formik.errors.status}
                    name={"status"}
                    staticOptions={data?.listStatus}
                    value={formik.values.status}
                    style={{ paddingRight: 0 }}
                  />
                  <TextInput
                    label={"Комментарий"}
                    value={formik.values.comment}
                    onChange={(event) => {
                      formik.setFieldValue(`comment`, event.target.value);
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.errors.comment}
                    touched={formik.touched.comment}
                    name={"comment"}
                  />
                </div>
                <div className="col-md-12 text-center">
                  <Button
                    label={"Сохранить"}
                    loading={loading}
                    style={"btn btn-primary w-md"}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
