import React from "react";
import { Wrapper } from "../../components/Wrapper";
import { PageTitle } from "../../components/PageTitle";
import { Notification } from "../../utils/Notification";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Preload } from "../../components/Preload";
import { CountryFormComponent } from "../../components/forms/CountryForm";
import { GET_COUNTRY } from "../../graphql/Queries/CountryQuery";
import { useQuery } from "@apollo/client";

export const CountryForm = ({ url }) => {
  const navigate = useNavigate();
  let location = useLocation();
  const id = location.state ? location.state.id : null;
  const { t } = useTranslation();
  let [searchParams, setSearchParams] = useSearchParams();
  const { data } = useQuery(GET_COUNTRY, {
    variables: {
      id: Number(searchParams.get("id")),
    },
    fetchPolicy: "no-cache",
  });

  if (!data) {
    return <Preload />;
  }

  return (
    <Wrapper>
      <PageTitle
        title={id === null ? "Добавить" : "Редактировать"}
        breadcrumbs={[
          {
            slug: url,
            name: "Страны",
          },
        ]}
      />
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <CountryFormComponent
                data={data.countries[0]}
                onSuccess={() => {
                  navigate(url);
                  Notification(t("Элемент добавлен"), "success");
                }}
                onError={(error) => Notification(error)}
                url={url}
              />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
