import { gql } from "@apollo/client";

export const GET_TEMPLATES = gql`
  query {
    templateNotification {
      id
      subject {
        value
        lang
      }
      comment
    }
  }
`;

export const GET_TEMPLATE = gql`
  query ($id: Int) {
    templateNotification(id: $id) {
      id
      subject {
        value
        lang
      }
      title {
        value
        lang
      }
      text {
        value
        lang
      }
      comment
      channel
    }
  }
`;
