import React, { useEffect, useState } from "react";
import { Wrapper } from "../components/Wrapper";
import { PageTitle } from "../components/PageTitle";
import { CustomTable } from "../components/CustomTable";
import { useNavigate } from "react-router-dom";
import { Notification } from "../utils/Notification";
import { useMutation, useQuery } from "@apollo/client";
import { Preload } from "../components/Preload";
import { GET_LANG } from "../graphql/Queries/LangQuery";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ADD_LANG, DELETE_LANG } from "../graphql/Mutations/LangMutation";
import { toast } from "react-toastify";

export const Lang = () => {
  const [items, setItems] = useState([]);
  const navigator = useNavigate();
  const { loading, error, data } = useQuery(GET_LANG, {
    fetchPolicy: "no-cache",
  });
  const [deleteDictionary] = useMutation(DELETE_LANG);
  const [addMessage] = useMutation(ADD_LANG);
  const [showModal, toggleModal] = useState(false);
  const { t } = useTranslation();
  const [action, setAction] = useState("add");
  const formik = useFormik({
    initialValues: {
      key: "",
      ru: "",
      az: "",
      en: "",
      edit: false,
    },
    validationSchema: Yup.object({
      key: Yup.string().required(t("Объязательное поле")),
      ru: Yup.string().required(t("Объязательное поле")),
    }),
    onSubmit: (values) => {
      addMessage({
        variables: {
          key: values.key,
          ru: values.ru,
          az: values.az,
          en: values.en,
          edit: values.edit,
        },
      })
        .then((res) => {
          if (res.data.updateLang === null) {
            toast.error(t("Данная фраза уже добавлена"));
          } else {
            setItems(res.data.updateLang);
            Notification(
              action === "edit"
                ? t("Элемент отредактирован")
                : t("Элемент добавлен"),
              "success",
            );
            toggleModal(false);
          }
        })
        .catch((error) => {
          Notification(error);
        });
    },
  });

  const remove = (key) => {
    deleteDictionary({
      variables: {
        key: key.key,
      },
    })
      .then((response) => {
        setItems(response.data.deleteLang);
      })
      .catch((error) => {
        Notification(t("Фраза с таким ключем уже есть в системе"));
      });
  };

  const edit = (id) => {
    const tr = items.find((item) => {
      return item.key === id;
    });
    formik.setValues(tr);
    formik.setFieldValue("edit", true);
    setAction("edit");
    toggleModal(true);
  };

  useEffect(() => {
    if (data) {
      setItems(data.lang);
    }
  }, [data]);

  if (!data) {
    return <Preload />;
  }

  return (
    <Wrapper>
      <PageTitle title={"Переводы"} />
      <Modal show={showModal}>
        <Modal.Header closeButton onClick={() => toggleModal(false)}>
          <Modal.Title>
            {action === "add"
              ? t("Добавление перевода")
              : t("Редактирование перевода")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={formik.handleSubmit}>
            <Form.Group as={Row} className="mb-2">
              <Form.Label column md={2}>
                {t("Ключ")}
              </Form.Label>
              <Col md={10}>
                <Form.Control
                  type={"text"}
                  disabled={action === "edit"}
                  value={formik.values.key}
                  onChange={(value) =>
                    formik.setFieldValue("key", value.target.value)
                  }
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2">
              <Form.Label column md={2}>
                {t("Русский")}
              </Form.Label>
              <Col md={10}>
                <Form.Control
                  type={"text"}
                  onChange={(value) =>
                    formik.setFieldValue("ru", value.target.value)
                  }
                  value={formik.values.ru}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2">
              <Form.Label column md={2}>
                {t("Azerbaijani")}
              </Form.Label>
              <Col md={10}>
                <Form.Control
                  type={"text"}
                  onChange={(value) =>
                    formik.setFieldValue("az", value.target.value)
                  }
                  value={formik.values.az}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2">
              <Form.Label column md={2}>
                {t("English")}
              </Form.Label>
              <Col md={10}>
                <Form.Control
                  type={"text"}
                  onChange={(value) =>
                    formik.setFieldValue("en", value.target.value)
                  }
                  value={formik.values.en}
                />
              </Col>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={() => formik.handleSubmit()}>
            {t("Сохранить")}
          </Button>
          <Button variant="secondary" onClick={() => toggleModal(false)}>
            {t("Закрыть")}
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <CustomTable
                data={items}
                columns={[
                  { key: "key", label: "Ключ" },
                  { key: "ru", label: "Русский" },
                  { key: "az", label: "Azerbaijani" },
                  { key: "en", label: "English" },
                ]}
                search={true}
                sort={true}
                pagination={{
                  limit: 10,
                }}
                actions={[
                  {
                    slug: "/steps/form",
                    icon: "fe-plus",
                    name: "Создать",
                    callback: () => {
                      formik.setValues({
                        key: "",
                        ru: "",
                        az: "",
                        en: "",
                        edit: false,
                      });
                      setAction("add");
                      toggleModal(true);
                    },
                  },
                ]}
                onClick={(id) => edit(id)}
                onDelete={(id) => remove(id)}
                primaryKey={"key"}
              />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
