import { gql } from "@apollo/client";

export const ADD_LANG = gql`
  mutation (
    $key: String!
    $ru: String!
    $az: String!
    $en: String!
    $edit: Boolean!
  ) {
    updateLang(key: $key, ru: $ru, az: $az, en: $en, edit: $edit) {
      key
      ru
      az
      en
    }
  }
`;

export const DELETE_LANG = gql`
  mutation ($key: String!) {
    deleteLang(key: $key) {
      key
      ru
      az
      en
    }
  }
`;
