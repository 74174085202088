import { gql } from "@apollo/client";

export const MODIFY_TICKET = gql`
  mutation ($id: Int!, $comment: String, $status: Int!) {
    modifyTicket(id: $id, comment: $comment, status: $status)
  }
`;

export const DELETE_TICKET = gql`
  mutation ($id: Int!) {
    deleteTicket(id: $id) {
      id
      created_at
      phone
      type
      status
    }
  }
`;
