import React from "react";
import { Wrapper } from "../../components/Wrapper";
import { PageTitle } from "../../components/PageTitle";
import { Notification } from "../../utils/Notification";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Preload } from "../../components/Preload";
import { useQuery } from "@apollo/client";
import { FaqTabsFormComponent } from "../../components/forms/FaqTabsFormComponent";
import { GET_FAQ_TAB } from "../../graphql/Queries/FaqQuery";

export const FaqTabsForm = ({ url }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const { data } = useQuery(GET_FAQ_TAB, {
    variables: {
      id: Number(searchParams.get("id")),
    },
    fetchPolicy: "no-cache",
  });

  if (!data) {
    return <Preload />;
  }

  return (
    <Wrapper>
      <PageTitle
        title={
          searchParams.get("id") === null
            ? "Добавить группу"
            : "Редактировать группу"
        }
        breadcrumbs={[
          {
            slug: url,
            name: "Группы для FAQ",
          },
        ]}
      />
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <FaqTabsFormComponent
                onSuccess={() => {
                  navigate(url);
                  Notification(t("Элемент добавлен"), "success");
                }}
                onError={(error) => Notification(error)}
                url={url}
                data={data}
              />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
