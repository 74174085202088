import React, { useEffect, useState } from "react";
import { Wrapper } from "../../components/Wrapper";
import { PageTitle } from "../../components/PageTitle";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Notification } from "../../utils/Notification";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Button } from "../../components/Button";
import { MultilangTextInput } from "../../components/Form/MultilangTextInput";
import { Col, Tab, Tabs } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import i18next from "i18next";
import { Preload } from "../../components/Preload";
import { Selected } from "../../components/Form/Selected";
import {
  DROP_TEMPLATE,
  ESTATE_TYPE,
  LANG,
  POSITION,
  STATUS,
  TARGET,
  TEMPLATES,
} from "../../utils/Dictionaries";
import { ADD_PAGE, EDIT_PAGE } from "../../graphql/Mutations/PageMutation";
import { GET_PAGE } from "../../graphql/Queries/Page";
import { Checkbox } from "../../components/Form/Checkbox";
import { FileInput } from "../../components/Form/FileInput";
import { TextInput } from "../../components/Form/TextInput";
import { Editor } from "../../components/Form/Editor";
import { SEO } from "../../components/Seo";

export const PagesForm = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [value, setValue] = useState({ ru: "", en: "", az: "" });
  const [key, setKey] = useState("");
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const [editPage] = useMutation(EDIT_PAGE);
  const [addPage] = useMutation(ADD_PAGE);
  const id = Number(searchParams.get("id"));
  const position = Number(searchParams.get("position"));
  const { load, error, data } = useQuery(GET_PAGE, {
    variables: {
      id: id,
      type: position,
      lang: i18next.language,
    },
    fetchPolicy: "no-cache",
  });
  if (error) {
    toast.error(error.message);
  }
  const languages = LANG;
  const defaultLang = i18next.language;
  const formik = useFormik({
    initialValues: {
      name: {
        ru: "",
        az: "",
        en: "",
      },
      slug: "",
      position: position,
      template: 1,
      status: 1,
      icon: null,
      drop_template: 1,
      parent_id: 0,
      target: null,
      estate_type: null,
      object_type_id: null,
      text: {
        ru: "",
        az: "",
        en: "",
      },
    },
    validationSchema: Yup.object({
      name: Yup.object().shape({
        az: Yup.string().required(t("Объязательное поле")),
      }),
      slug: Yup.string().required(t("Объязательное поле")),
    }),
    onSubmit: (values) => {
      setLoading(true);
      if (Number(formik.values.id) > 0) {
        editPage({
          variables: {
            id: Number(searchParams.get("id")),
            name: JSON.stringify(formik.values.name),
            slug: formik.values.slug,
            position: values.position,
            status: values.status,
            template: values.template,
            drop_template: Number(values.drop_template),
            icon: values.icon,
            parent_id: Number(values.parent_id),
            target: Number(values.target),
            estate_type: Number(values.estate_type),
            object_type_id: Number(values.object_type_id),
            text: JSON.stringify(values.text),
          },
        })
          .then((response) => {
            if (!response.data.updateMenu) {
              toast.error(t("Ошибка сохранения"));
            } else {
              navigate("/pages");
              Notification(t("Элемент отредактирован"), "success");
            }
          })
          .catch((error) => {
            Notification(error);
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        addPage({
          variables: {
            name: JSON.stringify(formik.values.name),
            slug: formik.values.slug,
            position: values.position,
            status: values.status,
            template: values.template,
            drop_template: Number(values.drop_template),
            icon: values.icon,
            parent_id: Number(values.parent_id),
            target: Number(values.target),
            estate_type: Number(values.estate_type),
            object_type_id: Number(values.object_type_id),
            text: JSON.stringify(values.text),
          },
        })
          .then((response) => {
            if (!response.data.addMenu) {
              Notification("Ошибка добавления");
            } else {
              navigate("/pages");
              Notification(t("Страница добавлена"), "success");
            }
          })
          .catch((error) => {
            Notification(error);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    },
  });
  const [complete, setComplete] = useState(false);
  const [typeObjects, setTypeObjects] = useState([]);

  useEffect(() => {
    if (data && data.menu.length > 0) {
      data.menu[0].name.map((dic) => {
        formik.setFieldValue(`name.${dic.lang}`, dic.value);
      });
      data.menu[0].text.map((dic) => {
        formik.setFieldValue(`text.${dic.lang}`, dic.value);
      });
      formik.setFieldValue("slug", data.menu[0].slug);
      formik.setFieldValue("status", data.menu[0].status);
      formik.setFieldValue("id", data.menu[0].id);
      formik.setFieldValue("parent_id", data.menu[0].parent_id);
      formik.setFieldValue("position", data.menu[0].position);
      formik.setFieldValue("sort", data.menu[0].sort);
      formik.setFieldValue("template", data.menu[0].template);
      formik.setFieldValue("drop_template", data.menu[0].drop_template);
      if (data.menu[0].icon) {
        const icons = JSON.parse(data.menu[0].icon);
        formik.setFieldValue("icon", icons[0]);
      }
      formik.setFieldValue("target", data.menu[0].target);
      formik.setFieldValue("estate_type", data.menu[0].estate_type);
      formik.setFieldValue("object_type_id", data.menu[0].object_type_id);
      setComplete(true);
    } else if (data && data.menu.length === 0) {
      setComplete(true);
    }
    if (data && data.objectType) {
      setTypeObjects(data.objectType);
    }
  }, [data]);

  if (!complete) {
    return <Preload />;
  }

  return (
    <Wrapper>
      <PageTitle
        title={id === 0 ? "Добавить страницу" : "Редактировать страницу"}
        breadcrumbs={[
          {
            slug: "/pages",
            name: "Страницы",
          },
        ]}
      />
      <div className="row">
        <div className="col-md-12">
          <Tabs defaultActiveKey={"info"} className={"multilang-tab"}>
            <Tab title={t("Основная информация")} eventKey={"info"}>
              <div className="card">
                <div className="card-body">
                  <form className="row" onSubmit={formik.handleSubmit}>
                    <div className="col-md-6">
                      <MultilangTextInput
                        label={"Название"}
                        value={formik.values.name}
                        onChange={(text, lang) => {
                          formik.setFieldValue(`name.${lang}`, text);
                        }}
                        onBlur={formik.handleBlur}
                        error={formik.errors.name}
                        touched={formik.touched.name}
                        name={"name"}
                      />
                    </div>
                    <div className="col-md-6 mt-4">
                      <TextInput
                        label={"Ссылка"}
                        value={formik.values.slug}
                        onChange={(event) => {
                          formik.setFieldValue(`slug`, event.target.value);
                        }}
                        onBlur={formik.handleBlur}
                        error={formik.errors.slug}
                        touched={formik.touched.slug}
                        name={"slug"}
                      />
                    </div>
                    <div className="col-md-6">
                      <Selected
                        label={"Шаблон"}
                        onChange={(value) => {
                          formik.setFieldValue(`template`, value);
                        }}
                        error={formik.errors.template}
                        touched={formik.touched.template}
                        name={"template"}
                        staticOptions={TEMPLATES}
                        style={{ paddingRight: 0 }}
                        value={formik.values.template}
                      />
                    </div>
                    {formik.values.template === 3 && (
                      <>
                        <div className="col-md-6">
                          <Selected
                            label={"Цель"}
                            onChange={(value) => {
                              formik.setFieldValue(`target`, value);
                            }}
                            error={formik.errors.target}
                            touched={formik.touched.target}
                            name={"target"}
                            staticOptions={TARGET}
                            style={{ paddingRight: 0 }}
                            value={formik.values.target}
                          />
                        </div>
                        <div className="col-md-6">
                          <Selected
                            label={"Тип недвижимости"}
                            onChange={(value) => {
                              formik.setFieldValue(`estate_type`, value);
                            }}
                            error={formik.errors.estate_type}
                            touched={formik.touched.estate_type}
                            name={"estate_type"}
                            staticOptions={ESTATE_TYPE}
                            style={{ paddingRight: 0 }}
                            value={formik.values.estate_type}
                          />
                        </div>
                        <div className="col-md-6">
                          <Selected
                            label={"Тип объекта"}
                            onChange={(value) => {
                              formik.setFieldValue(`object_type_id`, value);
                            }}
                            error={formik.errors.object_type_id}
                            touched={formik.touched.object_type_id}
                            name={"object_type_id"}
                            staticOptions={typeObjects}
                            style={{ paddingRight: 0 }}
                            rows={{ value: "id", label: "nameString" }}
                            value={formik.values.object_type_id}
                          />
                        </div>
                      </>
                    )}
                    <div className="col-md-6">
                      <Selected
                        label={"Тип меню"}
                        onChange={(value) => {
                          formik.setFieldValue(`position`, value);
                        }}
                        error={formik.errors.position}
                        touched={formik.touched.position}
                        name={"position"}
                        staticOptions={POSITION}
                        style={{ paddingRight: 0 }}
                        value={formik.values.position}
                      />
                    </div>
                    <div className="col-md-6">
                      <Selected
                        label={"Состояние"}
                        onChange={(value) => {
                          formik.setFieldValue(`status`, value);
                        }}
                        error={formik.errors.status}
                        touched={formik.touched.status}
                        name={"position"}
                        staticOptions={STATUS}
                        style={{ paddingRight: 0 }}
                        value={formik.values.status}
                      />
                    </div>
                    <div className="col-md-6">
                      <Selected
                        label={"Родительское меню"}
                        onChange={(value) => {
                          formik.setFieldValue(`parent_id`, value);
                        }}
                        error={formik.errors.parent_id}
                        touched={formik.touched.parent_id}
                        name={"parent_id"}
                        staticOptions={JSON.parse(data.menuTree)}
                        style={{ paddingRight: 0 }}
                        value={formik.values.parent_id}
                      />
                    </div>
                    {formik.values.parent_id === 0 && (
                      <div className="col-md-6">
                        <Selected
                          label={"Шаблон выпадающего меню"}
                          onChange={(value) => {
                            formik.setFieldValue(`drop_template`, value);
                          }}
                          error={formik.errors.drop_template}
                          touched={formik.touched.drop_template}
                          name={"drop_template"}
                          staticOptions={DROP_TEMPLATE}
                          style={{ paddingRight: 0 }}
                          value={formik.values.drop_template}
                        />
                      </div>
                    )}
                    {formik.values.parent_id > 0 && (
                      <>
                        <div className="col-md-12">
                          <FileInput
                            label={"Иконка"}
                            onChange={(file, lang) => {
                              formik.setFieldValue(`icon`, file);
                            }}
                            value={formik.values.icon}
                            multilang={false}
                          />
                        </div>
                      </>
                    )}
                    {formik.values.template === 2 && (
                      <div className="col-md-12">
                        <Editor
                          value={formik.values.text}
                          onChange={(text, lang) => {
                            formik.setFieldValue(`text.${lang}`, text);
                          }}
                          name={"text"}
                          label={"Текст"}
                        />
                      </div>
                    )}
                    <div className="col-md-12 text-center">
                      <Button
                        label={"Сохранить"}
                        loading={loading}
                        style={"btn btn-primary w-md"}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </Tab>
            <Tab title={t("SEO")} eventKey={"seo"}>
              <SEO id={id} table={"menu"} />
            </Tab>
          </Tabs>
        </div>
      </div>
    </Wrapper>
  );
};
