import { gql } from "@apollo/client";

export const GET_OBJECT_TYPE = gql`
  query {
    objectType {
      id
      name {
        lang
        value
      }
    }
  }
`;

export const GET_QUICK_FILTERS = gql`
  query ($id: Int!) {
    quickFilter {
      id
      name
    }
    objectTypeQuickFilter(object_type_id: $id) {
      quick_filter_id
    }
  }
`;
