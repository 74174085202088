import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sidebar: "default",
  theme: "light",
  lang: "ru",
};

const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    setSidebar: (state, action) => {
      state.sidebar = action.payload;
    },
    setTheme: (state, action) => {
      state.theme = action.payload;
    },
    setLang: (state, action) => {
      state.lang = action.payload;
    },
  },
});

export const { setSidebar, setTheme, setLang } = configSlice.actions;

export default configSlice.reducer;
