import styles from "../Styles.module.scss";
import Piccer from "../../Piccer/Piccer";
import React, { useEffect, useState } from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";

export const FileInputTab = ({
  label,
  lang,
  value,
  onChange,
  onDelete,
  onSort,
}) => {
  const [file, setFile] = useState(null);
  const [images, setImages] = useState([]);

  useEffect(() => {
    setImages(value);
  }, [value]);

  const SortableItem = React.memo(
    SortableElement(({ pic, index }) => {
      return (
        <div className={styles.imageContainer} key={"img-" + index}>
          <img src={pic} alt={"Image"} />
          <div className={styles.imageBack}>
            <div>
              <button
                type="button"
                className="btn btn-primary waves-effect waves-light"
                onClick={() => setFile(pic)}
              >
                <i className={"fe-edit-2"} />
              </button>
              <button
                type="button"
                onClick={() => onDelete(pic, lang)}
                className="btn btn-danger waves-effect waves-light"
              >
                <i className={"fe-trash"} />
              </button>
            </div>
          </div>
        </div>
      );
    }),
  );

  const SortableList = React.memo(
    SortableContainer(({ items }) => {
      return (
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {items &&
            items.map((item, index) => {
              return (
                <SortableItem key={`item-${index}`} index={index} pic={item} />
              );
            })}
        </div>
      );
    }),
  );

  return (
    <div className="form-floating mb-3">
      <div className={styles.fileInput}>
        <label>{label}</label>
        <Piccer
          onComplete={(file) =>
            onChange(file.source ? file.source : file, lang)
          }
          data={file}
        />
        {value && !Array.isArray(value) && (
          <div className={styles.imageContainer}>
            <img src={value} />
            <div className={styles.imageBack}>
              <div>
                {!value.includes(".svg") && (
                  <button
                    type="button"
                    className="btn btn-primary waves-effect waves-light"
                    onClick={() => setFile(value)}
                  >
                    <i className={"fe-edit-2"} />
                  </button>
                )}
                <button
                  type="button"
                  onClick={() => onDelete(value, lang)}
                  className="btn btn-danger waves-effect waves-light"
                >
                  <i className={"fe-trash"} />
                </button>
              </div>
            </div>
          </div>
        )}
        {value && Array.isArray(value) && (
          <SortableList
            pressDelay={200}
            axis={"x"}
            items={images}
            onSortEnd={({ oldIndex, newIndex, collection, isKeySorting }) => {
              let _items = [...images];
              let item = _items.splice(oldIndex, 1)[0];
              _items.splice(newIndex, 0, item);
              setImages(_items);
              onSort(_items);
            }}
          />
        )}
      </div>
    </div>
  );
};
