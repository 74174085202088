import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  estates: {},
};

const tableSlice = createSlice({
  name: "table",
  initialState,
  reducers: {
    updateQuery(state, action) {
      state[action.payload.page] = action.payload.data;
    },
  },
});

export const { updateQuery } = tableSlice.actions;

export default tableSlice.reducer;
