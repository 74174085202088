import { gql } from "@apollo/client";

export const GET_METRO = gql`
  query ($lang: String) {
    metro {
      id
      name {
        lang
        value
      }
      metro_line {
        nameString(lang: $lang)
      }
    }
  }
`;

export const GET_ONE_METRO = gql`
  query ($id: Int!, $lang: String) {
    metro(id: $id) {
      id
      name {
        lang
        value
      }
      metro_line {
        id
        name {
          lang
          value
        }
      }
      lat
      lng
      icon
    }
    metroLine {
      id
      nameString(lang: $lang)
    }
  }
`;
