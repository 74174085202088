import { gql } from "@apollo/client";

export const GET_PAGES = gql`
  query {
    menu {
      id
      name {
        lang
        value
      }
      slug
      sort
      position
      template
      status
      parent_id
    }
  }
`;

export const GET_PAGE = gql`
  query ($id: Int!, $type: Int!, $lang: String!) {
    menu(id: $id) {
      id
      name {
        lang
        value
      }
      slug
      sort
      position
      template
      status
      parent_id
      icon
      drop_template
      target
      estate_type
      object_type_id
      text {
        lang
        value
      }
    }
    menuTree(type: $type)
    objectType {
      id
      nameString(lang: $lang)
    }
  }
`;
