import React from "react";
import { useSelector } from "react-redux";
import { Dropdown } from "react-bootstrap";

export const User = () => {
  const user = useSelector((state) => state.auth.user);

  return (
    <Dropdown className="dropdown notification-list topbar-dropdown" as={"li"}>
      <Dropdown.Toggle className="nav-link dropdown-toggle nav-user me-0 waves-effect waves-light">
        <img
          src="https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/271deea8-e28c-41a3-aaf5-2913f5f48be6/de7834s-6515bd40-8b2c-4dc6-a843-5ac1a95a8b55.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7InBhdGgiOiJcL2ZcLzI3MWRlZWE4LWUyOGMtNDFhMy1hYWY1LTI5MTNmNWY0OGJlNlwvZGU3ODM0cy02NTE1YmQ0MC04YjJjLTRkYzYtYTg0My01YWMxYTk1YThiNTUuanBnIn1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmZpbGUuZG93bmxvYWQiXX0.BopkDn1ptIwbmcKHdAOlYHyAOOACXW0Zfgbs0-6BY-E"
          alt="user-image"
          className="rounded-circle"
        />
        <span className="pro-user-name ms-1">
          {user.name} <i className="mdi mdi-chevron-down"></i>
        </span>
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-menu dropdown-menu-end profile-dropdown ">
        <Dropdown.Item className="dropdown-item notify-item">
          <i className="ri-account-circle-line"></i>
          <span>My Account</span>
        </Dropdown.Item>
        <Dropdown.Item className="dropdown-item notify-item" as={"a"}>
          <i className="ri-wallet-line"></i>
          <span>
            My Wallet <span className="badge bg-success float-end">3</span>{" "}
          </span>
        </Dropdown.Item>
        <Dropdown.Item className="dropdown-item notify-item" as={"a"}>
          <i className="ri-lock-line"></i>
          <span>Lock Screen</span>
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item className="dropdown-item notify-item" as={"a"}>
          <i className="ri-logout-box-line"></i>
          <span>Logout</span>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};
