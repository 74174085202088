import { Selected } from "../Form/Selected";
import { Button } from "../Button";
import { MultilangTextInput } from "../Form/MultilangTextInput";
import { useFormik } from "formik";
import * as Yup from "yup";
import { CountryFormComponent } from "./CountryForm";
import { Notification } from "../../utils/Notification";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "@apollo/client";
import { MODIFY_CITY } from "../../graphql/Mutations/CityMutation";
import { MODIFY_FAQ_TAB } from "../../graphql/Mutations/FaqMutation";
import { FAQ_PAGE } from "../../utils/Dictionaries";

export const FaqTabsFormComponent = ({ onSuccess, onError, url, data }) => {
  const { t } = useTranslation();
  const [modify] = useMutation(MODIFY_FAQ_TAB);
  const formik = useFormik({
    initialValues: {
      id: 0,
      name: {
        ru: "",
        az: "",
        en: "",
      },
      page: 1,
    },
    validationSchema: Yup.object({
      name: Yup.object().shape({
        az: Yup.string().required(t("Объязательное поле")),
      }),
    }),
    onSubmit: (values) => {
      setLoading(true);
      modify({
        variables: {
          id: Number(values.id),
          name: JSON.stringify(values.name),
          page: Number(values.page),
        },
      })
        .then((response) => {
          onSuccess(response);
        })
        .catch((error) => {
          onError(error);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (data && data.faqTabs[0]) {
      data.faqTabs[0].name.map((dic) => {
        formik.setFieldValue(`name.${dic.lang}`, dic.value);
      });
      formik.setFieldValue("id", data.faqTabs[0].id);
      formik.setFieldValue("page", data.faqTabs[0].page);
    }
  }, [data]);

  return (
    <form className="row" onSubmit={formik.handleSubmit}>
      <div className="col-md-6">
        <MultilangTextInput
          label={"Название"}
          value={formik.values.name}
          onChange={(text, lang) => {
            formik.setFieldValue(`name.${lang}`, text);
          }}
          onBlur={formik.handleBlur}
          error={formik.errors.name}
          touched={formik.touched.name}
          name={"name"}
        />
      </div>
      <div className="col-md-6">
        <Selected
          label={"Где выводить?"}
          style={{ marginTop: "38px" }}
          onChange={(value) => {
            formik.setFieldValue(`page`, value);
          }}
          error={formik.errors.page}
          name={"page"}
          staticOptions={FAQ_PAGE}
          rows={{ value: "id", label: "name" }}
          value={formik.values.page}
        />
      </div>
      <div className="col-md-12 text-center">
        <Button
          label={"Сохранить"}
          loading={loading}
          style={"btn btn-primary w-md"}
        />
      </div>
    </form>
  );
};
