import { gql } from "@apollo/client";

export const GET_DICTIONARIES = gql`
  query {
    dictionaries {
      id
      name {
        lang
        value
      }
    }
  }
`;

export const GET_DICTIONARY = gql`
  query ($id: Int!) {
    dictionaries(id: $id) {
      id
      name {
        lang
        value
      }
      options {
        id
        key
        value {
          lang
          value
        }
        for_sitemap {
          lang
          value
        }
      }
    }
  }
`;
