import React from "react";

export const Preload = () => {
  return (
    <div className={"preload"}>
      <div className="spinner-border text-primary m-2" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
      Loading...
    </div>
  );
};
