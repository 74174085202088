import React, { useState } from "react";
import { Wrapper } from "../../components/Wrapper";
import { PageTitle } from "../../components/PageTitle";
import { Notification } from "../../utils/Notification";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Preload } from "../../components/Preload";
import { PropertyGroupForm } from "../../components/forms/PropertyGroupForm";
import { useQuery } from "@apollo/client";
import { GET_PROPERTY_GROUP } from "../../graphql/Queries/PropertyGroup";

export const FormPropertyGroups = ({ url }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const { data, error, load } = useQuery(GET_PROPERTY_GROUP, {
    variables: {
      id: Number(searchParams.get("id")),
    },
    fetchPolicy: "no-cache",
  });

  if (!data) {
    return <Preload />;
  }

  return (
    <Wrapper>
      <PageTitle
        title={
          searchParams.get("id") === null
            ? "Добавить группу"
            : "Редактировать группу"
        }
        breadcrumbs={[
          {
            slug: url,
            name: "Группы свойств",
          },
        ]}
      />
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <PropertyGroupForm
                data={data.property_group[0]}
                onSuccess={(response) => {
                  navigate(url);
                  Notification(t("Элемент добавлен"), "success");
                }}
                onError={(error) => {
                  Notification(error);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
