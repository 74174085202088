import React from "react";
import { Wrapper } from "../../components/Wrapper";
import { PageTitle } from "../../components/PageTitle";
import { Notification } from "../../utils/Notification";
import { useTranslation } from "react-i18next";

import { useNavigate, useSearchParams } from "react-router-dom";
import { Preload } from "../../components/Preload";
import { StepFormComponent } from "../../components/forms/StepFormComponent";
import { useQuery } from "@apollo/client";
import { GET_STEP } from "../../graphql/Queries/Steps";

export const StepForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const { load, error, data } = useQuery(GET_STEP, {
    variables: {
      id: Number(searchParams.get("id")),
    },
    fetchPolicy: "no-cache",
  });

  if (!data) {
    return <Preload />;
  }

  return (
    <Wrapper>
      <PageTitle
        title={searchParams.get("id") === null ? "Добавить" : "Редактировать"}
        breadcrumbs={[
          {
            slug: "/steps",
            name: "Шаги/Блоки",
          },
        ]}
      />
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <StepFormComponent
                data={data.steps[0]}
                onSuccess={() => {
                  navigate("/steps");
                  Notification(t("Шаг добавлен"), "success");
                }}
                onError={(error) => Notification(error)}
              />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
