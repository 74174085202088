// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SelectSidebar_selectSidebar__xZLFF {
  display: flex;
}
.SelectSidebar_selectSidebar__xZLFF .SelectSidebar_side__EDPXQ {
  flex: 1 1;
}
.SelectSidebar_selectSidebar__xZLFF .SelectSidebar_side__EDPXQ:first-child {
  margin-right: 10px;
}
.SelectSidebar_selectSidebar__xZLFF .SelectSidebar_side__EDPXQ:last-child {
  margin-left: 10px;
}

ul.SelectSidebar_list__8t53V {
  height: 300px;
  overflow: auto;
  border: 1px solid var(--bs-border-color);
  border-radius: 6px;
  list-style: none;
  padding: 0;
  margin: 0;
}
ul.SelectSidebar_list__8t53V li {
  padding: 5px 10px;
  cursor: pointer;
}
ul.SelectSidebar_list__8t53V li:hover {
  background-color: #3bafda;
  color: #fff;
}

li {
  list-style: none;
}

.SelectSidebar_title__3XHDn {
  font-weight: bold;
  margin: 0 0 10px 5px;
}`, "",{"version":3,"sources":["webpack://./src/components/SelectSidebar/SelectSidebar.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;AAAE;EACE,SAAA;AAEJ;AAAI;EACE,kBAAA;AAEN;AACI;EACE,iBAAA;AACN;;AAIA;EACE,aAAA;EACA,cAAA;EACA,wCAAA;EACA,kBAAA;EACA,gBAAA;EACA,UAAA;EACA,SAAA;AADF;AAEE;EACE,iBAAA;EACA,eAAA;AAAJ;AAEI;EACE,yBAAA;EACA,WAAA;AAAN;;AAKA;EACE,gBAAA;AAFF;;AAKA;EACE,iBAAA;EACA,oBAAA;AAFF","sourcesContent":[".selectSidebar {\n  display: flex;\n  .side {\n    flex: 1;\n\n    &:first-child {\n      margin-right: 10px;\n    }\n\n    &:last-child {\n      margin-left: 10px;\n    }\n  }\n}\n\nul.list {\n  height: 300px;\n  overflow: auto;\n  border: 1px solid var(--bs-border-color);\n  border-radius: 6px;\n  list-style: none;\n  padding: 0;\n  margin: 0;\n  li {\n    padding: 5px 10px;\n    cursor: pointer;\n\n    &:hover {\n      background-color: #3bafda;\n      color: #fff;\n    }\n  }\n}\n\nli {\n  list-style: none;\n}\n\n.title {\n  font-weight: bold;\n  margin: 0 0 10px 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectSidebar": `SelectSidebar_selectSidebar__xZLFF`,
	"side": `SelectSidebar_side__EDPXQ`,
	"list": `SelectSidebar_list__8t53V`,
	"title": `SelectSidebar_title__3XHDn`
};
export default ___CSS_LOADER_EXPORT___;
