import { gql } from "@apollo/client";

export const GET_SERVICES = gql`
  query {
    service {
      id
      name {
        lang
        value
      }
    }
  }
`;

export const GET_SERVICE = gql`
  query ($id: Int) {
    service(id: $id) {
      id
      name {
        lang
        value
      }
      name_short {
        lang
        value
      }
      text {
        lang
        value
      }
      key
      icon
      variants {
        id
        amount
        name {
          value
          lang
        }
        prices {
          user_type
          price
        }
      }
    }
  }
`;

export const GET_FAQS = gql`
  query {
    faq {
      id
      title {
        lang
        value
      }
      tab {
        name {
          lang
          value
        }
      }
    }
  }
`;

export const GET_FAQ = gql`
  query ($id: Int, $lang: String!) {
    faq(id: $id) {
      id
      title {
        lang
        value
      }
      text {
        lang
        value
      }
      tab {
        id
      }
    }
    faqTabs {
      id
      nameString(lang: $lang)
    }
  }
`;
