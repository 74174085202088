// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Styles_fileInput__9qbC9 {
  border: 1px solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  padding: 10px 15px 15px;
}
.Styles_fileInput__9qbC9 label {
  margin-bottom: 10px;
  color: rgba(var(--bs-body-color-rgb), 0.65);
  font-size: 14px;
}

.Styles_imageContainer__1kCuh {
  width: calc((100% - 80px) / 4);
  height: 200px;
  padding: 0.25rem;
  background-color: var(--bs-secondary-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-right: 20px;
  position: relative;
  flex-shrink: 0;
}
.Styles_imageContainer__1kCuh .Styles_imageBack__ZaXE4 {
  position: absolute;
  z-index: 2;
  background: rgba(255, 255, 255, 0.6);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  justify-content: center;
  align-items: center;
}
.Styles_imageContainer__1kCuh .Styles_imageBack__ZaXE4 button {
  display: block;
  margin: 5px;
}
.Styles_imageContainer__1kCuh:hover .Styles_imageBack__ZaXE4 {
  display: flex;
}
.Styles_imageContainer__1kCuh img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.Styles_editor__k4KXY {
  border: 1px solid var(--bs-border-color);
  padding: 15px 15px 60px;
  margin-bottom: 30px;
}`, "",{"version":3,"sources":["webpack://./src/components/Form/Styles.module.scss"],"names":[],"mappings":"AAAA;EACE,wCAAA;EACA,sCAAA;EACA,uBAAA;AACF;AACE;EACE,mBAAA;EACA,2CAAA;EACA,eAAA;AACJ;;AAGA;EACE,8BAAA;EACA,aAAA;EACA,gBAAA;EACA,wCAAA;EACA,2DAAA;EACA,sCAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,gBAAA;EACA,kBAAA;EACA,kBAAA;EACA,cAAA;AAAF;AAEE;EACE,kBAAA;EACA,UAAA;EACA,oCAAA;EACA,MAAA;EACA,QAAA;EACA,SAAA;EACA,OAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AAAJ;AAEI;EACE,cAAA;EACA,WAAA;AAAN;AAKI;EACE,aAAA;AAHN;AAOE;EACE,eAAA;EACA,gBAAA;EACA,mBAAA;AALJ;;AASA;EACE,wCAAA;EACA,uBAAA;EACA,mBAAA;AANF","sourcesContent":[".fileInput {\n  border: 1px solid var(--bs-border-color);\n  border-radius: var(--bs-border-radius);\n  padding: 10px 15px 15px;\n\n  label {\n    margin-bottom: 10px;\n    color: rgba(var(--bs-body-color-rgb), 0.65);\n    font-size: 14px;\n  }\n}\n\n.imageContainer {\n  width: calc((100% - 80px)/4);\n  height: 200px;\n  padding: 0.25rem;\n  background-color: var(--bs-secondary-bg);\n  border: var(--bs-border-width) solid var(--bs-border-color);\n  border-radius: var(--bs-border-radius);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin-top: 20px;\n  margin-right: 20px;\n  position: relative;\n  flex-shrink: 0;\n\n  .imageBack {\n    position: absolute;\n    z-index: 2;\n    background: rgba(255, 255, 255, .6);\n    top: 0;\n    right: 0;\n    bottom: 0;\n    left: 0;\n    display: none;\n    justify-content: center;\n    align-items: center;\n\n    button {\n      display: block;\n      margin: 5px;\n    }\n  }\n\n  &:hover {\n    .imageBack {\n      display: flex;\n    }\n  }\n\n  img {\n    max-width: 100%;\n    max-height: 100%;\n    object-fit: contain;\n  }\n}\n\n.editor {\n  border: 1px solid var(--bs-border-color);\n  padding: 15px 15px 60px;\n  margin-bottom: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fileInput": `Styles_fileInput__9qbC9`,
	"imageContainer": `Styles_imageContainer__1kCuh`,
	"imageBack": `Styles_imageBack__ZaXE4`,
	"editor": `Styles_editor__k4KXY`
};
export default ___CSS_LOADER_EXPORT___;
