import "./TimePicker.css";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";

export const TimePicker = ({
  hint,
  label,
  error,
  touched,
  name,
  value,
  onChange,
}) => {
  const { t } = useTranslation();
  const numbers = Array.from({ length: 24 }, (_, index) => index);
  const seconds = Array.from({ length: 60 }, (_, index) => index);
  const [h, setH] = useState(0);
  const [m, setM] = useState(0);
  const timePickerRef = useRef(null);
  const timePickerRef2 = useRef(null);
  const [show, toggleShow] = useState(false);

  useEffect(() => {
    const time = value.split(":");
    setH(time[0]);
    setM(time[1]);
  }, [value]);

  useEffect(() => {
    if (timePickerRef.current && show) {
      const activeElement = timePickerRef.current.querySelector(".active");
      if (activeElement) {
        activeElement.scrollIntoView({ behavior: "instant", block: "center" });
      }
    }
    if (timePickerRef2.current && show) {
      const activeElement = timePickerRef2.current.querySelector(".active");
      if (activeElement) {
        activeElement.scrollIntoView({ behavior: "instant", block: "center" });
      }
    }
  }, [show]);

  return (
    <div className={"form-floating mb-3 timeBox"}>
      <input
        className={
          error && touched ? "form-control is-invalid" : "form-control"
        }
        type={"text"}
        value={`${h}:${m}`}
        name={name}
        readOnly={true}
        onClick={() => toggleShow(!show)}
      />
      <label htmlFor={name}>{t(label)}</label>
      {hint && (
        <OverlayTrigger placement={"top"} overlay={<Tooltip>{hint}</Tooltip>}>
          <div
            className={"text-warning"}
            style={{ position: "absolute", fontSize: 20, right: 10, top: 14 }}
          >
            <i className="fe-alert-circle"></i>
          </div>
        </OverlayTrigger>
      )}
      {show && (
        <div className={"timepicker"}>
          <div className={"wrap"}>
            <div className={"hour"} ref={timePickerRef}>
              {numbers.map((number) => {
                const cls = Number(h) === Number(number) ? "active" : "";
                const hh = String(number).length === 1 ? "0" + number : number;
                return (
                  <div className={cls} onClick={() => setH(hh)}>
                    {hh}
                  </div>
                );
              })}
            </div>
            <div className={"hour"} ref={timePickerRef2}>
              {seconds.map((number) => {
                const cls = Number(m) === Number(number) ? "active" : "";
                const mm = String(number).length === 1 ? "0" + number : number;
                return (
                  <div onClick={() => setM(mm)} className={cls}>
                    {mm}
                  </div>
                );
              })}
            </div>
          </div>
          <div
            className={"selectBtn"}
            onClick={() => {
              toggleShow(false);
              onChange(`${h}:${m}`);
            }}
          >
            {t("Выбрать")}
          </div>
        </div>
      )}
    </div>
  );
};
