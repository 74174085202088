export const ru = {
  translation: {
    "Ввведите email и пароль для входа в систему":
      "Ввведите email и пароль для входа в систему",
    Email: "Email",
    "Введите свой email": "Введите свой email",
    Пароль: "Пароль",
    "Введите свой пароль": "Введите свой пароль",
    Ввойти: "Ввойти",
    Укажите: "Укажите",
    "Не верный email": "Не верный email",
    Подождите: "Подождите",
    Создать: "Создать",
    Главная: "Главная",
    Конструктор: "Конструктор",
    Справочники: "Справочники",
    Свойства: "Свойства",
    Недвижимость: "Недвижимость",
    Объекты: "Объекты",
    "Жилые комплексы": "Жилые комплексы",
    "": "",
  },
};
