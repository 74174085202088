import React, { useEffect, useState } from "react";
import { Wrapper } from "../components/Wrapper";
import { PageTitle } from "../components/PageTitle";
import { CustomTable } from "../components/CustomTable";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Notification } from "../utils/Notification";
import { Preload } from "../components/Preload";
import { useMutation, useQuery } from "@apollo/client";
import { GET_PROPERTY_GROUPS } from "../graphql/Queries/PropertyGroup";
import { DELETE_PROPERTY_GROUP } from "../graphql/Mutations/PropertyGroupMutation";

export const PropertyGroups = ({ url }) => {
  const [items, setItems] = useState([]);
  const navigator = useNavigate();
  const { data, load, error } = useQuery(GET_PROPERTY_GROUPS, {
    fetchPolicy: "no-cache",
  });
  const [deletePropertyGroup] = useMutation(DELETE_PROPERTY_GROUP);

  const remove = (id) => {
    deletePropertyGroup({
      variables: {
        id,
      },
    })
      .then((res) => {
        setItems(res.data.deletePropertyGroup);
      })
      .catch((error) => {
        Notification(error);
      });
  };

  const edit = (id) => {
    navigator(`${url}/form?id=${id}`);
  };

  useEffect(() => {
    if (data) {
      setItems(data.property_group);
    }
  }, [data]);

  if (!data) {
    return <Preload />;
  }

  return (
    <Wrapper>
      <PageTitle title={"Группы свойств"} />
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <CustomTable
                data={items}
                columns={[
                  { key: "id", label: "ID" },
                  { key: "name", label: "Название" },
                  { key: "name_system", label: "Название (системное)" },
                  { key: "step.name.ru", label: "Шаг/блок" },
                ]}
                search={true}
                sort={true}
                pagination={{
                  limit: 10,
                }}
                actions={[
                  {
                    slug: `${url}/form`,
                    icon: "fe-plus",
                    name: "Создать",
                  },
                ]}
                onClick={(id) => edit(id)}
                onDelete={(id) => remove(id)}
              />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
