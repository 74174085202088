import React, { useEffect, useState } from "react";
import { Wrapper } from "../../components/Wrapper";
import { PageTitle } from "../../components/PageTitle";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Notification } from "../../utils/Notification";
import { useTranslation } from "react-i18next";
import { Button } from "../../components/Button";
import { MultilangTextInput } from "../../components/Form/MultilangTextInput";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Preload } from "../../components/Preload";
import { Selected } from "../../components/Form/Selected";
import { useMutation, useQuery } from "@apollo/client";
import i18next from "i18next";
import { Editor } from "../../components/Form/Editor";
import { GET_ONE_NEWS } from "../../graphql/Queries/NewsQuery";
import { CategoryNewsFormComponent } from "../../components/forms/CategoryNewsFormComponent";
import { toast } from "react-toastify";
import { TagNewsFormComponent } from "../../components/forms/TagNewsFormComponent";
import { FileInput } from "../../components/Form/FileInput";
import { TextInput } from "../../components/Form/TextInput";
import { Slug } from "../../utils/Slug";
import { MODIFY_NEWS } from "../../graphql/Mutations/NewsMutation";
import { Datepicker } from "../../components/Form/Datepicker";
import moment from "moment";
import { TimePicker } from "../../components/TimePicker/TimePicker";

export const FormNews = ({ url }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  let location = useLocation();
  const id = location.state ? location.state.id : null;
  let [searchParams, setSearchParams] = useSearchParams();
  const [modify] = useMutation(MODIFY_NEWS);
  const [disableSlug, setDisableSlug] = useState(false);
  const { data, refetch } = useQuery(GET_ONE_NEWS, {
    variables: {
      id: Number(searchParams.get("id")),
      lang: i18next.language,
    },
    fetchPolicy: "no-cache",
  });
  const formik = useFormik({
    initialValues: {
      id: 0,
      title: {
        ru: "",
        az: "",
        en: "",
      },
      text: {
        ru: "",
        az: "",
        en: "",
      },
      slug: "",
      image: [],
      categories: [],
      tags: [],
      public_date: moment().format("DD.MM.YYYY"),
      public_time: moment().format("HH:mm"),
      description: "",
    },
    validationSchema: Yup.object({
      title: Yup.object().shape({
        az: Yup.string().required(t("Объязательное поле")),
      }),
      description: Yup.object().shape({
        az: Yup.string().required(t("Объязательное поле")),
      }),
      text: Yup.object().shape({
        az: Yup.string().required(t("Объязательное поле")),
      }),
      slug: Yup.string().required(t("Объязательное поле")),
      categories: Yup.array().test(
        "notEmpty",
        t("Выберите хотя бы одно значение"),
        function (value) {
          return value.length > 0;
        },
      ),
    }),
    onSubmit: (values) => {
      setLoading(true);
      modify({
        variables: {
          id: Number(values.id),
          title: JSON.stringify(values.title),
          description: JSON.stringify(values.description),
          text: JSON.stringify(values.text),
          categories: JSON.stringify(values.categories),
          tags: JSON.stringify(values.tags),
          image: JSON.stringify(values.image),
          slug: values.slug,
          public_datetime:
            values.public_date + " " + values.public_time + ":00",
        },
      })
        .then((response) => {
          if (response.data.modifyNews) {
            navigate(url);
            Notification(t("Все хорошо!"), "success");
          } else {
            Notification(t("Ошибка сохранения"));
          }
        })
        .catch((error) => {
          Notification(error);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  useEffect(() => {
    if (data && data.news[0]) {
      setDisableSlug(true);
      data.news[0].title.map((dic) => {
        formik.setFieldValue(`title.${dic.lang}`, dic.value);
      });
      data.news[0].description.map((dic) => {
        formik.setFieldValue(`description.${dic.lang}`, dic.value);
      });
      data.news[0].text.map((dic) => {
        formik.setFieldValue(`text.${dic.lang}`, dic.value);
      });
      formik.setFieldValue("id", data.news[0].id);
      formik.setFieldValue("slug", data.news[0].slug);
      const date = data.news[0].public_datetime.split(" ");
      formik.setFieldValue("public_date", date[0]);
      formik.setFieldValue("public_time", date[1]);
      formik.setFieldValue("categories", JSON.parse(data.news[0].categories));
      formik.setFieldValue("tags", JSON.parse(data.news[0].tags));
      formik.setFieldValue("image", JSON.parse(data.news[0].image));
    }
  }, [data]);

  if (!data) {
    return <Preload />;
  }

  return (
    <Wrapper>
      <PageTitle
        title={id === null ? "Добавить новость" : "Редактировать новость"}
        breadcrumbs={[
          {
            slug: url,
            name: "Новости",
          },
        ]}
      />
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <form className="row" onSubmit={formik.handleSubmit}>
                <div className="col-md-12">
                  <MultilangTextInput
                    label={"Название"}
                    value={formik.values.title}
                    onChange={(text, lang) => {
                      if (lang === "az" && !disableSlug) {
                        formik.setFieldValue("slug", Slug(text));
                      }
                      formik.setFieldValue(`title.${lang}`, text);
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.errors.title}
                    touched={formik.touched.title}
                    name={"title"}
                  />
                </div>
                <div className="col-md-12">
                  <MultilangTextInput
                    label={"Краткое описание"}
                    value={formik.values.description}
                    onChange={(text, lang) => {
                      formik.setFieldValue(`description.${lang}`, text);
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.errors.description}
                    touched={formik.touched.description}
                    name={"description"}
                  />
                </div>
                <div className="col-md-12">
                  <Editor
                    value={formik.values.text}
                    onChange={(text, lang) => {
                      formik.setFieldValue(`text.${lang}`, text);
                    }}
                    name={"text"}
                    label={"Текст новости"}
                  />
                </div>
                <div className="col-md-4">
                  <Selected
                    label={"Категории"}
                    onChange={(value) => {
                      const arr = [];
                      value.map((v) => {
                        arr.push(Number(v.value));
                      });
                      formik.setFieldValue("categories", arr);
                    }}
                    tags={true}
                    error={formik.errors.categories}
                    touched={formik.touched.categories}
                    rows={{ value: "id", label: "nameString" }}
                    staticOptions={data?.categoryNews}
                    value={formik.values.categories}
                    modalForm={
                      <CategoryNewsFormComponent
                        data={{ name: { ru: "", en: "", az: "" } }}
                        url={"/category-news"}
                        onSuccess={() => {
                          toast.success(t("Категория создана"));
                        }}
                      />
                    }
                    onRefresh={() => refetch()}
                  />
                </div>
                <div className="col-md-4">
                  <Selected
                    label={"Теги"}
                    onChange={(value) => {
                      const arr = [];
                      value.map((v) => {
                        arr.push(Number(v.value));
                      });
                      formik.setFieldValue("tags", arr);
                    }}
                    tags={true}
                    error={formik.errors.tags}
                    rows={{ value: "id", label: "nameString" }}
                    staticOptions={data?.tagsNews}
                    value={formik.values.tags}
                    modalForm={
                      <TagNewsFormComponent
                        data={{ name: { ru: "", en: "", az: "" } }}
                        url={"/tag-news"}
                        onSuccess={() => {
                          toast.success(t("Тег создан"));
                        }}
                      />
                    }
                    onRefresh={() => refetch()}
                  />
                </div>
                <div className="col-md-4">
                  <TextInput
                    label={"Ссылка"}
                    value={formik.values.slug}
                    onChange={(event) => {
                      formik.setFieldValue(`slug`, event.target.value);
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.errors.slug}
                    touched={formik.touched.slug}
                    name={"slug"}
                    readOnly={disableSlug}
                  />
                </div>
                <div className="col-md-4">
                  <Datepicker
                    label={"Дата публикации"}
                    value={formik.values.public_date}
                    onChange={(value) => {
                      formik.setFieldValue(`public_date`, value);
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.errors.public_date}
                    touched={formik.touched.public_date}
                    name={"public_date"}
                    readOnly={disableSlug}
                  />
                </div>
                <div className="col-md-4">
                  <TimePicker
                    label={"Время публикации"}
                    value={formik.values.public_time}
                    name={"public_time"}
                    error={formik.errors.public_date}
                    touched={formik.touched.public_date}
                    onChange={(value) => {
                      formik.setFieldValue(`public_time`, value);
                    }}
                  />
                </div>
                <div className="col-md-12">
                  <FileInput
                    label={"Фото"}
                    onChange={(file, lang) => {
                      let _image = [];
                      if (formik.values.image) {
                        _image = [...formik.values.image];
                      }
                      _image.push(file);
                      formik.setFieldValue(`image`, _image);
                    }}
                    value={formik.values.image}
                    multilang={false}
                    onDelete={() => {
                      formik.setFieldValue("image", []);
                    }}
                  />
                </div>
                <div className="col-md-12 text-center">
                  <Button
                    label={"Сохранить"}
                    loading={loading}
                    style={"btn btn-primary w-md"}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
