import React, { useCallback, useEffect, useState } from "react";
import { Wrapper } from "../../components/Wrapper";
import { PageTitle } from "../../components/PageTitle";
import { useFormik } from "formik";
import { Notification } from "../../utils/Notification";
import { useTranslation } from "react-i18next";
import { Button } from "../../components/Button";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Preload } from "../../components/Preload";
import { useMutation, useQuery } from "@apollo/client";
import i18next from "i18next";
import { GET_COMPLEX_PROPERTY } from "../../graphql/Queries/ComplexQuery";
import { SET_PROPS } from "../../graphql/Mutations/ComplexMutation";
import { SelectSidebar } from "../../components/SelectSidebar/SelectSidebar";
import { Accordion } from "react-bootstrap";

export const Props = ({ url }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  let location = useLocation();
  const id = location.state ? location.state.id : null;
  let [searchParams, setSearchParams] = useSearchParams();
  const [modify] = useMutation(SET_PROPS);
  const { data } = useQuery(GET_COMPLEX_PROPERTY, {
    variables: {
      lang: i18next.language,
    },
    fetchPolicy: "no-cache",
  });
  const formik = useFormik({
    initialValues: {
      properties: [],
    },
    onSubmit: (values) => {
      setLoading(true);
      modify({
        variables: {
          properties: JSON.stringify(values.properties),
        },
      })
        .then((response) => {
          navigate(url);
          Notification(t("Все хорошо!"), "success");
        })
        .catch((error) => {
          Notification(error);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });
  const [selected, setSelected] = useState({});

  useEffect(() => {
    if (data) {
      const full = {};
      data?.list.map((item, index) => {
        const arr = [];
        data.complexProperty.map((propCurrent) => {
          data.property.map((prop) => {
            if (
              prop.id === propCurrent.property.id &&
              Number(item.id) === Number(propCurrent.type)
            ) {
              arr.push(prop);
            }
          });
        });
        full[item.id] = arr;
      });
      setSelected(full);
      formik.setFieldValue("properties", full);
    }
  }, [data]);

  if (!data) {
    return <Preload />;
  }

  return (
    <Wrapper>
      <PageTitle
        title={id === null ? "Настроить свойства" : "Редактировать свойства"}
        breadcrumbs={[
          {
            slug: url,
            name: "Жилые зоны",
          },
        ]}
      />
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <form className="row" onSubmit={formik.handleSubmit}>
                <Accordion defaultActiveKey={false} id="accordion">
                  {data?.list.map((item, index) => {
                    return (
                      <Accordion.Item eventKey={String(index)}>
                        <Accordion.Header>
                          <i className="mdi mdi-help-circle me-1 text-primary"></i>{" "}
                          {item.name}
                        </Accordion.Header>
                        <Accordion.Body>
                          <SelectSidebar
                            items={data.property}
                            label={"nameString"}
                            onChange={(items) => {
                              const before = { ...formik.values.properties };
                              before[item.id] = items;
                              formik.setFieldValue("properties", before);
                            }}
                            values={selected[item.id]}
                          />
                        </Accordion.Body>
                      </Accordion.Item>
                    );
                  })}
                </Accordion>
                <div
                  className="col-md-12 text-center"
                  style={{ marginTop: 20 }}
                >
                  <Button
                    label={"Сохранить"}
                    loading={loading}
                    style={"btn btn-primary w-md"}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
