import React from "react";
import { Dropdown } from "react-bootstrap";

export const Notification = () => {
  return (
    <>
      <Dropdown
        as={"li"}
        className="dropdown notification-list topbar-dropdown"
      >
        <Dropdown.Toggle
          className="nav-link dropdown-toggle waves-effect waves-light"
          as={"a"}
        >
          <i className="fe-bell noti-icon"></i>
          {/*<span className="badge bg-danger rounded-circle noti-icon-badge">*/}
          {/*  5*/}
          {/*</span>*/}
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu dropdown-menu-end dropdown-lg">
          <Dropdown.Item className="dropdown-item noti-title" as={"div"}>
            <h5 className="m-0">
              <span className="float-end">
                <a href="" className="text-dark">
                  <small>Clear All</small>
                </a>
              </span>
              Notification
            </h5>
          </Dropdown.Item>
          <Dropdown.Item className="noti-scroll" as={"div"}>
            <a
              href="javascript:void(0);"
              className="dropdown-item notify-item active"
            >
              <div className="notify-icon bg-soft-primary text-primary">
                <i className="mdi mdi-comment-account-outline"></i>
              </div>
              <p className="notify-details">
                Doug Dukes commented on Admin Dashboard
                <small className="text-muted">1 min ago</small>
              </p>
            </a>

            {/*item*/}
            <a href="javascript:void(0);" className="dropdown-item notify-item">
              <div className="notify-icon">
                <img
                  src="assets/images/users/avatar-2.jpg"
                  className="img-fluid rounded-circle"
                  alt=""
                />
              </div>
              <p className="notify-details">Mario Drummond</p>
              <p className="text-muted mb-0 user-msg">
                <small>Hi, How are you? What about our next meeting</small>
              </p>
            </a>

            {/*item*/}
            <a href="javascript:void(0);" className="dropdown-item notify-item">
              <div className="notify-icon">
                <img
                  src="assets/images/users/avatar-4.jpg"
                  className="img-fluid rounded-circle"
                  alt=""
                />
              </div>
              <p className="notify-details">Karen Robinson</p>
              <p className="text-muted mb-0 user-msg">
                <small>Wow ! this admin looks good and awesome design</small>
              </p>
            </a>

            {/*item*/}
            <a href="javascript:void(0);" className="dropdown-item notify-item">
              <div className="notify-icon bg-soft-warning text-warning">
                <i className="mdi mdi-account-plus"></i>
              </div>
              <p className="notify-details">
                New user registered.
                <small className="text-muted">5 hours ago</small>
              </p>
            </a>

            {/*item*/}
            <a href="javascript:void(0);" className="dropdown-item notify-item">
              <div className="notify-icon bg-info">
                <i className="mdi mdi-comment-account-outline"></i>
              </div>
              <p className="notify-details">
                Caleb Flakelar commented on Admin
                <small className="text-muted">4 days ago</small>
              </p>
            </a>

            {/*item*/}
            <a href="javascript:void(0);" className="dropdown-item notify-item">
              <div className="notify-icon bg-secondary">
                <i className="mdi mdi-heart"></i>
              </div>
              <p className="notify-details">
                Carlos Crouch liked
                <b>Admin</b>
                <small className="text-muted">13 days ago</small>
              </p>
            </a>
          </Dropdown.Item>
          <Dropdown.Item
            className="dropdown-item text-center text-primary notify-item notify-all"
            as={"a"}
          >
            View all
            <i className="fe-arrow-right"></i>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};
