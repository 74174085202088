import { toast } from "react-toastify";

export const Notification = (text, type = "error") => {
  switch (type) {
    case "success":
      toast.success(text);
      break;

    default:
      if (text.response !== undefined) {
        toast.error(text.response.data.message);
      } else if (text.message !== undefined) {
        toast.error(text.message);
      } else if (text.error !== undefined) {
        toast.error(text.error);
      } else {
        toast.error(text);
      }
  }
};
