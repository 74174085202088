import { gql } from "@apollo/client";

export const GET_PROMO_CODES = gql`
  query {
    promoCode {
      id
      start_date
      end_date
      code
      type
      user_type
      code
      name
      price
    }
  }
`;

export const GET_PROMO_CODE = gql`
  query ($id: Int) {
    promoCode(id: $id) {
      id
      start_date
      end_date
      code
      apply
      user_type
      price
      type
      name
    }
  }
`;

export const GET_PROMO_CODE_STAT = gql`
  query ($id: Int) {
    promoCodeUser(promo_code_id: $id) {
      updated_at
      user {
        phone
        name
        id
      }
    }
  }
`;
