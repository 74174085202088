import React, { useEffect, useState } from "react";
import { Wrapper } from "../components/Wrapper";
import { PageTitle } from "../components/PageTitle";
import { useLazyQuery, useQuery } from "@apollo/client";
import { Accordion, Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { GET_DASHBOARD } from "../graphql/Queries/DashboardQuery";
import { Link } from "react-router-dom";
import Chart from "react-apexcharts";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css";
import { DateRangePicker } from "react-date-range";
import moment from "moment";
import { Preload } from "../components/Preload";
import Switch from "react-switch";
import { toast } from "react-toastify";

export const Home = () => {
  const { t } = useTranslation();
  const [badges, setBadges] = useState(null);
  const [range, setRange] = useState({
    start: new Date(moment().subtract(7, "days").format()),
    end: new Date(),
  });
  const [dateFrom, setDateFrom] = useState(
    moment().subtract(7, "days").format("YYYY-MM-DD"),
  );
  const [dateTo, setDateTo] = useState(moment().format("YYYY-MM-DD"));
  const [target, setTarget] = useState(2);
  const { load, error, data } = useQuery(GET_DASHBOARD, {
    fetchPolicy: "no-cache",
    variables: {
      from: moment(dateFrom).format("YYYY-MM-DD"),
      to: moment(dateTo).format("YYYY-MM-DD"),
      charts: JSON.stringify([
        "estateInModeration",
        "sms",
        "promocodes",
        "services",
        "register",
        "complexes",
        "ad",
        "publicAndRegister",
        "cash",
        "save_search",
        "all_time_estates",
        "all_pay",
      ]),
      target: target,
    },
    pollInterval: 600000,
  });
  const [detailChart] = useLazyQuery(GET_DASHBOARD);
  const [showModal, toggleModal] = useState(false);
  const [unique, setUnique] = useState(false);
  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    if (data) {
      const arr = JSON.parse(data.dashboard);
      console.log("[Log]", arr);
      setBadges(arr);
    }
  }, [data]);

  if (badges === null) {
    return <Preload />;
  }

  return (
    <Wrapper>
      <PageTitle title={"Главная"} />
      <Row>
        <div className="col-xl-3 col-md-6">
          <div className="widget-simple text-center card">
            <div className="card-body">
              <h3 className="text-success mt-0">
                <span data-plugin="counterup">{badges?.sms}</span>
              </h3>
              <p className="text-muted mb-0">{t("Баланс смс")}</p>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-6">
          <div className="widget-simple text-center card">
            <div className="card-body">
              <h3 className="text-info mt-0">
                <span data-plugin="counterup">
                  {badges?.estateInModeration}
                </span>
              </h3>
              <Link className="text-muted mb-0" to={"/estates"}>
                {t("Объявлений на модерации")}
              </Link>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-6">
          <div className="widget-simple text-center card">
            <div className="card-body">
              <h3 className="text-info mt-0">
                <span data-plugin="counterup">{badges?.save_search}</span>
              </h3>
              <p className="text-muted mb-0">{t("Сохраненных поисков")}</p>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-6">
          <div className="widget-simple text-center card">
            <div className="card-body">
              <h3 className="text-info mt-0">
                <span data-plugin="counterup">{badges?.all_time_estates}</span>
              </h3>
              <p className="text-muted mb-0">{t("Публикаций за все время")}</p>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-6">
          <div className="widget-simple text-center card">
            <div className="card-body">
              <h3 className="text-info mt-0">
                <span data-plugin="counterup">{badges?.all_pay}</span>
              </h3>
              <p className="text-muted mb-0">
                {t("Куплено услуг за все время")}
              </p>
            </div>
          </div>
        </div>
      </Row>
      <Accordion
        style={{ marginBottom: 24 }}
        onSelect={(item) => {
          if (item !== null && !showMore) {
            detailChart({
              fetchPolicy: "no-cache",
              variables: {
                from: moment(dateFrom).format("YYYY-MM-DD"),
                to: moment(dateTo).format("YYYY-MM-DD"),
                charts: JSON.stringify([
                  "ad_catalog",
                  "ad_full",
                  "ad_view",
                  "view_number",
                  "add_favorite",
                  "add_compare",
                ]),
              },
            })
              .then((resp) => {
                const arr = JSON.parse(resp.data.dashboard);
                setBadges({
                  ...badges,
                  ad_catalog: arr.ad_catalog,
                  ad_full: arr.ad_full,
                  ad_view: arr.ad_view,
                  view_number: arr.view_number,
                  add_favorite: arr.add_favorite,
                  add_compare: arr.add_compare,
                });
                setShowMore(true);
              })
              .catch((error) => {
                toast.error(t("Ошибка загрузки данных"));
              });
          }
        }}
      >
        <Accordion.Item eventKey="0">
          <Accordion.Header style={{ background: "#fff" }}>
            {t("Детализация")}
          </Accordion.Header>
          <Accordion.Body>
            {showMore && (
              <Row>
                <div className="col-md-6">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="header-title mb-3">
                        {t("Показов в поиске - каталоги")}
                      </h4>
                      <Chart
                        options={{
                          chart: {
                            id: "ad-catalog",
                          },
                          series: unique
                            ? badges?.ad_catalog.series.unique
                            : badges?.ad_catalog.series.all,
                          labels: badges?.ad_catalog.labels,
                        }}
                        series={
                          unique
                            ? badges?.ad_catalog.series.unique
                            : badges?.ad_catalog.series.all
                        }
                        type="pie"
                        height={200}
                      />
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Switch
                          checked={unique}
                          onChange={() => setUnique(!unique)}
                          height={20}
                          offHandleColor={"#888"}
                          offColor={"#ebebeb"}
                          width={40}
                          handleDiameter={20}
                          onHandleColor={"#3bafda"}
                          onColor={"#ccecf6"}
                        />
                        <span style={{ marginLeft: 10 }}>
                          {t("Только уникальные")}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="header-title mb-3">
                        {t("Показов объявлений")}
                      </h4>
                      <Chart
                        options={{
                          chart: {
                            id: "ad-full",
                          },
                          series: unique
                            ? badges?.ad_full.series.unique
                            : badges?.ad_full.series.all,
                          labels: badges?.ad_full.labels,
                        }}
                        series={
                          unique
                            ? badges?.ad_full.series.unique
                            : badges?.ad_full.series.all
                        }
                        type="pie"
                        height={200}
                      />
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Switch
                          checked={unique}
                          onChange={() => setUnique(!unique)}
                          height={20}
                          offHandleColor={"#888"}
                          offColor={"#ebebeb"}
                          width={40}
                          handleDiameter={20}
                          onHandleColor={"#3bafda"}
                          onColor={"#ccecf6"}
                        />
                        <span style={{ marginLeft: 10 }}>
                          {t("Только уникальные")}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="header-title mb-3">
                        {t("Просмотр объявлений")}
                      </h4>
                      <Chart
                        options={{
                          chart: {
                            id: "ad-view",
                          },
                          series: unique
                            ? badges?.ad_view.series.unique
                            : badges?.ad_view.series.all,
                          labels: badges?.ad_view.labels,
                        }}
                        series={
                          unique
                            ? badges?.ad_view.series.unique
                            : badges?.ad_view.series.all
                        }
                        type="pie"
                        height={200}
                      />
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Switch
                          checked={unique}
                          onChange={() => setUnique(!unique)}
                          height={20}
                          offHandleColor={"#888"}
                          offColor={"#ebebeb"}
                          width={40}
                          handleDiameter={20}
                          onHandleColor={"#3bafda"}
                          onColor={"#ccecf6"}
                        />
                        <span style={{ marginLeft: 10 }}>
                          {t("Только уникальные")}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="header-title mb-3">
                        {t("Просмотров номера")}
                      </h4>
                      <Chart
                        options={{
                          chart: {
                            id: "view_number",
                          },
                          series: unique
                            ? badges?.view_number.series.unique
                            : badges?.view_number.series.all,
                          labels: badges?.view_number.labels,
                        }}
                        series={
                          unique
                            ? badges?.view_number.series.unique
                            : badges?.view_number.series.all
                        }
                        type="pie"
                        height={200}
                      />
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Switch
                          checked={unique}
                          onChange={() => setUnique(!unique)}
                          height={20}
                          offHandleColor={"#888"}
                          offColor={"#ebebeb"}
                          width={40}
                          handleDiameter={20}
                          onHandleColor={"#3bafda"}
                          onColor={"#ccecf6"}
                        />
                        <span style={{ marginLeft: 10 }}>
                          {t("Только уникальные")}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="header-title mb-3">
                        {t("Добавлений в избранное")}
                      </h4>
                      <Chart
                        options={{
                          chart: {
                            id: "add_favorite",
                          },
                          series: unique
                            ? badges?.add_favorite.series.unique
                            : badges?.add_favorite.series.all,
                          labels: badges?.add_favorite.labels,
                        }}
                        series={
                          unique
                            ? badges?.add_favorite.series.unique
                            : badges?.add_favorite.series.all
                        }
                        type="pie"
                        height={200}
                      />
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Switch
                          checked={unique}
                          onChange={() => setUnique(!unique)}
                          height={20}
                          offHandleColor={"#888"}
                          offColor={"#ebebeb"}
                          width={40}
                          handleDiameter={20}
                          onHandleColor={"#3bafda"}
                          onColor={"#ccecf6"}
                        />
                        <span style={{ marginLeft: 10 }}>
                          {t("Только уникальные")}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="header-title mb-3">
                        {t("Добавлений в сравнение")}
                      </h4>
                      <Chart
                        options={{
                          chart: {
                            id: "add_compare",
                          },
                          series: unique
                            ? badges?.add_compare.series.unique
                            : badges?.add_compare.series.all,
                          labels: badges?.add_compare.labels,
                        }}
                        series={
                          unique
                            ? badges?.add_compare.series.unique
                            : badges?.add_compare.series.all
                        }
                        type="pie"
                        height={200}
                      />
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Switch
                          checked={unique}
                          onChange={() => setUnique(!unique)}
                          height={20}
                          offHandleColor={"#888"}
                          offColor={"#ebebeb"}
                          width={40}
                          handleDiameter={20}
                          onHandleColor={"#3bafda"}
                          onColor={"#ccecf6"}
                        />
                        <span style={{ marginLeft: 10 }}>
                          {t("Только уникальные")}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Row>
            )}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Modal show={showModal} size="lg">
        <Modal.Header closeButton onClick={() => toggleModal(false)}>
          <Modal.Title>{t("Укажите период")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DateRangePicker
            ranges={[
              {
                startDate: range.start,
                endDate: range.end,
                key: "selection",
              },
            ]}
            months={1}
            onChange={(ranges) => {
              setRange({
                start: ranges.selection.startDate,
                end: ranges.selection.endDate,
              });
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            onClick={() => {
              setDateFrom(new Date(range.start));
              setDateTo(new Date(range.end));
              toggleModal(false);
            }}
          >
            {t("Показать")}
          </Button>
          <Button
            variant="secondary"
            onClick={() => {
              setRange({
                start: dateFrom,
                end: dateTo,
              });
              toggleModal(false);
            }}
          >
            {t("Отмена")}
          </Button>
        </Modal.Footer>
      </Modal>
      <Row>
        <div className="col-md-6">
          <div className="card">
            <div className="card-body">
              <div className="d-flex align-items-start justify-content-between">
                <div>
                  <h5
                    className="text-muted fw-normal mt-0 text-truncate"
                    title="Campaign Sent"
                  >
                    {t("Данные за период")}:
                  </h5>
                  <h3 className="my-2 py-1">
                    <span data-plugin="counterup">
                      {moment(dateFrom).format("DD.MM.YYYY")} -{" "}
                      {moment(dateTo).format("DD.MM.YYYY")}
                    </span>
                  </h3>
                </div>
                <div className="avatar-sm" onClick={() => toggleModal(true)}>
                  <span className="avatar-title bg-soft-primary rounded">
                    <i className=" ri-calendar-line font-20 text-primary"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <h4 className="header-title mb-3">
                {t("Публикации")} - {badges.ad.total}
                <div style={{ float: "right" }} onClick={() => {}}>
                  <label style={{ marginRight: 20 }}>
                    <input
                      type={"radio"}
                      value={2}
                      checked={target === 2}
                      onClick={() => setTarget(2)}
                    />
                    <span>{t("Продажа")}</span>
                  </label>
                  <label>
                    <input
                      type={"radio"}
                      value={1}
                      checked={target === 1}
                      onClick={() => setTarget(1)}
                    />
                    <span>{t("Аренда")}</span>
                  </label>
                </div>
              </h4>
              <Chart
                options={{
                  chart: {
                    id: "ad",
                  },
                  xaxis: {
                    categories: badges?.ad?.categories,
                  },
                }}
                series={badges?.ad?.values}
                type="line"
                width="100%"
                height={400}
              />
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <h4 className="header-title mb-3">
                {t("Публикации и регистрации")}
              </h4>
              <Chart
                options={{
                  chart: {
                    id: "adWithRegister",
                  },
                  xaxis: {
                    categories: badges?.publicAndRegister?.categories,
                  },
                }}
                series={badges?.publicAndRegister?.values}
                type="line"
                width="100%"
                height={400}
              />
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <h4 className="header-title mb-3">
                {t("Используемые промокоды")}
              </h4>
              <Chart
                options={{
                  chart: {
                    id: "promocode",
                  },
                  xaxis: {
                    categories: badges?.promocodes?.categories,
                  },
                }}
                series={[
                  {
                    name: "К-во кодов",
                    data: badges?.promocodes?.values,
                  },
                ]}
                type="line"
                width="100%"
                height={400}
              />
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <h4 className="header-title mb-3">
                {t("Статистика покупки услуг")} -{" "}
                {t("Куплено {{amount}} шт.", {
                  amount: badges?.services?.total,
                })}{" "}
                {t("на сумму")} {badges?.services?.amount} AZN
              </h4>
              <Chart
                options={{
                  chart: {
                    id: "services",
                  },
                  xaxis: {
                    categories: badges?.services?.categories,
                  },
                }}
                series={[
                  {
                    name: 'Покупка услуги "Поднятие объявления"',
                    data: badges?.services?.values[4],
                  },
                  {
                    name: 'Покупка услуги "VIP"',
                    data: badges?.services?.values[5],
                  },
                  {
                    name: 'Покупка услуги "Premium"',
                    data: badges?.services?.values[6],
                  },
                  {
                    name: 'Покупка услуги "Восстановление"',
                    data: badges?.services?.values[7],
                  },
                ]}
                type="line"
                width="100%"
                height={400}
              />
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <h4 className="header-title mb-3">
                {t("Как покупали")} -{" "}
                {t("Общая сумма: {{sum}} AZN", { sum: badges?.cash?.amount })}
              </h4>
              <Chart
                options={{
                  chart: {
                    id: "cash",
                  },
                  xaxis: {
                    categories: badges?.cash?.categories,
                  },
                }}
                series={badges?.cash?.values}
                type="line"
                width="100%"
                height={400}
              />
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <h4 className="header-title mb-3">
                {t("К-во регистраций")} - {badges?.register?.total}
              </h4>
              <Chart
                options={{
                  chart: {
                    id: "register",
                  },
                  xaxis: {
                    categories: badges?.register?.categories,
                  },
                }}
                series={[
                  {
                    name: "Пользователи",
                    data: badges?.register?.values[1],
                  },
                  {
                    name: "Агенты",
                    data: badges?.register?.values[2],
                  },
                  {
                    name: "Застройщики",
                    data: badges?.register?.values[3],
                  },
                ]}
                type="line"
                width="100%"
                height={400}
              />
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <h4 className="header-title mb-3">
                {t("Жилые зоны")} -{" "}
                {t("создано {{amount}}", {
                  amount: badges?.complexes?.total,
                })}
              </h4>
              <Chart
                options={{
                  chart: {
                    id: "complexes",
                  },
                  xaxis: {
                    categories: badges?.complexes?.categories,
                  },
                }}
                series={[
                  {
                    name: "Жилой комплекс",
                    data: badges?.complexes?.values[1],
                  },
                  {
                    name: "Дачный поселок",
                    data: badges?.complexes?.values[2],
                  },
                  {
                    name: "Коттеджный поселок",
                    data: badges?.complexes?.values[3],
                  },
                  {
                    name: "Курортный поселок",
                    data: badges?.complexes?.values[4],
                  },
                  {
                    name: "Военный городок",
                    data: badges?.complexes?.values[5],
                  },
                ]}
                type="line"
                width="100%"
                height={400}
              />
            </div>
          </div>
        </div>
      </Row>
    </Wrapper>
  );
};
