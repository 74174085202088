import { gql } from "@apollo/client";

export const GET_FAST_MENU = gql`
  query {
    fastMenu {
      id
      title {
        lang
        value
      }
      url {
        lang
        value
      }
      type
    }
  }
`;

export const GET_FAST_MENU_ONE = gql`
  query ($id: Int!) {
    fastMenu(id: $id) {
      id
      title {
        lang
        value
      }
      url {
        lang
        value
      }
      type
      sort
      color
      color_hover
      status
      icon
    }
  }
`;
