import { useFormik } from "formik";
import { Notification } from "../../utils/Notification";
import { useEffect, useState } from "react";
import axios from "axios";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { MultilangTextInput } from "../Form/MultilangTextInput";
import { Button } from "../Button";
import { useMutation } from "@apollo/client";
import { MODIFY_COUNTRY } from "../../graphql/Mutations/CoutryMutation";

export const CountryFormComponent = ({ data, onSuccess, onError, url }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [modify] = useMutation(MODIFY_COUNTRY);
  const formik = useFormik({
    initialValues: {
      id: 0,
      name: {
        ru: "",
        az: "",
        en: "",
      },
    },
    validationSchema: Yup.object({
      name: Yup.object().shape({
        ru: Yup.string().required(t("Объязательное поле")),
      }),
    }),
    onSubmit: (values) => {
      setLoading(true);
      console.log("[Log]", {
        id: values.id,
        name: JSON.stringify(values.name),
      });
      modify({
        variables: {
          id: Number(values.id),
          name: JSON.stringify(values.name),
        },
      })
        .then((response) => {
          onSuccess(response);
        })
        .catch((error) => {
          onError(error);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  useEffect(() => {
    if (data) {
      data.name.map((dic) => {
        formik.setFieldValue(`name.${dic.lang}`, dic.value);
      });
      formik.setFieldValue("id", data.id);
    }
  }, [data]);

  return (
    <form className="row" onSubmit={formik.handleSubmit}>
      <div className="col-md-6">
        <MultilangTextInput
          label={"Название"}
          value={formik.values.name}
          onChange={(text, lang) => {
            formik.setFieldValue(`name.${lang}`, text);
          }}
          onBlur={formik.handleBlur}
          error={formik.errors.name}
          name={"name"}
        />
      </div>
      <div className="col-md-12 text-center">
        <Button
          label={"Сохранить"}
          loading={loading}
          style={"btn btn-primary w-md"}
        />
      </div>
    </form>
  );
};
