import React, { useEffect, useState } from "react";
import { Wrapper } from "../components/Wrapper";
import { PageTitle } from "../components/PageTitle";
import { CustomTable } from "../components/CustomTable";
import { useNavigate } from "react-router-dom";
import { Notification } from "../utils/Notification";
import { useMutation, useQuery } from "@apollo/client";
import { GET_TICKETS } from "../graphql/Queries/TicketQuery";
import moment from "moment";
import { DELETE_TICKET } from "../graphql/Mutations/TicketMutation";
import i18next from "i18next";
import { setBadge } from "../redux/slices/badgeSlice";
import { useDispatch } from "react-redux";

export const Tickets = ({ url }) => {
  const [items, setItems] = useState([]);
  const navigator = useNavigate();
  const dispatch = useDispatch();

  const { data } = useQuery(GET_TICKETS, {
    fetchPolicy: "no-cache",
    variables: {
      lang: i18next.language,
    },
  });
  const [drop] = useMutation(DELETE_TICKET);

  useEffect(() => {
    if (data) {
      setItems(data.ticket);
      const badge = JSON.parse(data.badge);
      dispatch(setBadge(badge));
    }
  }, [data]);

  const remove = (id) => {
    drop({
      variables: {
        id: Number(id),
      },
    })
      .then((response) => {
        setItems(response.data.deleteTicket);
      })
      .catch((error) => {
        Notification(error);
      });
  };

  const edit = (id) => {
    navigator(`${url}/form?id=${id}`);
  };

  return (
    <Wrapper>
      <PageTitle title={"Обращения"} />
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <CustomTable
                data={items}
                columns={[
                  { key: "id", label: "ID" },
                  {
                    key: `phone`,
                    label: "Телефон",
                    // callback: (item) => {
                    //   return getField(item, "value");
                    // },
                  },
                  {
                    key: "created_at",
                    label: "Дата и время",
                    callback: (item) => {
                      return moment(item).format("HH:mm DD.MM.YYYY");
                    },
                  },
                  {
                    key: "status",
                    label: "Состояние",
                    callback: (item) => {
                      let status = data.list.find((e) => {
                        return Number(e.id) === Number(item);
                      });
                      return status.name;
                    },
                  },
                ]}
                search={true}
                sort={true}
                pagination={{
                  limit: 10,
                }}
                onClick={(id) => edit(id)}
                onDelete={(id) => remove(id)}
              />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
