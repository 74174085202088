import React, { useEffect, useState } from "react";
import { Wrapper } from "../components/Wrapper";
import { PageTitle } from "../components/PageTitle";
import { CustomTable } from "../components/CustomTable";
import { useNavigate } from "react-router-dom";
import { Notification } from "../utils/Notification";
import { useMutation, useQuery } from "@apollo/client";
import i18next from "i18next";
import { GET_METRO } from "../graphql/Queries/MetroQuery";
import { DELETE_METRO } from "../graphql/Mutations/MetroMutation";

export const Metro = ({ url }) => {
  const [items, setItems] = useState([]);
  const navigator = useNavigate();

  const { data } = useQuery(GET_METRO, {
    fetchPolicy: "no-cache",
    variables: {
      lang: i18next.language,
    },
  });
  const [removeMetro] = useMutation(DELETE_METRO);

  useEffect(() => {
    if (data) {
      setItems(data.metro);
    }
  }, [data]);

  const remove = (id) => {
    removeMetro({
      variables: {
        id: Number(id),
      },
    })
      .then((response) => {
        setItems(response.data.deleteMetro);
      })
      .catch((error) => {
        Notification(error);
      });
  };

  const edit = (id) => {
    navigator(`${url}/form?id=${id}`);
  };

  return (
    <Wrapper>
      <PageTitle title={"Метро"} />
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <CustomTable
                data={items}
                columns={[
                  { key: "id", label: "ID" },
                  { key: "name", label: "Название" },
                  {
                    key: "metro_line",
                    label: "Город",
                    callback: (metro_line) => {
                      return metro_line.nameString;
                    },
                  },
                ]}
                search={true}
                sort={true}
                pagination={{
                  limit: 10,
                }}
                actions={[
                  {
                    slug: `${url}/form`,
                    icon: "fe-plus",
                    name: "Создать",
                  },
                ]}
                onClick={(id) => edit(id)}
                onDelete={(id) => remove(id)}
              />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
