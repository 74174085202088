import { gql } from "@apollo/client";

export const MODIFY_FAST_MENU = gql`
  mutation (
    $id: Int!
    $title: String!
    $url: String!
    $type: Int!
    $sort: Int!
    $status: Int!
    $color: String
    $color_hover: String
    $icon: String
  ) {
    modifyFastMenu(
      id: $id
      title: $title
      url: $url
      type: $type
      sort: $sort
      status: $status
      color: $color
      color_hover: $color_hover
      icon: $icon
    )
  }
`;

export const DELETE_FAST_MENU = gql`
  mutation ($id: Int!) {
    deleteFastMenu(id: $id) {
      id
      title {
        lang
        value
      }
      url {
        lang
        value
      }
      type
    }
  }
`;
