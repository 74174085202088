import React from "react";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export const Create = () => {
  const { t } = useTranslation();

  return (
    <Dropdown className="dropdown d-none d-xl-block" as={"li"}>
      <Dropdown.Toggle
        className="nav-link dropdown-toggle waves-effect waves-light"
        as={"a"}
      >
        {t("Создать")}
        <i className="mdi mdi-chevron-down"></i>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item>
          <i className="fe-briefcase me-1"></i>
          <span>{t("Новый справочник")}</span>
        </Dropdown.Item>
        <Dropdown.Item>
          <i className="fe-bar-chart-line- me-1"></i>
          <span>{t("Новый жилой комплекс")}</span>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};
