import React, { useEffect, useState } from "react";
import { Wrapper } from "../components/Wrapper";
import { PageTitle } from "../components/PageTitle";
import { CustomTable } from "../components/CustomTable";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Notification } from "../utils/Notification";
import { useMutation, useQuery } from "@apollo/client";
import { GET_PROPERTIES } from "../graphql/Queries/PropertyQuery";
import i18next from "i18next";
import { Preload } from "../components/Preload";
import { INPUT_TYPE } from "../utils/Dictionaries";
import { useTranslation } from "react-i18next";
import { DELETE_PROPERTY } from "../graphql/Mutations/PropertyMutation";

export const Properties = ({ url }) => {
  const [items, setItems] = useState([]);
  const navigator = useNavigate();
  const data = useQuery(GET_PROPERTIES, {
    variables: {
      lang: i18next.language,
    },
    fetchPolicy: "no-cache",
  });
  const { t } = useTranslation();
  const [deleteProp] = useMutation(DELETE_PROPERTY);

  const remove = (id) => {
    deleteProp({
      variables: {
        id,
        lang: i18next.language,
      },
    })
      .then((response) => {
        setItems(response.data.deleteProperty);
      })
      .catch((error) => {
        Notification(error);
      });
  };

  const edit = (id) => {
    navigator(`${url}/form?id=${id}`);
  };

  useEffect(() => {
    if (data.data) {
      setItems(data.data.property);
    }
  }, [data]);

  if (!data) {
    return <Preload />;
  }

  return (
    <Wrapper>
      <PageTitle title={"Свойства"} />
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <CustomTable
                data={items}
                columns={[
                  { key: "id", label: "ID" },
                  { key: "name", label: "Название" },
                  {
                    key: "type",
                    label: "Тип",
                    callback: (value) => {
                      const inputType = INPUT_TYPE.find((item) => {
                        return item.id === value;
                      });
                      return inputType.name;
                    },
                    list: INPUT_TYPE,
                    listProp: {
                      value: "id",
                      label: "name",
                    },
                  },
                  {
                    key: "property_group.name.ru",
                    label: "Группа свойств",
                    list: data?.data?.property_group,
                    listProp: {
                      value: "id",
                      label: "nameString",
                      search: "property_group.id",
                    },
                  },
                  {
                    key: "dictionary.name.ru",
                    label: "Справочник",
                    list: data?.data?.dictionaries,
                    listProp: {
                      value: "id",
                      label: "nameString",
                      search: "dictionary.id",
                    },
                  },
                  {
                    key: "is_require",
                    label: "Обязательное?",
                    callback: (value) => {
                      return value === 1 ? t("да") : t("нет");
                    },
                    list: [
                      { id: 0, name: t("нет") },
                      { id: 1, name: t("да") },
                    ],
                    listProp: {
                      value: "id",
                      label: "name",
                    },
                  },
                ]}
                search={true}
                sort={true}
                pagination={{
                  limit: 10,
                }}
                actions={[
                  {
                    slug: `${url}/form`,
                    icon: "fe-plus",
                    name: "Создать",
                  },
                ]}
                onClick={(id) => edit(id)}
                onDelete={(id) => remove(id)}
              />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
