import { MultilangTextInput } from "./Form/MultilangTextInput";
import { FileInput } from "./Form/FileInput";
import { Col } from "react-bootstrap";
import { Checkbox } from "./Form/Checkbox";
import { Button } from "./Button";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { Notification } from "../utils/Notification";
import { useMutation, useQuery } from "@apollo/client";
import { GET_SEO } from "../graphql/Queries/SeoQuery";
import { EDIT_SEO } from "../graphql/Mutations/SeoMutation";
import { useTranslation } from "react-i18next";

export const SEO = ({ id, table }) => {
  const { t } = useTranslation();
  const { load, error, data } = useQuery(GET_SEO, {
    variables: {
      row_id: id,
      table: table,
    },
    fetchPolicy: "no-cache",
  });
  const [edit] = useMutation(EDIT_SEO);
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      title: {
        ru: "",
        az: "",
        en: "",
      },
      keywords: {
        ru: "",
        az: "",
        en: "",
      },
      description: {
        ru: "",
        az: "",
        en: "",
      },
      image: {
        ru: null,
        az: null,
        en: null,
      },
      follow: 0,
      index: 0,
      h1: {
        ru: "",
        az: "",
        en: "",
      },
    },
    onSubmit: (values) => {
      setLoading(true);
      edit({
        variables: {
          row_id: id,
          table: table,
          title: JSON.stringify(values.title),
          keywords: JSON.stringify(values.keywords),
          description: JSON.stringify(values.description),
          image: JSON.stringify(values.image),
          follow: Number(values.follow),
          index: Number(values.index),
          h1: JSON.stringify(values.h1),
        },
      })
        .then((response) => {
          if (!response.data.modifySeo) {
            toast.error(t("Ошибка сохранения"));
          } else {
            Notification(t("Сохранено"), "success");
          }
        })
        .catch((error) => {
          Notification(error);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  useEffect(() => {
    if (data?.seo) {
      formik.setFieldValue("follow", data.seo?.follow);
      formik.setFieldValue("index", data.seo?.index);
      data.seo.title.map((dic) => {
        formik.setFieldValue(`title.${dic.lang}`, dic.value);
      });
      data.seo.keywords.map((dic) => {
        formik.setFieldValue(`keywords.${dic.lang}`, dic.value);
      });
      data.seo.description.map((dic) => {
        formik.setFieldValue(`description.${dic.lang}`, dic.value);
      });
      data.seo.image.map((dic) => {
        formik.setFieldValue(`image.${dic.lang}`, dic.value);
      });
      data.seo.h1.map((dic) => {
        formik.setFieldValue(`h1.${dic.lang}`, dic.value);
      });
    }
  }, [data]);

  return (
    <div className="card">
      <div className="card-body">
        <form className="row" onSubmit={formik.handleSubmit}>
          <div className="col-md-12">
            <MultilangTextInput
              label={"Title"}
              value={formik.values.title}
              onChange={(text, lang) => {
                formik.setFieldValue(`title.${lang}`, text);
              }}
              onBlur={formik.handleBlur}
              error={formik.errors.title}
              touched={formik.touched.title}
              name={"title"}
            />
          </div>
          <div className="col-md-12">
            <MultilangTextInput
              label={"Keywords"}
              value={formik.values.keywords}
              onChange={(text, lang) => {
                formik.setFieldValue(`keywords.${lang}`, text);
              }}
              onBlur={formik.handleBlur}
              error={formik.errors.keywords}
              touched={formik.touched.keywords}
              name={"keywords"}
            />
          </div>
          <div className="col-md-12">
            <MultilangTextInput
              label={"H1"}
              value={formik.values.h1}
              onChange={(text, lang) => {
                formik.setFieldValue(`h1.${lang}`, text);
              }}
              onBlur={formik.handleBlur}
              error={formik.errors.h1}
              touched={formik.touched.h1}
              name={"h1"}
            />
          </div>
          <div className="col-md-12">
            <MultilangTextInput
              label={"Description"}
              value={formik.values.description}
              onChange={(text, lang) => {
                formik.setFieldValue(`description.${lang}`, text);
              }}
              onBlur={formik.handleBlur}
              error={formik.errors.description}
              touched={formik.touched.description}
              name={"description"}
            />
          </div>
          <div className="col-md-12">
            <FileInput
              label={"Картинка"}
              onChange={(file, lang) => {
                formik.setFieldValue(`image.${lang}`, file);
              }}
              value={formik.values.image}
            />
          </div>
          <Col md={6}>
            <Checkbox
              label={"Follow"}
              name={"follow"}
              value={formik.values.follow}
              onChange={() =>
                formik.setFieldValue("follow", !formik.values.follow)
              }
            />
          </Col>
          <Col md={6}>
            <Checkbox
              label={"Index"}
              name={"index"}
              value={formik.values.index}
              onChange={() =>
                formik.setFieldValue("index", !formik.values.index)
              }
            />
          </Col>
          <div className="col-md-12 text-center mt-2">
            <Button
              label={"Сохранить"}
              loading={loading}
              style={"btn btn-primary w-md"}
            />
          </div>
        </form>
      </div>
    </div>
  );
};
