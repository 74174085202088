import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import AirDatepicker from "air-datepicker";
import "air-datepicker/air-datepicker.css";

export const Datepicker = ({
  name,
  label,
  value,
  onChange,
  onBlur,
  error = false,
  touched,
  hint = false,
}) => {
  const { t } = useTranslation();
  const inputRef = useRef(null);
  const dp = useRef();

  useEffect(() => {
    // Save instance for the further update
    dp.current = new AirDatepicker(inputRef.current, {
      autoClose: true,
      timepicker: false,
      onSelect({ date, formattedDate }) {
        onChange(formattedDate);
      },
    });
  }, []);

  useEffect(() => {
    // Update if props are changed
    dp.current.update();
  }, []);

  return (
    <div className="form-floating mb-3">
      <input
        ref={inputRef}
        type="text"
        className={
          error && touched ? "form-control is-invalid" : "form-control"
        }
        value={value}
        onBlur={onBlur}
        placeholder={t(label)}
        id={name}
        readOnly={true}
      />
      <label htmlFor={name}>{t(label)}</label>
      {hint && (
        <OverlayTrigger placement={"top"} overlay={<Tooltip>{hint}</Tooltip>}>
          <div
            className={"text-warning"}
            style={{ position: "absolute", fontSize: 20, right: 10, top: 14 }}
          >
            <i className="fe-alert-circle"></i>
          </div>
        </OverlayTrigger>
      )}
    </div>
  );
};
