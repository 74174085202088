import { gql } from "@apollo/client";

export const MODIFY_ESTATE = gql`
  mutation (
    $id: Int!
    $status: Int!
    $reject_reason: Int
    $comment: String
    $description: String
    $images: String
    $address: String!
    $video: String
    $email: String
    $lat: String!
    $lng: String!
  ) {
    modifyEstate(
      id: $id
      status: $status
      reject_reason: $reject_reason
      comment: $comment
      description: $description
      images: $images
      address: $address
      video: $video
      email: $email
      lat: $lat
      lng: $lng
    )
  }
`;

export const DELETE_ESTATE = gql`
  mutation ($id: Int!, $page: Int, $query: String, $sort: String) {
    deleteEstate(id: $id, page: $page, query: $query, sort: $sort) {
      data {
        id
        status(asString: true)
        status_id: status
        title
        updated_at
        published_at
        object_type_id(asString: true)
        user {
          phone
        }
      }
      total
    }
  }
`;

export const MODIFY_AROUND_PROPS = gql`
  mutation (
    $object_type_id: Int!
    $recommendedProps: String!
    $aroundProps: String!
    $areaProps: String!
    $metroProps: String!
  ) {
    aroundProps(
      object_type_id: $object_type_id
      recommendedProps: $recommendedProps
      aroundProps: $aroundProps
      areaProps: $areaProps
      metroProps: $metroProps
    )
  }
`;

export const MODIFY_ESTATE_REJECT_REASON = gql`
  mutation ($id: Int!, $title: String!, $text: String!) {
    modifyEstateRejectReason(id: $id, title: $title, text: $text)
  }
`;

export const DELETE_ESTATE_REJECT_REASON = gql`
  mutation ($id: Int!) {
    deleteEstateRejectReason(id: $id) {
      id
      title {
        lang
        value
      }
    }
  }
`;

export const UPDATE_PROP_ESTATE = gql`
  mutation (
    $estate_id: Int!
    $property_id: Int!
    $value: String!
    $multiple: Boolean!
    $group: String
  ) {
    updatePropertyEstate(
      estate_id: $estate_id
      property_id: $property_id
      value: $value
      multiple: $multiple
      group: $group
    )
  }
`;

export const UPDATE_IMAGE = gql`
  mutation ($estate_id: Int!, $image_id: Int!, $action: String!) {
    updateImage(estate_id: $estate_id, image_id: $image_id, action: $action) {
      id
      url
      is_plan
      is_index
    }
  }
`;
