import React from "react";
import { useTranslation } from "react-i18next";
import { Tab, Tabs } from "react-bootstrap";
import { RichEditor } from "../RichEditor";

export const Editor = ({ name, label, value, onChange }) => {
  const { t } = useTranslation();
  const languages = [
    {
      url: "az",
      label: t("Azerbaijan"),
    },
    {
      url: "ru",
      label: t("Русский"),
    },
    {
      url: "en",
      label: t("English"),
    },
  ];
  return (
    <Tabs defaultActiveKey={languages[0].url} className={"multilang-tab"}>
      {languages.map((lang) => {
        return (
          <Tab eventKey={lang.url} key={lang.url} title={lang.label}>
            <div className="form-floating mb-3">
              <RichEditor
                placeholder={t(label)}
                onChange={(text) => {
                  onChange(text, lang.url);
                }}
                value={value[lang.url]}
              />
              {/*<Texted*/}
              {/*  placeholder={t(label)}*/}
              {/*  onChange={(text) => {*/}
              {/*    onChange(text, lang.url);*/}
              {/*  }}*/}
              {/*  value={value[lang.url]}*/}
              {/*/>*/}
            </div>
          </Tab>
        );
      })}
    </Tabs>
  );
};
