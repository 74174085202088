import { gql } from "@apollo/client";

export const MODIFY_TEMPLATE = gql`
  mutation (
    $id: Int!
    $subject: String
    $title: String
    $text: String!
    $comment: String
    $channel: String
  ) {
    modifyTemplateNotification(
      id: $id
      subject: $subject
      title: $title
      text: $text
      comment: $comment
      channel: $channel
    )
  }
`;

export const DELETE_TEMPLATE = gql`
  mutation ($id: Int!) {
    deleteTemplateNotification(id: $id) {
      id
      subject {
        value
        lang
      }
      comment
    }
  }
`;

export const TESTED_TEMPLATE = gql`
  mutation ($template_id: Int!, $lang: String!, $email: String!) {
    testTemplateNotification(
      template_id: $template_id
      lang: $lang
      email: $email
    )
  }
`;
