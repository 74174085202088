import React, { useEffect, useState } from "react";
import { Wrapper } from "../../components/Wrapper";
import { PageTitle } from "../../components/PageTitle";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Notification } from "../../utils/Notification";
import { useTranslation } from "react-i18next";
import { Button } from "../../components/Button";
import { MultilangTextInput } from "../../components/Form/MultilangTextInput";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Preload } from "../../components/Preload";
import { useMutation, useQuery } from "@apollo/client";
import { GET_ESTATE_REJECT_REASON } from "../../graphql/Queries/EstateQuery";
import { MODIFY_ESTATE_REJECT_REASON } from "../../graphql/Mutations/EstateMutation";

export const EstateRejectReasonForm = ({ url }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const [modify] = useMutation(MODIFY_ESTATE_REJECT_REASON);
  const { data } = useQuery(GET_ESTATE_REJECT_REASON, {
    variables: {
      id: Number(searchParams.get("id")),
    },
  });
  const formik = useFormik({
    initialValues: {
      id: 0,
      title: {
        ru: "",
        az: "",
        en: "",
      },
      text: {
        ru: "",
        az: "",
        en: "",
      },
    },
    validationSchema: Yup.object({
      title: Yup.object().shape({
        az: Yup.string().required(t("Объязательное поле")),
      }),
      text: Yup.object().shape({
        az: Yup.string().required(t("Объязательное поле")),
      }),
    }),
    onSubmit: (values) => {
      setLoading(true);
      modify({
        variables: {
          id: Number(values.id),
          title: JSON.stringify(values.title),
          text: JSON.stringify(values.text),
        },
      })
        .then((response) => {
          navigate(url);
          Notification(t("Все хорошо!"), "success");
        })
        .catch((error) => {
          Notification(error);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  useEffect(() => {
    if (data && data.estateRejectReason[0]) {
      data.estateRejectReason[0].title.map((dic) => {
        formik.setFieldValue(`title.${dic.lang}`, dic.value);
      });
      data.estateRejectReason[0].text.map((dic) => {
        formik.setFieldValue(`text.${dic.lang}`, dic.value);
      });
      formik.setFieldValue("id", data.estateRejectReason[0].id);
    }
  }, [data]);

  if (!data) {
    return <Preload />;
  }

  return (
    <Wrapper>
      <PageTitle
        title={
          searchParams.get("id") === null
            ? "Добавить причину"
            : "Редактировать причину"
        }
        breadcrumbs={[
          {
            slug: url,
            name: "Причины отклонения",
          },
        ]}
      />
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <form className="row" onSubmit={formik.handleSubmit}>
                <div className="col-md-12">
                  <MultilangTextInput
                    label={"Заголовок"}
                    value={formik.values.title}
                    onChange={(text, lang) => {
                      formik.setFieldValue(`title.${lang}`, text);
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.errors.title}
                    touched={formik.touched.title}
                    name={"title"}
                  />
                </div>
                <div className="col-md-12">
                  <MultilangTextInput
                    type={"textarea"}
                    label={"Текст"}
                    value={formik.values.text}
                    onChange={(text, lang) => {
                      formik.setFieldValue(`text.${lang}`, text);
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.errors.text}
                    touched={formik.touched.text}
                    name={"text"}
                  />
                </div>
                <div className="col-md-12 text-center">
                  <Button
                    label={"Сохранить"}
                    loading={loading}
                    style={"btn btn-primary w-md"}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
