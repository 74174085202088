import React, { useEffect, useState } from "react";
import { Wrapper } from "../../components/Wrapper";
import { PageTitle } from "../../components/PageTitle";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Col, Form } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { Preload } from "../../components/Preload";
import {
  QUICK_FILTERS,
  THUMB_PROPS,
} from "../../graphql/Mutations/ObjectTypeMutation";
import { GET_LIST_PROPERTIES } from "../../graphql/Queries/PropertyQuery";
import i18next from "i18next";
import { Button } from "../../components/Button";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { GET_QUICK_FILTERS } from "../../graphql/Queries/ObjectTypeQuery";

export const QuickFilters = ({ id = null }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const [save] = useMutation(QUICK_FILTERS);
  const { load, error, data } = useQuery(GET_QUICK_FILTERS, {
    variables: {
      id: Number(searchParams.get("id")),
    },
    fetchPolicy: "no-cache",
  });
  if (error) {
    toast.error(error.message);
  }
  const [props, setProps] = useState([]);
  const [arr, setArr] = useState([]);

  useEffect(() => {
    if (data) {
      setProps(data?.quickFilter);
      const _arr = [];
      data?.objectTypeQuickFilter.map((e) => {
        _arr.push(e.quick_filter_id);
      });
      setArr(_arr);
    }
  }, [data]);

  if (!data) {
    return <Preload />;
  }

  return (
    <Wrapper>
      <PageTitle
        title={"Свойства для блока объявления"}
        breadcrumbs={[
          {
            slug: "/type-objects",
            name: "Типы объектов",
          },
        ]}
      />
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <Form>
                {props.map((item) => {
                  return (
                    <Form.Check
                      key={item.id}
                      type="switch"
                      checked={arr.includes(Number(item.id))}
                      value={arr.includes(Number(item.id))}
                      label={item.name}
                      onChange={() => {
                        if (arr.includes(Number(item.id))) {
                          const _arr = [...arr];
                          _arr.splice(_arr.indexOf(Number(item.id)), 1);
                          setArr(_arr);
                        } else {
                          setArr([...arr, Number(item.id)]);
                        }
                      }}
                    />
                  );
                })}
                <Col md={12} style={{ textAlign: "center", marginTop: 20 }}>
                  <Button
                    label={"Сохранить"}
                    loading={loading}
                    type={"button"}
                    style={"btn btn-primary w-md"}
                    onClick={() => {
                      console.log("[Log]", arr);
                      save({
                        variables: {
                          props: JSON.stringify(arr),
                          id: Number(searchParams.get("id")),
                        },
                      })
                        .then((response) => {
                          toast.success(t("Настройки сохранены"));
                        })
                        .catch((error) => {
                          toast.error(error.message);
                        });
                    }}
                  />
                </Col>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
