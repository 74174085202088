import React, { useState } from "react";
import { Wrapper } from "../../components/Wrapper";
import { PageTitle } from "../../components/PageTitle";
import { Notification } from "../../utils/Notification";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Preload } from "../../components/Preload";
import { useQuery } from "@apollo/client";
import i18next from "i18next";
import { GET_TAGS_NEWS } from "../../graphql/Queries/NewsQuery";
import { TagNewsFormComponent } from "../../components/forms/TagNewsFormComponent";

export const FormTagNews = ({ url }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  let location = useLocation();
  const id = location.state ? location.state.id : null;
  let [searchParams, setSearchParams] = useSearchParams();
  const { data } = useQuery(GET_TAGS_NEWS, {
    variables: {
      id: Number(searchParams.get("id")),
      lang: i18next.language,
    },
  });

  if (!data) {
    return <Preload />;
  }

  return (
    <Wrapper>
      <PageTitle
        title={id === 0 ? "Добавить тег" : "Редактировать тег"}
        breadcrumbs={[
          {
            slug: url,
            name: "Теги",
          },
        ]}
      />
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <TagNewsFormComponent
                url={url}
                data={data}
                onSuccess={() => {
                  navigate(url);
                  Notification(t("Элемент добавлен"), "success");
                }}
                onError={(error) => Notification(error)}
              />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
