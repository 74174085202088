import { gql } from "@apollo/client";

export const MODIFY_PROMOCODE = gql`
  mutation (
    $id: Int!
    $start_date: String!
    $end_date: String!
    $price: Int!
    $user_type: Int!
    $type: Int!
    $amount: Int
    $name: String
  ) {
    modifyPromoCode(
      id: $id
      start_date: $start_date
      end_date: $end_date
      price: $price
      user_type: $user_type
      type: $type
      amount: $amount
      name: $name
    )
  }
`;

export const DELETE_PROMOCODE = gql`
  mutation ($id: Int!) {
    deletePromoCode(id: $id) {
      id
      start_date
      end_date
      code
      type
      user_type
      code
      name
    }
  }
`;
