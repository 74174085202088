import { gql } from "@apollo/client";

export const EDIT_HOME_MODULE = gql`
  mutation ($id: Int!, $is_enable: Int!) {
    updateHomeModule(id: $id, is_enable: $is_enable) {
      id
      name
      is_enable
    }
  }
`;
