import { gql } from "@apollo/client";

export const MODIFY_CITY = gql`
  mutation (
    $id: Int!
    $name: String!
    $country_id: Int!
    $lat: String!
    $lng: String!
    $objects: String
  ) {
    modifyCity(
      id: $id
      name: $name
      country_id: $country_id
      lat: $lat
      lng: $lng
      objects: $objects
    )
  }
`;

export const DELETE_CITY = gql`
  mutation ($id: Int!) {
    deleteCity(id: $id) {
      id
      name {
        lang
        value
      }
      country {
        name {
          lang
          value
        }
      }
    }
  }
`;
