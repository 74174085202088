import { gql } from "@apollo/client";

export const MODIFY_POINT = gql`
  mutation (
    $id: Int!
    $name: String!
    $type: Int!
    $address: String!
    $lat: String!
    $lng: String!
    $city_id: Int!
    $area_id: Int
  ) {
    modifyPoint(
      id: $id
      name: $name
      type: $type
      address: $address
      lat: $lat
      lng: $lng
      city_id: $city_id
      area_id: $area_id
    )
  }
`;

export const DELETE_POINT = gql`
  mutation ($id: Int!, $lang: String!) {
    deletePoint(id: $id) {
      id
      nameString(lang: $lang)
      type
    }
  }
`;
