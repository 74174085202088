import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useRoutes } from "react-router-dom";

export const Menu = ({ item }) => {
  const [open, setOpen] = useState(false);
  const navigate = useLocation();
  const pathParts = navigate.pathname.split("/");
  const navigator = useNavigate();

  useEffect(() => {
    let isChildren = undefined;
    if (item.children) {
      isChildren = item.children.find((child) => {
        return child.slug === pathParts[1];
      });
    }
    if (isChildren !== undefined) {
      setOpen(true);
    }
  }, []);

  return (
    <li>
      <a
        onClick={() => {
          if (item.children) {
            setOpen(!open);
          } else {
            navigator(`/${item.slug}`);
          }
        }}
        data-bs-toggle="collapse"
        aria-expanded={open}
        aria-controls="sidebarDashboards"
        className="waves-effect collapsed"
      >
        <i className={item.icon}></i>
        {item.badge > 0 && (
          <span className="badge bg-danger rounded-pill float-end">
            {item.badge}
          </span>
        )}
        <span> {item.name} </span>
        {item.children && !item.badge && <span className="menu-arrow"></span>}
      </a>
      {item.children && (
        <div
          className={open ? "collapse show" : "collapse"}
          id="sidebarDashboards"
        >
          <ul className="nav-second-level">
            {item.children.map((child, index) => {
              return (
                <li
                  key={index}
                  className={
                    child.slug === pathParts[1] ? "menuitem-active" : ""
                  }
                >
                  <Link
                    className={child.slug === pathParts[1] ? "active" : ""}
                    to={`/${child.slug}`}
                  >
                    {child.name}
                    {child.badge > 0 && (
                      <span className="badge bg-danger rounded-pill float-end">
                        {child.badge}
                      </span>
                    )}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </li>
  );
};
