import React, { useEffect, useState } from "react";
import { Wrapper } from "../../components/Wrapper";
import { PageTitle } from "../../components/PageTitle";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Notification } from "../../utils/Notification";
import { useTranslation } from "react-i18next";
import { Button } from "../../components/Button";
import { MultilangTextInput } from "../../components/Form/MultilangTextInput";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Preload } from "../../components/Preload";
import { Selected } from "../../components/Form/Selected";
import { PropertyGroupForm } from "../../components/forms/PropertyGroupForm";
import { useMutation, useQuery } from "@apollo/client";
import { GET_PROPERTY } from "../../graphql/Queries/PropertyQuery";
import i18next from "i18next";
import {
  CONTENT_TYPE,
  DISPLAY,
  INPUT_TYPE,
  SIZE_COLUMN,
} from "../../utils/Dictionaries";
import { Checkbox } from "../../components/Form/Checkbox";
import { MODIFY_PROPERTY } from "../../graphql/Mutations/PropertyMutation";
import { Editor } from "../../components/Form/Editor";
import { TextInput } from "../../components/Form/TextInput";
import { FileInput } from "../../components/Form/FileInput";
import Select from "react-select";

export const FormProperties = ({ url }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const [modify] = useMutation(MODIFY_PROPERTY);
  const { data, error, load } = useQuery(GET_PROPERTY, {
    variables: {
      id: Number(searchParams.get("id")),
      lang: i18next.language,
    },
    fetchPolicy: "no-cache",
  });
  const formik = useFormik({
    initialValues: {
      id: 0,
      name: {
        ru: "",
        az: "",
        en: "",
      },
      property_group_id: null,
      type: 1,
      dictionary_id: null,
      content_type: 1,
      hint: {
        ru: "",
        az: "",
        en: "",
      },
      hint_text: {
        ru: "",
        az: "",
        en: "",
      },
      is_require: false,
      show_in_filter: false,
      display: 1,
      error_message: {
        ru: "",
        az: "",
        en: "",
      },
      mask: {
        ru: "",
        az: "",
        en: "",
      },
      label_icon: "",
      max_length: null,
      parent_id: 0,
      size: 1,
      key: "",
      filterRawQuery: [
        {
          title: "",
          property_id: [],
          where: null,
          value: "",
        },
      ],
    },
    validationSchema: Yup.object({
      name: Yup.object().shape({
        ru: Yup.string().required(t("Объязательное поле")),
      }),
      key: Yup.string()
        .test(
          "onlyLetter",
          t("Поле может содержать только буквы и дефис"),
          function (value) {
            if (!value) {
              return true;
            }
            return value.match(/^[a-z\-]+$/g);
          },
        )
        .required(t("Объязательное поле")),
    }),
    onSubmit: (values) => {
      setLoading(true);
      modify({
        variables: {
          id: values.id,
          name: JSON.stringify(values.name),
          hint: JSON.stringify(values.hint),
          hint_text: JSON.stringify(values.hint_text),
          error_message: JSON.stringify(values.error_message),
          mask: JSON.stringify(values.mask),
          property_group_id: values.property_group_id,
          type: values.type,
          dictionary_id: values.dictionary_id,
          content_type: values.content_type,
          is_require: values.is_require,
          display: values.display,
          label_icon: values.label_icon,
          show_in_filter: values.show_in_filter,
          max_length: Number(values.max_length),
          parent_id: Number(values.parent_id),
          size: Number(values.size),
          key: values.key,
          raw_query: JSON.stringify(values.filterRawQuery),
        },
      })
        .then((response) => {
          if (!response.data.modifyProperty) {
            Notification("Ошибка!");
          } else {
            navigate("/properties");
            Notification(t("Все хорошо!"), "success");
          }
        })
        .catch((error) => {
          Notification(error);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });
  // const propertyGroups = useQuery(GET_PROPERTY_GROUPS);
  const [propertyGroupOptions, setPropertyGroupOptions] = useState([]);
  const [dictionaryOptions, setDictionaryOptions] = useState([]);
  const [propertiesOptions, setPropertiesOptions] = useState([]);
  const [exKeys, setExKeys] = useState([]);
  const [childrenProps, setChildrenProps] = useState([]);

  useEffect(() => {
    if (data && data.property[0]) {
      formik.setFieldValue("id", data.property[0].id);
      data.property[0].name.map((dic) => {
        formik.setFieldValue(`name.${dic.lang}`, dic.value);
      });
      data.property[0].hint.map((dic) => {
        formik.setFieldValue(`hint.${dic.lang}`, dic.value);
      });
      data.property[0].hint_text.map((dic) => {
        formik.setFieldValue(`hint_text.${dic.lang}`, dic.value);
      });
      data.property[0].mask.map((dic) => {
        formik.setFieldValue(`mask.${dic.lang}`, dic.value);
      });
      data.property[0].error_message.map((dic) => {
        formik.setFieldValue(`error_message.${dic.lang}`, dic.value);
      });
      formik.setFieldValue("content_type", data.property[0].content_type);
      formik.setFieldValue("display", data.property[0].display);
      formik.setFieldValue("is_require", data.property[0].is_require === 1);
      formik.setFieldValue("type", data.property[0].type);
      formik.setFieldValue("max_length", data.property[0].max_length);
      formik.setFieldValue("parent_id", data.property[0].parent_id);
      formik.setFieldValue("dictionary_id", data.property[0].dictionary?.id);
      if (data.property[0].raw_query) {
        formik.setFieldValue(
          "filterRawQuery",
          JSON.parse(data.property[0].raw_query),
        );
      }
      const icons = JSON.parse(data.property[0].label_icon);
      if (icons) {
        formik.setFieldValue("label_icon", icons[0] ?? "");
      }
      formik.setFieldValue("size", data.property[0].size);
      formik.setFieldValue("key", data.property[0].key);
      formik.setFieldValue(
        "property_group_id",
        data.property[0].property_group.id,
      );
      formik.setFieldValue(
        "show_in_filter",
        Number(data.property[0].show_in_filter) === 1,
      );
      const _child = data.property[0].children.map((item) => {
        return {
          value: item.id,
          label: item.name.find((it) => it.lang === i18next.language).value,
        };
      });
      setChildrenProps(_child);
    }
    if (data && data.property_group) {
      const _arr = [];
      data.property_group.map((item) => {
        const name = item.name.find((n) => {
          return n.lang === i18next.language;
        });
        _arr.push({
          id: item.id,
          name: name.value,
        });
      });
      setPropertyGroupOptions(_arr);
    }
    if (data && data.dictionaries) {
      const _arr = [];
      data.dictionaries.map((item) => {
        const name = item.name.find((n) => {
          return n.lang === i18next.language;
        });
        _arr.push({
          id: item.id,
          name: name.value,
        });
      });
      setDictionaryOptions(_arr);
    }
    if (data && data.properties) {
      const _arr = [];
      data.properties.map((item) => {
        if (Number(item.type) === 6) {
          _arr.push({
            id: item.id,
            name: item.nameString,
          });
        }
      });
      setPropertiesOptions(_arr);
    }
    if (data && data.propertyKeys) {
      const keys = JSON.parse(data.propertyKeys);
      if (data.property[0] && data.property[0].key) {
        keys.splice(keys.indexOf(data.property[0].key), 1);
      }
      setExKeys(keys);
    }
  }, [data]);

  if (!data) {
    return <Preload />;
  }

  return (
    <Wrapper>
      <PageTitle
        title={
          searchParams.get("id") === null
            ? "Добавить свойство"
            : "Редактировать свойство"
        }
        breadcrumbs={[
          {
            slug: url,
            name: "Свойства",
          },
        ]}
      />
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <form className="row" onSubmit={formik.handleSubmit}>
                <div className="col-md-4">
                  <MultilangTextInput
                    label={"Название"}
                    value={formik.values.name}
                    onChange={(text, lang) => {
                      formik.setFieldValue(`name.${lang}`, text);
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.errors.name}
                    name={"name"}
                  />
                </div>
                <div className="col-md-4">
                  <Selected
                    label={"Группа свойств"}
                    onChange={(value) => {
                      formik.setFieldValue(`property_group_id`, value);
                    }}
                    error={formik.errors.property_group_id}
                    touched={formik.touched.property_group_id}
                    name={"property_group_id"}
                    modalForm={
                      <PropertyGroupForm
                        data={false}
                        url={"/property-groups"}
                        onSuccess={() => {
                          Notification(t("Группа создана"));
                        }}
                      />
                    }
                    value={formik.values.property_group_id}
                    style={{ marginTop: "40px" }}
                    staticOptions={propertyGroupOptions}
                  />
                </div>
                <div className="col-md-4">
                  <Selected
                    label={"Входит в"}
                    onChange={(value) => {
                      formik.setFieldValue(`parent_id`, value ? value : 0);
                    }}
                    error={formik.errors.parent_id}
                    touched={formik.touched.parent_id}
                    name={"parent_id"}
                    value={formik.values.parent_id}
                    style={{ marginTop: "40px", paddingRight: 0 }}
                    rows={{ value: "id", label: "name" }}
                    staticOptions={propertiesOptions}
                    isClearable={true}
                  />
                </div>
                <div className="col-md-6">
                  <Selected
                    label={"Тип"}
                    onChange={(value) => {
                      formik.setFieldValue(`type`, value);
                    }}
                    error={formik.errors.type}
                    touched={formik.touched.type}
                    name={"type"}
                    value={formik.values.type}
                    staticOptions={INPUT_TYPE}
                    style={{ paddingRight: 0 }}
                  />
                </div>
                <div className="col-md-6">
                  <Selected
                    label={"Размер"}
                    onChange={(value) => {
                      formik.setFieldValue(`size`, value);
                    }}
                    error={formik.errors.size}
                    touched={formik.touched.size}
                    name={"size"}
                    value={formik.values.size}
                    staticOptions={SIZE_COLUMN}
                    style={{ paddingRight: 0 }}
                  />
                </div>
                {(formik.values.type === 2 ||
                  formik.values.type === 4 ||
                  formik.values.type === 7 ||
                  formik.values.type === 8) && (
                  <div className="col-md-6">
                    <Selected
                      label={"Справочник"}
                      onChange={(value) => {
                        formik.setFieldValue(`dictionary_id`, value);
                      }}
                      error={formik.errors.dictionary_id}
                      touched={formik.touched.dictionary_id}
                      name={"type"}
                      value={formik.values.dictionary_id}
                      staticOptions={dictionaryOptions}
                      rows={{ value: "id", label: "name" }}
                      style={{ paddingRight: 0 }}
                    />
                  </div>
                )}
                {formik.values.type === 1 && (
                  <div className="col-md-6">
                    <Selected
                      label={"Тип содержимого"}
                      onChange={(value) => {
                        formik.setFieldValue(`content_type`, value);
                      }}
                      error={formik.errors.content_type}
                      touched={formik.touched.content_type}
                      name={"type"}
                      value={formik.values.content_type}
                      staticOptions={CONTENT_TYPE}
                      style={{ paddingRight: 0 }}
                    />
                  </div>
                )}
                {(formik.values.type === 1 || formik.values.type === 5) && (
                  <div className="col-md-6">
                    <TextInput
                      label={"Максимальное к-во символов"}
                      value={formik.values.max_length}
                      onChange={(text) => {
                        formik.setFieldValue(`max_length`, text.target.value);
                      }}
                      onBlur={formik.handleBlur}
                      error={formik.errors.max_length}
                      name={"max_length"}
                      hint={t(
                        "в счетчике вы указываете уже не длину, а значение. то есть, если вы указали 1 - то это макс 1. если вам нужен 1 символ, то вы можете указать 9, тогда можно будет увеличить до 9",
                      )}
                    />
                  </div>
                )}
                <div className="col-md-6">
                  <TextInput
                    label={"Уникальный ключ"}
                    value={formik.values.key}
                    onChange={(text) => {
                      formik.setFieldValue(`key`, text.target.value);
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.errors.key}
                    touched={formik.touched.key}
                    name={"key"}
                  />
                </div>
                <div className="col-md-12">
                  <MultilangTextInput
                    label={"Подсказка"}
                    value={formik.values.hint}
                    onChange={(text, lang) => {
                      formik.setFieldValue(`hint.${lang}`, text);
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.errors.hint}
                    name={"hint"}
                  />
                </div>
                <div className="col-md-12">
                  <Editor
                    value={formik.values.hint_text}
                    label={"Текст подсказки"}
                    onChange={(text, lang) => {
                      formik.setFieldValue(`hint_text.${lang}`, text);
                    }}
                  />
                </div>
                <div className="col-md-6">
                  <MultilangTextInput
                    label={"Текст ошибки"}
                    value={formik.values.error_message}
                    onChange={(text, lang) => {
                      formik.setFieldValue(`error_message.${lang}`, text);
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.errors.hint}
                    name={"error_message"}
                  />
                </div>
                <div className="col-md-6">
                  <MultilangTextInput
                    label={"Маска"}
                    value={formik.values.mask}
                    onChange={(text, lang) => {
                      formik.setFieldValue(`mask.${lang}`, text);
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.errors.mask}
                    name={"mask"}
                  />
                </div>
                <div className="col-md-6">
                  <Selected
                    label={"Показывать ..."}
                    onChange={(value) => {
                      formik.setFieldValue(`display`, value);
                    }}
                    error={formik.errors.display}
                    touched={formik.touched.display}
                    name={"type"}
                    value={formik.values.display}
                    staticOptions={DISPLAY}
                    style={{ paddingRight: 0 }}
                  />
                </div>
                <div className="col-md-6">
                  <Checkbox
                    label={"Обязательное?"}
                    value={formik.values.is_require}
                    onChange={(value) =>
                      formik.setFieldValue("is_require", value)
                    }
                  />
                  <Checkbox
                    label={"Показывать в фильтрации?"}
                    value={formik.values.show_in_filter}
                    onChange={(value) =>
                      formik.setFieldValue("show_in_filter", value)
                    }
                  />
                </div>
                {data.property[0]?.children.length > 0 && (
                  <div className="col-md-12">
                    <h4>{t("Собственные условия фильтрации")}</h4>
                    {formik.values.filterRawQuery.map((filter, index) => {
                      return (
                        <div className="card">
                          <div className="card-body">
                            <div className="card-widgets">
                              {index ===
                                formik.values.filterRawQuery.length - 1 && (
                                <a
                                  href="javascript:;"
                                  onClick={() => {
                                    const _variants = [
                                      ...formik.values.filterRawQuery,
                                    ];
                                    _variants.push({
                                      title: "",
                                      property_id: [],
                                      where: null,
                                      value: "",
                                    });
                                    formik.setFieldValue(
                                      "filterRawQuery",
                                      _variants,
                                    );
                                  }}
                                >
                                  <i className="mdi mdi-plus"></i>
                                </a>
                              )}
                              <a
                                href="javascript:void(0);"
                                onClick={() => {
                                  const _variants = [...formik.values.variants];
                                  _variants.splice(index, 1);
                                  formik.setFieldValue("variants", _variants);
                                }}
                                data-toggle="remove"
                              >
                                <i className="mdi mdi-close"></i>
                              </a>
                            </div>

                            <div className="collapse pt-3 show">
                              <div className={"row"}>
                                <div className="col-md-4">
                                  <input
                                    type={"text"}
                                    className={"form-control"}
                                    placeholder={"Название"}
                                    value={
                                      formik.values.filterRawQuery[index].title
                                    }
                                    onChange={(text, lang) => {
                                      formik.setFieldValue(
                                        `filterRawQuery[${index}].title`,
                                        text.target.value,
                                      );
                                    }}
                                  />
                                </div>
                                <div className="col-md-4">
                                  <Select
                                    isMulti={true}
                                    isClearable={true}
                                    className="react-select react-select-container"
                                    classNamePrefix="react-select"
                                    options={childrenProps}
                                    placeholder={t("Выберите")}
                                    value={
                                      formik.values.filterRawQuery[index]
                                        .property_id
                                    }
                                    onChange={(e) => {
                                      formik.setFieldValue(
                                        `filterRawQuery[${index}].property_id`,
                                        e,
                                      );
                                    }}
                                  ></Select>
                                </div>
                                <div className="col-md-1">
                                  <select
                                    className={"form-control"}
                                    onChange={(text) => {
                                      formik.setFieldValue(
                                        `filterRawQuery[${index}].where`,
                                        text.target.value,
                                      );
                                    }}
                                    value={
                                      formik.values.filterRawQuery[index].where
                                    }
                                  >
                                    <option value={">"}>{">"}</option>
                                    <option value={"<"}>{"<"}</option>
                                    <option value={"="}>=</option>
                                    <option value={">="}>{">="}</option>
                                    <option value={"<="}>{"<="}</option>
                                  </select>
                                </div>
                                <div className="col-md-3">
                                  <input
                                    type={"text"}
                                    className={"form-control"}
                                    placeholder={"Значение"}
                                    onChange={(text, lang) => {
                                      formik.setFieldValue(
                                        `filterRawQuery[${index}].value`,
                                        text.target.value,
                                      );
                                    }}
                                    value={
                                      formik.values.filterRawQuery[index].value
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
                <div className="col-md-12">
                  <FileInput
                    label={"Иконка"}
                    onChange={(file) => {
                      formik.setFieldValue(`label_icon`, file);
                    }}
                    onDelete={() => {
                      formik.setFieldValue(`label_icon`, "");
                    }}
                    value={formik.values.label_icon}
                    multilang={false}
                  />
                </div>
                <div className="col-md-12 text-center">
                  <Button
                    label={"Сохранить"}
                    loading={loading}
                    style={"btn btn-primary w-md"}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
