import { gql } from "@apollo/client";

export const EDIT_STEP = gql`
  mutation (
    $id: Int!
    $name: String!
    $name_system: String!
    $help_title: String
    $help_text: String
    $key: String!
  ) {
    updateStep(
      id: $id
      name: $name
      name_system: $name_system
      help_title: $help_title
      help_text: $help_text
      key: $key
    )
  }
`;

export const ADD_STEP = gql`
  mutation (
    $name: String!
    $name_system: String!
    $help_title: String
    $help_text: String
    $key: String!
  ) {
    addStep(
      name: $name
      name_system: $name_system
      help_title: $help_title
      help_text: $help_text
      key: $key
    )
  }
`;

export const DELETE_STEP = gql`
  mutation ($id: Int!) {
    deleteStep(id: $id) {
      id
      name {
        lang
        value
      }
      name_system {
        lang
        value
      }
    }
  }
`;
