import { gql } from "@apollo/client";

export const GET_HOME_MODULES = gql`
  query {
    home_module {
      id
      name
      is_enable
    }
  }
`;
