export const Slug = (word) => {
  const a = {
    а: "a",
    б: "b",
    в: "v",
    г: "g",
    д: "d",
    е: "e",
    ё: "yo",
    ж: "zh",
    з: "z",
    и: "i",
    й: "y",
    к: "k",
    л: "l",
    м: "m",
    н: "n",
    о: "o",
    п: "p",
    р: "r",
    с: "s",
    т: "t",
    у: "u",
    ф: "f",
    х: "h",
    ц: "ts",
    ч: "ch",
    ш: "sh",
    щ: "shch",
    ъ: "",
    ы: "y",
    ь: "",
    э: "e",
    ю: "yu",
    я: "ya",
    А: "A",
    Б: "B",
    В: "V",
    Г: "G",
    Д: "D",
    Е: "E",
    Ё: "Yo",
    Ж: "Zh",
    З: "Z",
    И: "I",
    Й: "Y",
    К: "K",
    Л: "L",
    М: "M",
    Н: "N",
    О: "O",
    П: "P",
    Р: "R",
    С: "S",
    Т: "T",
    У: "U",
    Ф: "F",
    Х: "H",
    Ц: "Ts",
    Ч: "Ch",
    Ш: "Sh",
    Щ: "Shch",
    Ъ: "",
    Ы: "Y",
    Ь: "",
    Э: "E",
    Ю: "Yu",
    Я: "Ya",
    " ": "-",
    ",": "",
    ".": "",
    "?": "",
    "!": "",
    ":": "",
    ";": "",
    '"': "",
    "'": "",
    "(": "",
    ")": "",
    "[": "",
    "]": "",
    "{": "",
    "}": "",
    "<": "",
    ">": "",
    "@": "",
    "#": "",
    $: "",
    "%": "",
    "^": "",
    "&": "",
    "*": "",
    "+": "",
    "=": "",
    1: "1",
    2: "2",
    3: "3",
    4: "4",
    5: "5",
    6: "6",
    7: "7",
    8: "8",
    9: "9",
    0: "0",
    ə: "e",
    ö: "o",
    ğ: "g",
    ı: "i",
    ü: "u",
    ç: "c",
    ş: "s",
    "«": "",
    "»": "",
    q: "q",
    w: "w",
    r: "r",
    e: "e",
    t: "t",
    y: "y",
    u: "u",
    i: "i",
    o: "o",
    p: "p",
    a: "a",
    s: "s",
    d: "d",
    g: "g",
    h: "h",
    j: "j",
    k: "k",
    l: "l",
    z: "z",
    x: "x",
    c: "c",
    b: "b",
    v: "v",
    n: "n",
    m: "m",
    i̇: "i",
  };
  return word
    .split("")
    .map(function (char) {
      return a[char.toLowerCase()] ?? "";
    })
    .join("")
    .toLowerCase();
};
