import React, { useEffect, useState } from "react";
import { Wrapper } from "../components/Wrapper";
import { PageTitle } from "../components/PageTitle";
import { useNavigate } from "react-router-dom";
import { Notification } from "../utils/Notification";
import { useMutation, useQuery } from "@apollo/client";
import { Preload } from "../components/Preload";
import { GET_PAGES } from "../graphql/Queries/Page";
import { DELETE_PAGE } from "../graphql/Mutations/PageMutation";
import Nestable from "react-nestable";
import { Button, Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "react-nestable/dist/styles/index.css";
import i18n from "i18next";

export const Pages = ({ url }) => {
  const { t } = useTranslation();
  //const [items, setItems] = useState([]);
  const navigator = useNavigate();
  const { loading, error, data } = useQuery(GET_PAGES, {
    fetchPolicy: "no-cache",
  });
  const [deletePage] = useMutation(DELETE_PAGE);
  const [topMenu, setTopMenu] = useState([]);
  const [bottomMenu, setBottomMenu] = useState([]);

  const remove = (id) => {
    if (window.confirm(t("Удалить?"))) {
      deletePage({
        variables: {
          id: Number(id),
        },
      })
        .then((response) => {
          sortMenu(response.data.deleteMenu);
        })
        .catch((error) => {
          Notification(error);
        });
    }
  };

  const RenderItem = ({ item, collapseIcon, handler }) => {
    return (
      <div className="dd-list">
        <div className="dd-item">
          <div
            className="dd-handle"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              {handler}
              {collapseIcon}
              <span
                onClick={() =>
                  navigator(
                    `/pages/form?id=${item.id}&position=${item.position}`,
                  )
                }
              >
                {item.text}
              </span>
            </div>
            <i className={"fe-x"} onClick={() => remove(item.id)} />
          </div>
        </div>
      </div>
    );
  };

  const sortMenu = (data) => {
    let top = [];
    let bottom = [];
    data.map((m) => {
      if (m.position === 1) {
        const name = m.name.find((n) => {
          return n.lang === i18n.language;
        });
        if (m.parent_id === 0) {
          top.push({
            id: m.id,
            text: name.value,
            position: m.position,
            children: [],
          });
        } else {
          top.map((t) => {
            if (Number(t.id) === Number(m.parent_id)) {
              t.children.push({
                id: m.id,
                text: name.value,
                position: m.position,
                children: [],
              });
            }
            return t;
          });
        }
      } else if (m.position === 2) {
        const name = m.name.find((n) => {
          return n.lang === i18n.language;
        });
        if (m.parent_id === 0) {
          bottom.push({
            id: m.id,
            text: name.value,
            position: m.position,
            children: [],
          });
        } else {
          bottom.map((t) => {
            if (Number(t.id) === Number(m.parent_id)) {
              t.children.push({
                id: m.id,
                text: name.value,
                position: m.position,
                children: [],
              });
            }
            return t;
          });
        }
      }
    });
    console.log("[Log]", top);
    setTopMenu(top);
    setBottomMenu(bottom);
  };

  useEffect(() => {
    if (data) {
      sortMenu(data.menu);
    }
  }, [data]);

  if (!data) {
    return <Preload />;
  }

  return (
    <Wrapper>
      <PageTitle title={"Страницы"} />
      <div className="row">
        <Col md={6}>
          <Card>
            <Card.Body>
              <Row style={{ marginBottom: 20 }}>
                <Col>
                  <h4 className="header-title">{t("Верхнее меню")}</h4>
                </Col>
                <Col style={{ textAlign: "right" }}>
                  <Button
                    size={"sm"}
                    variant={"primary"}
                    className="waves-effect waves-light"
                    onClick={() => {
                      navigator("/pages/form?position=1");
                    }}
                  >
                    {t("Добавить")}
                  </Button>
                </Col>
              </Row>
              <Nestable
                items={topMenu}
                renderItem={RenderItem}
                onChange={(items) => {
                  console.log("[Log]", items);
                }}
              />
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card>
            <Card.Body>
              <Row style={{ marginBottom: 20 }}>
                <Col>
                  <h4 className="header-title">{t("Нижнее меню")}</h4>
                </Col>
                <Col style={{ textAlign: "right" }}>
                  <Button
                    size={"sm"}
                    variant={"primary"}
                    className="waves-effect waves-light"
                    onClick={() => {
                      navigator("/pages/form?position=2");
                    }}
                  >
                    {t("Добавить")}
                  </Button>
                </Col>
              </Row>
              <Nestable items={bottomMenu} renderItem={RenderItem} />
            </Card.Body>
          </Card>
        </Col>
      </div>
    </Wrapper>
  );
};
