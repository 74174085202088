import React, { useEffect, useState } from "react";
import { Wrapper } from "../components/Wrapper";
import { PageTitle } from "../components/PageTitle";
import { CustomTable } from "../components/CustomTable";
import { useNavigate } from "react-router-dom";
import { Notification } from "../utils/Notification";
import { useMutation, useQuery } from "@apollo/client";
import { GET_TEMPLATES } from "../graphql/Queries/TemplateNotificationQuery";
import {
  DELETE_TEMPLATE,
  TESTED_TEMPLATE,
} from "../graphql/Mutations/TemplateNotificationMutation";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";
import { LANG } from "../utils/Dictionaries";
import { toast } from "react-toastify";

export const TemplateNotifications = ({ url }) => {
  const [items, setItems] = useState([]);
  const navigator = useNavigate();
  const { data } = useQuery(GET_TEMPLATES, {
    fetchPolicy: "no-cache",
  });
  const [drop] = useMutation(DELETE_TEMPLATE);
  const [send] = useMutation(TESTED_TEMPLATE);
  const [showModal, toggleModal] = useState(false);
  const { t } = useTranslation();
  const formik = useFormik({
    initialValues: {
      template_id: 1,
      lang: "az",
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required(t("Объязательное поле"))
        .email("Не верный формат email"),
    }),
    onSubmit: (values) => {
      send({
        variables: {
          template_id: Number(values.template_id),
          email: values.email,
          lang: values.lang,
        },
      })
        .then((res) => {
          if (res.data.testTemplateNotification) {
            toast.success(t("Тестовый email отправлен"));
          } else {
            Notification(t("Ошибка отправки"));
          }
          toggleModal(false);
        })
        .catch((error) => {
          Notification(error);
        });
    },
  });

  useEffect(() => {
    if (data) {
      setItems(data.templateNotification);
    }
  }, [data]);

  const remove = (id) => {
    drop({
      variables: {
        id: Number(id),
      },
    })
      .then((response) => {
        setItems(response.data.deleteTemplateNotification);
      })
      .catch((error) => {
        Notification(error.message);
      });
  };

  const edit = (id) => {
    navigator(`${url}/form?id=${id}`);
  };

  return (
    <Wrapper>
      <Modal show={showModal}>
        <Modal.Header closeButton onClick={() => toggleModal(false)}>
          <Modal.Title>{t("Тестирование шаблона")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={formik.handleSubmit}>
            <Form.Group as={Row} className="mb-2">
              <Form.Label column md={2}>
                {t("Шаблон")}
              </Form.Label>
              <Col md={10}>
                <Form.Select
                  value={formik.values.template_id}
                  onChange={(value) =>
                    formik.setFieldValue("template_id", value.target.value)
                  }
                >
                  {items.map((item) => (
                    <option value={item.id}>{item.comment}</option>
                  ))}
                </Form.Select>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2">
              <Form.Label column md={2}>
                {t("Язык")}
              </Form.Label>
              <Col md={10}>
                <Form.Select
                  value={formik.values.lang}
                  onChange={(value) =>
                    formik.setFieldValue("lang", value.target.value)
                  }
                >
                  {LANG.map((item) => (
                    <option value={item.url}>{item.label}</option>
                  ))}
                </Form.Select>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2">
              <Form.Label column md={2}>
                {t("Email")}
              </Form.Label>
              <Col md={10}>
                <Form.Control
                  type={"text"}
                  onChange={(value) =>
                    formik.setFieldValue("email", value.target.value)
                  }
                  value={formik.values.email}
                />
              </Col>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={() => formik.handleSubmit()}>
            {t("Отправить")}
          </Button>
          <Button variant="secondary" onClick={() => toggleModal(false)}>
            {t("Закрыть")}
          </Button>
        </Modal.Footer>
      </Modal>
      <PageTitle title={"Шаблоны уведомлений"} />
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <CustomTable
                data={items}
                columns={[
                  { key: "id", label: "ID" },
                  { key: "subject", label: "Тема" },
                  { key: "comment", label: "Комментарий" },
                ]}
                search={true}
                sort={true}
                pagination={{
                  limit: 10,
                }}
                actions={[
                  {
                    slug: `${url}/form`,
                    icon: "fe-plus",
                    name: "Создать",
                  },
                  {
                    slug: `${url}/form`,
                    icon: "fe-zap",
                    name: "Тестовый мейл",
                  },
                ]}
                onClick={(id) => edit(id)}
                onDelete={(id) => remove(id)}
              />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
