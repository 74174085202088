import { gql } from "@apollo/client";

export const GET_POINTS = gql`
  query ($lang: String!) {
    point {
      id
      nameString(lang: $lang)
      type
    }
  }
`;

export const GET_POINT = gql`
  query ($id: Int!) {
    point(id: $id) {
      id
      name {
        lang
        value
      }
      address {
        lang
        value
      }
      lat
      lng
      type
      city_id
      area_id
    }
  }
`;
