import React from "react";
import { useTranslation } from "react-i18next";

export const Button = ({
  style,
  label,
  type = "submit",
  loading = false,
  onClick = null,
}) => {
  const { t } = useTranslation();

  return (
    <button
      className={"btn " + style}
      type={type}
      disabled={loading}
      onClick={onClick ? () => onClick() : null}
    >
      {!loading ? (
        t(label)
      ) : (
        <>
          <span
            className="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>{" "}
          {t("Подождите")}...
        </>
      )}
    </button>
  );
};
