import { gql } from "@apollo/client";

export const MODIFY_AGENT_REQUEST = gql`
  mutation ($id: Int!, $comment: String, $status: Int!) {
    modifyAgentRequest(id: $id, comment: $comment, status: $status)
  }
`;

export const DELETE_AGENT_REQUEST = gql`
  mutation ($id: Int!, $lang: String!) {
    deleteAgentRequest(id: $id) {
      agentRequest {
        id
        user {
          id
          email
          phone
        }
        status
        created_at
      }
      list(method: "AgentRequest::listStatus", lang: $lang) {
        id
        name
      }
    }
  }
`;
