export const en = {
  translation: {
    "Ввведите email и пароль для входа в систему":
      "Ввведите email и пароль для входа в систему",
    Email: "Email",
    "Введите свой email": "Введите свой email",
    Пароль: "Пароль",
    "Введите свой пароль": "Введите свой пароль",
    Ввойти: "Ввойти",
    Укажите: "Укажите",
    "Не верный email": "Не верный email",
    Подождите: "Подождите",
    Создать: "Создать",
    Главная: "Home",
    "": "",
  },
};
