import React, { useState } from "react";
import { Wrapper } from "../../components/Wrapper";
import { PageTitle } from "../../components/PageTitle";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Notification } from "../../utils/Notification";
import { useTranslation } from "react-i18next";
import { Button } from "../../components/Button";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Preload } from "../../components/Preload";
import { Selected } from "../../components/Form/Selected";
import { useMutation, useQuery } from "@apollo/client";
import i18next from "i18next";
import { GET_PROMO_CODE } from "../../graphql/Queries/PromoCodeQuery";
import { Datepicker } from "../../components/Form/Datepicker";
import { TextInput } from "../../components/Form/TextInput";
import { TYPE_PROMOCODE, USER_TYPE } from "../../utils/Dictionaries";
import moment from "moment";
import { MODIFY_PROMOCODE } from "../../graphql/Mutations/PromoCodeMutation";

export const PromoCodeForm = ({ url }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  let location = useLocation();
  const id = location.state ? location.state.id : null;
  let [searchParams, setSearchParams] = useSearchParams();
  const [modify] = useMutation(MODIFY_PROMOCODE);
  const { data } = useQuery(GET_PROMO_CODE, {
    variables: {
      id: Number(searchParams.get("id")),
      lang: i18next.language,
    },
  });
  const formik = useFormik({
    initialValues: {
      id: 0,
      start_date: moment().format("DD.MM.YYYY"),
      end_date: moment().format("DD.MM.YYYY"),
      user_type: 1,
      price: "",
      type: 1,
      amount: 1,
      name: "",
    },
    validationSchema: Yup.object({
      price: Yup.string().required(t("Объязательное поле")),
    }),
    onSubmit: (values) => {
      setLoading(true);
      modify({
        variables: {
          id: Number(values.id),
          start_date: values.start_date,
          end_date: values.end_date,
          user_type: Number(values.user_type),
          price: Number(values.price),
          type: Number(values.type),
          amount: Number(values.amount),
          name: values.name,
        },
      })
        .then((response) => {
          navigate(url);
          Notification(t("Все хорошо!"), "success");
        })
        .catch((error) => {
          Notification(error);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  if (!data) {
    return <Preload />;
  }

  return (
    <Wrapper>
      <PageTitle
        title={"Добавить промокод"}
        breadcrumbs={[
          {
            slug: url,
            name: "Промокоды",
          },
        ]}
      />
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <form className="row" onSubmit={formik.handleSubmit}>
                <div className="col-md-4">
                  <TextInput
                    label={"Название"}
                    value={formik.values.name}
                    onChange={(event) => {
                      formik.setFieldValue(`name`, event.target.value);
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.errors.name}
                    touched={formik.touched.name}
                    name={"name"}
                  />
                </div>
                <div className="col-md-4">
                  <Datepicker
                    label={"Действует с"}
                    value={formik.values.start_date}
                    onChange={(value) => {
                      formik.setFieldValue(`start_date`, value);
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.errors.start_date}
                    touched={formik.touched.start_date}
                    name={"start_date"}
                  />
                </div>
                <div className="col-md-4">
                  <Datepicker
                    label={"Действует до"}
                    value={formik.values.end_date}
                    onChange={(value) => {
                      formik.setFieldValue(`end_date`, value);
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.errors.end_date}
                    touched={formik.touched.end_date}
                    name={"end_date"}
                  />
                </div>
                <div className="col-md-4">
                  <TextInput
                    label={"Стоимость, AZN"}
                    value={formik.values.price}
                    onChange={(event) => {
                      formik.setFieldValue(`price`, event.target.value);
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.errors.price}
                    touched={formik.touched.price}
                    name={"price"}
                  />
                </div>
                <div className="col-md-4">
                  <Selected
                    label={"Тип пользователя"}
                    onChange={(value) => {
                      formik.setFieldValue(`user_type`, value);
                    }}
                    error={formik.errors.user_type}
                    name={"user_type"}
                    staticOptions={USER_TYPE}
                    value={formik.values.user_type}
                    style={{ paddingRight: 0 }}
                  />
                </div>
                <div className="col-md-4">
                  <Selected
                    label={"Тип промокода"}
                    onChange={(value) => {
                      formik.setFieldValue(`type`, value);
                    }}
                    error={formik.errors.type}
                    name={"type"}
                    staticOptions={TYPE_PROMOCODE}
                    value={formik.values.type}
                    style={{ paddingRight: 0 }}
                  />
                </div>
                {formik.values.type === 2 && (
                  <div className="col-md-4">
                    <TextInput
                      label={"Количество"}
                      value={formik.values.amount}
                      onChange={(event) => {
                        formik.setFieldValue(`amount`, event.target.value);
                      }}
                      onBlur={formik.handleBlur}
                      error={formik.errors.amount}
                      touched={formik.touched.amount}
                      name={"amount"}
                    />
                  </div>
                )}
                <div className="col-md-12 text-center">
                  <Button
                    label={"Сохранить"}
                    loading={loading}
                    style={"btn btn-primary w-md"}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
