import { Button } from "../Button";
import { MultilangTextInput } from "../Form/MultilangTextInput";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { MODIFY_CATEGORY_NEWS } from "../../graphql/Mutations/NewsMutation";

export const CategoryNewsFormComponent = ({
  onSuccess,
  onError,
  url,
  data,
}) => {
  const { t } = useTranslation();
  const [modify] = useMutation(MODIFY_CATEGORY_NEWS);
  const formik = useFormik({
    initialValues: {
      id: 0,
      name: {
        ru: "",
        az: "",
        en: "",
      },
    },
    validationSchema: Yup.object({
      name: Yup.object().shape({
        ru: Yup.string().required(t("Объязательное поле")),
      }),
    }),
    onSubmit: (values) => {
      setLoading(true);
      modify({
        variables: {
          id: Number(values.id),
          name: JSON.stringify(values.name),
          country_id: Number(values.country_id),
        },
      })
        .then((response) => {
          onSuccess(response);
        })
        .catch((error) => {
          onError(error);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (data && data.categoryNews && data.categoryNews[0]) {
      data.categoryNews[0].name.map((dic) => {
        formik.setFieldValue(`name.${dic.lang}`, dic.value);
      });
      formik.setFieldValue("id", data.categoryNews[0].id);
    }
  }, [data]);

  return (
    <form className="row" onSubmit={formik.handleSubmit}>
      <div className="col-md-12">
        <MultilangTextInput
          label={"Название"}
          value={formik.values.name}
          onChange={(text, lang) => {
            formik.setFieldValue(`name.${lang}`, text);
          }}
          onBlur={formik.handleBlur}
          error={formik.errors.name}
          name={"name"}
        />
      </div>
      <div className="col-md-12 text-center">
        <Button
          label={"Сохранить"}
          loading={loading}
          style={"btn btn-primary w-md"}
        />
      </div>
    </form>
  );
};
