import i18next from "i18next";
import Nestable from "react-nestable";
import { Property } from "./Property";
import { CHANGE_SORT_PROPS } from "../../graphql/Mutations/ObjectTypeMutation";
import { useMutation } from "@apollo/client";
import { useState } from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";

export const PropertyGroup = ({ item, collapseIcon, handler }) => {
  const [changeSortProps] = useMutation(CHANGE_SORT_PROPS);
  const name = item.name.find((i) => {
    return i.lang === i18next.language;
  });
  const [items, setItems] = useState(item.properties);

  const SortableItem = SortableElement(({ item }) => {
    return <Property item={item} />;
  });

  const SortableList = SortableContainer(({ items }) => {
    return (
      <di>
        {items.map((item, index) => {
          return (
            <SortableItem key={`item-${index}`} index={index} item={item} />
          );
        })}
      </di>
    );
  });

  return (
    <div className="dd-list" style={{ marginBottom: 10 }}>
      <div className="dd-item">
        <div className="dd-handle" style={{ background: "#e0e3e4" }}>
          <div>
            {handler}
            {collapseIcon}
            <span>{name.value}</span>
          </div>
          <SortableList
            items={items}
            onSortEnd={({ oldIndex, newIndex, collection, isKeySorting }) => {
              const _items = [...items];
              const el = _items[oldIndex];
              _items.splice(oldIndex, 1);
              _items.splice(newIndex, 0, el);
              setItems(_items);
              const arr = [];
              _items.map((item, index) => {
                arr.push({ id: item.id, sort: index + 1 });
              });
              changeSortProps({
                variables: {
                  arr: JSON.stringify(arr),
                },
              })
                .then((response) => {
                  console.log("[Log]", response.data);
                })
                .catch((error) => {
                  console.log("[Err]", error);
                });
            }}
          />
        </div>
      </div>
    </div>
  );
};
