import { gql } from "@apollo/client";

export const MODIFY_FAQ_TAB = gql`
  mutation ($id: Int!, $name: String!, $page: Int!) {
    modifyFaqTab(id: $id, name: $name, page: $page)
  }
`;

export const MODIFY_FAQ = gql`
  mutation ($id: Int!, $title: String!, $text: String!, $tab_id: Int!) {
    modifyFaq(id: $id, title: $title, tab_id: $tab_id, text: $text)
  }
`;

export const DELETE_FAQ_TAB = gql`
  mutation ($id: Int!) {
    deleteFaqTab(id: $id) {
      id
      name {
        lang
        value
      }
      page
    }
  }
`;

export const DELETE_FAQ = gql`
  mutation ($id: Int!) {
    deleteFaq(id: $id) {
      id
      title {
        lang
        value
      }
      tab {
        name {
          lang
          value
        }
      }
    }
  }
`;
