import React, { useEffect, useState } from "react";
import { Wrapper } from "../components/Wrapper";
import { PageTitle } from "../components/PageTitle";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { Preload } from "../components/Preload";
import { useFormik } from "formik";
import { GET_SETTING } from "../graphql/Queries/Setting";
import { TextInput } from "../components/Form/TextInput";
import { Button } from "../components/Button";
import { UPDATE_SETTING } from "../graphql/Mutations/SettingMutation";
import { Notification } from "../utils/Notification";
import { Selected } from "../components/Form/Selected";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export const Settings = () => {
  const { t } = useTranslation();
  const [items, setItems] = useState([]);
  const navigator = useNavigate();
  const { loading, error, data } = useQuery(GET_SETTING, {
    fetchPolicy: "no-cache",
  });
  const [modify] = useMutation(UPDATE_SETTING);
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      settings: [],
    },
    onSubmit: (values) => {
      modify({
        variables: {
          settings: JSON.stringify(values.settings),
        },
      })
        .then((resp) => {
          toast.success(t("Настройки сохранены"));
        })
        .catch((error) => {
          Notification(error);
        });
    },
  });

  useEffect(() => {
    if (data) {
      formik.setFieldValue("settings", data.setting);
    }
  }, [data]);

  if (!data) {
    return <Preload />;
  }

  return (
    <Wrapper>
      <PageTitle title={"Основные настройки"} />
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                {formik.values.settings.map((setting) => {
                  return (
                    <div className="col-md-6">
                      {setting.type === "input" && (
                        <TextInput
                          label={setting.name}
                          value={setting.value}
                          onChange={(event) => {
                            const _settings = formik.values.settings.map(
                              (set) => {
                                if (Number(set.id) === Number(setting.id)) {
                                  set.value = event.target.value;
                                }
                                return set;
                              },
                            );
                            formik.setFieldValue(`settings`, _settings);
                          }}
                          onBlur={formik.handleBlur}
                          error={formik.errors.name}
                          name={"name"}
                        />
                      )}
                      {setting.type === "checkbox" && (
                        <Selected
                          label={setting.name}
                          onChange={(value) => {
                            const _settings = formik.values.settings.map(
                              (set) => {
                                if (Number(set.id) === Number(setting.id)) {
                                  set.value = value;
                                }
                                return set;
                              },
                            );
                            formik.setFieldValue(`settings`, _settings);
                          }}
                          name={"country_id"}
                          rows={{ value: "id", label: "name" }}
                          staticOptions={[
                            { id: 1, name: "Включены" },
                            { id: 2, name: "Отключены" },
                          ]}
                          value={Number(setting.value)}
                        />
                      )}
                    </div>
                  );
                })}
                <div className="col-md-12 text-center">
                  <Button
                    label={"Сохранить"}
                    loading={loading}
                    style={"btn btn-primary w-md"}
                    onClick={() => formik.submitForm()}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
