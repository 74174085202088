import React, { useEffect, useState } from "react";
import { Wrapper } from "../../components/Wrapper";
import { PageTitle } from "../../components/PageTitle";
import { useFormik } from "formik";
import { Notification } from "../../utils/Notification";
import { useTranslation } from "react-i18next";
import { Button } from "../../components/Button";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Preload } from "../../components/Preload";
import { useMutation, useQuery } from "@apollo/client";
import i18next from "i18next";
import { GET_COMPLAINT } from "../../graphql/Queries/ComplaintQuery";
import { TextInput } from "../../components/Form/TextInput";
import { MODIFY_COMPLAINT } from "../../graphql/Mutations/ComplaintMutation";
import { useDispatch } from "react-redux";
import { addComplaint } from "../../redux/slices/badgeSlice";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { finder, USER_TYPE } from "../../utils/Dictionaries";

export const ComplaintForm = ({ url }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  let location = useLocation();
  const id = location.state ? location.state.id : null;
  let [searchParams, setSearchParams] = useSearchParams();
  const [modify] = useMutation(MODIFY_COMPLAINT);
  const dispatch = useDispatch();
  const { data } = useQuery(GET_COMPLAINT, {
    variables: {
      id: Number(searchParams.get("id")),
      lang: i18next.language,
    },
    fetchPolicy: "no-cache",
  });
  const formik = useFormik({
    initialValues: {
      id: 0,
      estate: null,
      text: "",
      comment: "",
      tree: [],
      email: "",
      user: {},
    },
  });
  const [showModal, setShowModal] = useState(false);

  const apply = (status) => {
    setLoading(true);
    modify({
      variables: {
        id: Number(formik.values.id),
        status: status,
        comment: formik.values.comment,
      },
    })
      .then((response) => {
        dispatch(addComplaint(response.data.modifyComplaint));
        Notification(t("Все хорошо!"), "success");
        setTimeout(() => {
          navigate(url);
        }, 1000);
      })
      .catch((error) => {
        Notification(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (data && data.complaint[0]) {
      formik.setFieldValue("estate", data.complaint[0].estate);
      formik.setFieldValue("text", data.complaint[0].text);
      formik.setFieldValue("tree", JSON.parse(data.complaint[0].tree));
      formik.setFieldValue("email", data.complaint[0].email);
      formik.setFieldValue("comment", data.complaint[0].comment);
      formik.setFieldValue("id", data.complaint[0].id);
      formik.setFieldValue("user", data.complaint[0].user);
    }
  }, [data]);

  if (!data) {
    return <Preload />;
  }

  return (
    <Wrapper>
      <PageTitle
        title={"Просмотр жалобы"}
        breadcrumbs={[
          {
            slug: url,
            name: "Жалобы",
          },
        ]}
      />
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <form className="row" onSubmit={formik.handleSubmit}>
                <div className="col-md-12">
                  <h4 className={"header-title"}>{t("Объявление")}</h4>
                  <div className={"mb-3"}>
                    <a
                      target={"_blank"}
                      href={
                        "https://city.az/" +
                        i18next.language +
                        "/item/" +
                        formik.values.estate?.id
                      }
                    >
                      {formik.values.estate?.title}
                    </a>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-floating mb-3">
                    <h4 className={"header-title"}>
                      {t("Комментарий жалующегося")}
                    </h4>
                    <div className="mb-3">{formik.values.text}</div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-floating mb-3">
                    <h4 className={"header-title"}>{t("Жалующийся")}</h4>
                    <div className="mb-3">
                      <a
                        href={"javascript:void(0);"}
                        onClick={() => setShowModal(true)}
                      >
                        {t("подробнее")}
                      </a>{" "}
                    </div>
                    <Modal show={showModal}>
                      <Modal.Header
                        closeButton
                        onClick={() => setShowModal(false)}
                      >
                        <Modal.Title>
                          {t("Информация о пользователе")}
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Form.Group as={Row} className="mb-2">
                          <Form.Label column md={2}>
                            {t("Email")}
                          </Form.Label>
                          <Col md={10} className={"mt-1"}>
                            {formik.values.user
                              ? formik.values.user?.email
                              : formik.values.email}
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-2">
                          <Form.Label column md={2}>
                            {t("Имя")}
                          </Form.Label>
                          <Col md={10} className={"mt-1"}>
                            {formik.values.user?.name}
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-2">
                          <Form.Label column md={2}>
                            {t("Телефон")}
                          </Form.Label>
                          <Col md={10} className={"mt-1"}>
                            {formik.values.user?.phone}
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-2">
                          <Form.Label column md={2}>
                            {t("Тип")}
                          </Form.Label>
                          <Col md={10} className={"mt-1"}>
                            {formik.values.user?.type
                              ? finder(USER_TYPE, formik.values.user?.type)
                              : ""}
                          </Col>
                        </Form.Group>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button
                          variant="secondary"
                          onClick={() => setShowModal(false)}
                        >
                          {t("Закрыть")}
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-floating mb-3">
                    <h4 className={"header-title"}>{t("Жалоба")}</h4>
                    {formik.values.tree.map((item, i) => {
                      return <div>{item}</div>;
                    })}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-floating mb-3">
                    <TextInput
                      name={"comment"}
                      onChange={(event) => {
                        formik.setFieldValue("comment", event.target.value);
                      }}
                      touched={formik.touched.comment}
                      error={formik.errors.comment}
                      onBlur={formik.handleBlur}
                      label={"Комментарий для пользователя"}
                    />
                  </div>
                </div>
                {Number(data?.complaint[0].status) === 1 && (
                  <div className="col-md-12 text-center">
                    <Button
                      type={"button"}
                      label={"Отклонить"}
                      loading={loading}
                      style={"btn btn-primary w-md"}
                      onClick={() => apply(2)}
                    />
                    &nbsp;
                    <Button
                      type={"button"}
                      label={"Заблокировать"}
                      loading={loading}
                      style={"btn btn-danger w-md"}
                      onClick={() => apply(3)}
                    />
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
