import React from "react";
import { useTranslation } from "react-i18next";
import { Tab, Tabs } from "react-bootstrap";
import { FileInputTab } from "./FileInput/FileInputTab";
import { LANG } from "../../utils/Dictionaries";

export const FileInput = ({
  label,
  value,
  onChange,
  onDelete,
  onSort,
  multilang = true,
}) => {
  const { t } = useTranslation();

  return multilang ? (
    <Tabs defaultActiveKey={LANG[0].url} className={"multilang-tab"}>
      {LANG.map((lang) => {
        return (
          <Tab eventKey={lang.url} key={lang.url} title={lang.label}>
            <FileInputTab
              lang={lang.url}
              value={value[lang.url]}
              onChange={(file, lang) => onChange(file, lang)}
              label={t(label)}
              key={lang.url}
              onSort={(images) => onSort(images)}
            />
          </Tab>
        );
      })}
    </Tabs>
  ) : (
    <FileInputTab
      lang={false}
      value={value}
      onChange={(file, lang) => {
        onChange(file, lang);
      }}
      onDelete={(file) => {
        onDelete(file);
      }}
      onSort={(images) => onSort(images)}
      label={t(label)}
    />
  );
};
