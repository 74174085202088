import { gql } from "@apollo/client";

export const MODIFY_METRO = gql`
  mutation (
    $id: Int!
    $name: String!
    $metro_line_id: Int!
    $lat: String!
    $lng: String!
    $icon: String
  ) {
    modifyMetro(
      id: $id
      name: $name
      metro_line_id: $metro_line_id
      lat: $lat
      lng: $lng
      icon: $icon
    )
  }
`;

export const DELETE_METRO = gql`
  mutation ($id: Int!, $lang: String!) {
    deleteMetro(id: $id) {
      id
      name {
        lang
        value
      }
      metro_line {
        nameString(lang: $lang)
      }
    }
  }
`;
