import { gql } from "@apollo/client";

export const GET_COMPLEX_PROPERTY = gql`
  query ($lang: String!) {
    complexProperty {
      id
      property {
        id
      }
      sort
      type
    }
    property {
      id
      nameString(lang: $lang)
    }
    list(method: "Complex::listType", lang: $lang) {
      id
      name
    }
  }
`;

export const GET_COMPLEX = gql`
  query ($id: Int!, $lang: String!) {
    complex(id: $id) {
      id
      name {
        lang
        value
      }
      text {
        lang
        value
      }
      city {
        id
        country {
          id
        }
      }
      area {
        id
      }
      place {
        id
      }
      address
      properties
      image
      lat
      lng
      type
      slug
    }
    list(method: "Complex::listType", lang: $lang) {
      id
      name
    }
    countries {
      id
      nameString(lang: $lang)
    }
  }
`;

export const GET_COMPLEXES = gql`
  query {
    complex {
      id
      name {
        lang
        value
      }
      address
    }
  }
`;

export const GET_COMPLEX_PROPS = gql`
  query ($lang: String!, $type: Int!) {
    complexProperty(type: $type) {
      id
      property {
        id
        type
        nameString(lang: $lang)
        dictionary {
          id
          options {
            id
            valueString(lang: $lang)
          }
        }
        children {
          id
          type
          nameString(lang: $lang)
          dictionary {
            id
            options {
              id
              valueString(lang: $lang)
            }
          }
        }
      }
      sort
    }
  }
`;
