import React, { useEffect, useState } from "react";
import { Wrapper } from "../../components/Wrapper";
import { PageTitle } from "../../components/PageTitle";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Notification } from "../../utils/Notification";
import { useTranslation } from "react-i18next";
import { Button } from "../../components/Button";
import { MultilangTextInput } from "../../components/Form/MultilangTextInput";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Preload } from "../../components/Preload";
import { Selected } from "../../components/Form/Selected";
import { useMutation, useQuery } from "@apollo/client";
import i18next from "i18next";
import { MODIFY_METRO } from "../../graphql/Mutations/MetroMutation";
import { GET_ONE_METRO } from "../../graphql/Queries/MetroQuery";
import { TextInput } from "../../components/Form/TextInput";
import { FileInput } from "../../components/Form/FileInput";

export const FormMetro = ({ url }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  let location = useLocation();
  const id = location.state ? location.state.id : null;
  let [searchParams, setSearchParams] = useSearchParams();
  const [modify] = useMutation(MODIFY_METRO);
  const { data } = useQuery(GET_ONE_METRO, {
    fetchPolicy: "no-cache",
    variables: {
      id: Number(searchParams.get("id")),
      lang: i18next.language,
    },
  });
  const formik = useFormik({
    initialValues: {
      id: 0,
      name: {
        ru: "",
        az: "",
        en: "",
      },
      metro_line_id: null,
      lat: "",
      lng: "",
      icon: "",
    },
    validationSchema: Yup.object({
      name: Yup.object().shape({
        ru: Yup.string().required(t("Объязательное поле")),
      }),
      metro_line_id: Yup.number().required(t("Укажите ветку")),
      lat: Yup.string().required(t("Объязательное поле")),
      lng: Yup.string().required(t("Объязательное поле")),
    }),
    onSubmit: (values) => {
      setLoading(true);
      modify({
        variables: {
          id: Number(values.id),
          name: JSON.stringify(values.name),
          metro_line_id: Number(values.metro_line_id),
          lat: values.lat,
          lng: values.lng,
          icon: values.icon,
        },
      })
        .then((response) => {
          navigate(url);
          Notification(t("Все хорошо!"), "success");
        })
        .catch((error) => {
          Notification(error);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  useEffect(() => {
    if (data && data.metro[0]) {
      data.metro[0].name.map((dic) => {
        formik.setFieldValue(`name.${dic.lang}`, dic.value);
      });
      formik.setFieldValue("id", data.metro[0].id);
      formik.setFieldValue("metro_line_id", data.metro[0].metro_line.id);
      formik.setFieldValue("lat", data.metro[0].lat);
      formik.setFieldValue("lng", data.metro[0].lng);
      formik.setFieldValue("icon", data.metro[0].icon);
    }
  }, [data]);

  if (!data) {
    return <Preload />;
  }

  return (
    <Wrapper>
      <PageTitle
        title={id === null ? "Добавить метро" : "Редактировать метро"}
        breadcrumbs={[
          {
            slug: url,
            name: "Метро",
          },
        ]}
      />
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <form className="row" onSubmit={formik.handleSubmit}>
                <div className="col-md-6">
                  <MultilangTextInput
                    label={"Название"}
                    value={formik.values.name}
                    onChange={(text, lang) => {
                      formik.setFieldValue(`name.${lang}`, text);
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.errors.name}
                    name={"name"}
                  />
                </div>
                <div className="col-md-6">
                  <Selected
                    label={"Ветка"}
                    style={{ marginTop: "38px" }}
                    onChange={(value) => {
                      formik.setFieldValue(`metro_line_id`, value);
                    }}
                    error={formik.errors.metro_line_id}
                    name={"metro_line_id"}
                    rows={{ value: "id", label: "nameString" }}
                    staticOptions={data?.metroLine}
                    value={formik.values.metro_line_id}
                  />
                </div>
                <div className="col-md-6">
                  <TextInput
                    label={"Lat"}
                    value={formik.values.lat}
                    onChange={(text) => {
                      formik.setFieldValue(`lat`, text.target.value);
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.errors.lat}
                    error={formik.errors.lat}
                    name={"lat"}
                  />
                </div>
                <div className="col-md-6">
                  <TextInput
                    label={"Lng"}
                    value={formik.values.lng}
                    onChange={(text) => {
                      formik.setFieldValue(`lng`, text.target.value);
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.errors.lng}
                    name={"lng"}
                  />
                </div>
                <div className="col-md-12">
                  <FileInput
                    label={"Иконка"}
                    onChange={(file) => {
                      formik.setFieldValue(`icon`, file);
                    }}
                    value={formik.values.icon}
                    multilang={false}
                  />
                </div>
                <div className="col-md-12 text-center">
                  <Button
                    label={"Сохранить"}
                    loading={loading}
                    style={"btn btn-primary w-md"}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
