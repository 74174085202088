import React from "react";

export const Paginator = ({ amountPages, currentPage, setCurrentPage }) => {
  if (amountPages <= 10) {
    return (
      <ul className={"pagination"}>
        <li className={"disabled"}>
          <i className="mdi mdi-arrow-left"></i>
        </li>
        {[...Array(amountPages)].map((item, index) => {
          return (
            <li
              key={index}
              className={currentPage === index + 1 ? "active" : ""}
              onClick={() => setCurrentPage(index + 1)}
            >
              {index + 1}
            </li>
          );
        })}
        <li>
          <i className="mdi mdi-arrow-right"></i>
        </li>
      </ul>
    );
  } else {
    let lastPages = [];
    let firstPage = [];
    for (let i = amountPages - 4; i <= amountPages; i++) {
      lastPages.push(i);
    }
    let start = 1;
    let end = 5;
    if (currentPage > 2 && currentPage < amountPages - 5) {
      start = currentPage - 2;
      end = currentPage + 2;
    }
    if (currentPage >= amountPages - 7) {
      start = amountPages - 10;
      end = amountPages;
      lastPages = [];
    }
    for (let i = start; i <= end; i++) {
      firstPage.push(i);
    }
    return (
      <ul className={"pagination"} style={{ userSelect: "none" }}>
        <li
          onClick={() =>
            currentPage > 1
              ? setCurrentPage(currentPage - 1)
              : setCurrentPage(1)
          }
        >
          <i className="mdi mdi-arrow-left"></i>
        </li>
        {firstPage.map((item, index) => {
          return (
            <li
              key={item}
              className={currentPage === item ? "active" : ""}
              onClick={() => setCurrentPage(item)}
            >
              {item}
            </li>
          );
        })}
        {currentPage < amountPages - 7 && (
          <li className={"disabled"}>
            <i className="mdi mdi-dots-horizontal"></i>
          </li>
        )}
        {lastPages.map((item, index) => {
          return (
            <li
              key={item}
              className={currentPage === item ? "active" : ""}
              onClick={() => setCurrentPage(item)}
            >
              {item}
            </li>
          );
        })}
        <li
          onClick={() =>
            currentPage < amountPages
              ? setCurrentPage(currentPage + 1)
              : setCurrentPage(amountPages)
          }
        >
          <i className="mdi mdi-arrow-right"></i>
        </li>
      </ul>
    );
  }
};
