import { gql } from "@apollo/client";

export const EDIT_SEO = gql`
  mutation (
    $row_id: Int!
    $table: String!
    $title: String
    $keywords: String
    $description: String
    $image: String
    $follow: Int
    $index: Int
    $h1: String
  ) {
    modifySeo(
      row_id: $row_id
      table: $table
      title: $title
      keywords: $keywords
      description: $description
      image: $image
      follow: $follow
      index: $index
      h1: $h1
    )
  }
`;
