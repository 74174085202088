import { gql } from "@apollo/client";

export const GET_LANG = gql`
  query {
    lang {
      key
      ru
      az
      en
    }
  }
`;
