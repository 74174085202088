import { gql } from "@apollo/client";

export const MODIFY_NEWS = gql`
  mutation (
    $id: Int!
    $title: String!
    $text: String!
    $slug: String!
    $categories: String!
    $tags: String
    $image: String
    $public_datetime: String
    $description: String
  ) {
    modifyNews(
      id: $id
      title: $title
      text: $text
      slug: $slug
      categories: $categories
      tags: $tags
      image: $image
      public_datetime: $public_datetime
      description: $description
    )
  }
`;

export const DELETE_NEWS = gql`
  mutation ($id: Int!) {
    deleteNews(id: $id) {
      id
      title {
        lang
        value
      }
    }
  }
`;

export const MODIFY_CATEGORY_NEWS = gql`
  mutation ($id: Int!, $name: String!) {
    modifyCategoryNews(id: $id, name: $name)
  }
`;

export const DELETE_CATEGORY_NEWS = gql`
  mutation ($id: Int!) {
    deleteCategoryNews(id: $id) {
      id
      name {
        lang
        value
      }
    }
  }
`;

export const MODIFY_TAG_NEWS = gql`
  mutation ($id: Int!, $name: String!) {
    modifyTagsNews(id: $id, name: $name)
  }
`;

export const DELETE_TAG_NEWS = gql`
  mutation ($id: Int!) {
    deleteTagsNews(id: $id) {
      id
      name {
        lang
        value
      }
    }
  }
`;
