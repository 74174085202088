import React, { useEffect, useState } from "react";
import { Wrapper } from "../components/Wrapper";
import { PageTitle } from "../components/PageTitle";
import { CustomTable } from "../components/CustomTable";
import { useNavigate } from "react-router-dom";
import { Notification } from "../utils/Notification";
import { useMutation, useQuery } from "@apollo/client";
import { GET_AREAS } from "../graphql/Queries/AreaQuery";
import { DELETE_AREA } from "../graphql/Mutations/AreaMutation";
import i18next from "i18next";
import { GET_POINTS } from "../graphql/Queries/PointQuery";
import { finder, POINT_TYPE } from "../utils/Dictionaries";
import { DELETE_POINT } from "../graphql/Mutations/PointMutation";

export const Points = ({ url }) => {
  const [items, setItems] = useState([]);
  const navigator = useNavigate();

  const { data } = useQuery(GET_POINTS, {
    fetchPolicy: "no-cache",
    variables: {
      lang: i18next.language,
    },
  });
  const [drop] = useMutation(DELETE_POINT);

  useEffect(() => {
    if (data) {
      setItems(data.point);
    }
  }, [data]);

  const remove = (id) => {
    drop({
      variables: {
        id: Number(id),
        lang: i18next.language,
      },
    })
      .then((response) => {
        setItems(response.data.deletePoint);
      })
      .catch((error) => {
        Notification(error);
      });
  };

  const edit = (id) => {
    navigator(`${url}/form?id=${id}`);
  };

  return (
    <Wrapper>
      <PageTitle title={"Ориентиры"} />
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <CustomTable
                data={items}
                columns={[
                  { key: "id", label: "ID" },
                  { key: "nameString", label: "Название" },
                  {
                    key: "type",
                    label: "Тип",
                    callback: (row) => {
                      return finder(POINT_TYPE, row);
                    },
                    list: POINT_TYPE,
                    listProp: {
                      value: "id",
                      label: "name",
                    },
                  },
                ]}
                search={true}
                sort={true}
                pagination={{
                  limit: 10,
                }}
                actions={[
                  {
                    slug: `${url}/form`,
                    icon: "fe-plus",
                    name: "Создать",
                  },
                ]}
                onClick={(id) => edit(id)}
                onDelete={(id) => remove(id)}
              />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
