import { gql } from "@apollo/client";

export const MODIFY_PARSER = gql`
  mutation (
    $id: Int!
    $name: String!
    $link: String!
    $object_type_id: Int!
    $target: Int!
    $properties: String!
    $address: String
    $price: String
    $complex: String
    $title: String
    $text: String
    $city: String
    $container: String!
    $item: String!
    $item_link: String!
    $prefix_link: String
    $estate_type: Int!
    $enable: Int!
    $ad_send: Int!
  ) {
    modifyParser(
      id: $id
      name: $name
      link: $link
      object_type_id: $object_type_id
      target: $target
      properties: $properties
      address: $address
      complex: $complex
      price: $price
      title: $title
      text: $text
      city: $city
      container: $container
      item: $item
      item_link: $item_link
      prefix_link: $prefix_link
      estate_type: $estate_type
      enable: $enable
      ad_send: $ad_send
    )
  }
`;

export const DELETE_PARSER = gql`
  mutation ($id: Int!) {
    deleteParser(id: $id) {
      id
      name
      link
    }
  }
`;
