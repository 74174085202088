import { gql } from "@apollo/client";

export const SET_PROPS = gql`
  mutation ($properties: String!) {
    setPropsComplex(properties: $properties)
  }
`;

export const MODIFY_COMPLEX = gql`
  mutation (
    $id: Int!
    $name: String!
    $city_id: Int!
    $area_id: Int
    $place_id: Int
    $address: String!
    $lat: String
    $lng: String
    $image: String
    $properties: String
    $type: Int!
    $slug: String!
    $text: String
  ) {
    modifyComplex(
      id: $id
      name: $name
      city_id: $city_id
      area_id: $area_id
      place_id: $place_id
      address: $address
      lat: $lat
      lng: $lng
      image: $image
      properties: $properties
      type: $type
      slug: $slug
      text: $text
    )
  }
`;

export const DELETE_COMPLEX = gql`
  mutation ($id: Int!) {
    deleteComplex(id: $id) {
      id
      name {
        lang
        value
      }
      address
    }
  }
`;
