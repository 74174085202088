import React from "react";
import { useTranslation } from "react-i18next";

export const Checkbox = ({ name, label, value, onChange, error = false }) => {
  const { t } = useTranslation();
  return (
    <div className="form-check">
      <input
        className="form-check-input"
        type="checkbox"
        value={value}
        checked={value}
        id={name}
        onClick={() => onChange(!value)}
      />
      <label className="form-check-label" htmlFor={name}>
        {t(label)}
      </label>
    </div>
  );
};
