import { gql } from "@apollo/client";

export const GET_PROPERTIES = gql`
  query ($lang: String) {
    property {
      id
      name(lang: $lang) {
        lang
        value
      }
      property_group {
        id
        name(lang: $lang) {
          lang
          value
        }
      }
      type
      content_type
      is_require
      dictionary {
        id
        name {
          lang
          value
        }
      }
    }
    dictionaries {
      id
      nameString(lang: $lang)
    }
    property_group {
      id
      nameString(lang: $lang)
    }
  }
`;

export const GET_LIST_PROPERTIES = gql`
  query ($lang: String!, $id: Int!) {
    property(object_type_id: $id) {
      id
      nameString(lang: $lang)
      is_thumb(object_type_id: $id)
      sort_in_thumb(object_type_id: $id) {
        sort_in_thumb
      }
    }
    objectType(id: $id) {
      title
      title_from_search
      seo_title
      seo_desc
    }
  }
`;

export const GET_PROPERTY = gql`
  query ($id: Int!, $lang: String) {
    property(id: $id) {
      id
      name {
        lang
        value
      }
      property_group {
        id
        name {
          lang
          value
        }
      }
      type
      content_type
      is_require
      display
      dictionary {
        id
        name {
          lang
          value
        }
      }
      hint {
        lang
        value
      }
      hint_text {
        lang
        value
      }
      error_message(lang: $lang) {
        lang
        value
      }
      mask {
        lang
        value
      }
      show_in_filter
      is_require
      max_length
      parent_id
      label_icon
      size
      key
      children {
        id
        name {
          lang
          value
        }
      }
      raw_query
    }
    property_group {
      id
      name {
        lang
        value
      }
      name_system {
        lang
        value
      }
      step {
        id
        name {
          lang
          value
        }
      }
    }
    dictionaries {
      id
      name {
        lang
        value
      }
    }
    properties: property {
      id
      nameString(lang: $lang)
      type
    }
    propertyKeys
  }
`;
