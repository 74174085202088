import { gql } from "@apollo/client";

export const GET_CITIES = gql`
  query {
    cities {
      id
      name {
        lang
        value
      }
      country {
        name {
          lang
          value
        }
      }
    }
  }
`;

export const GET_CITIES_FILTER = gql`
  query ($country_id: Int, $lang: String) {
    cities(country_id: $country_id) {
      id
      nameString(lang: $lang)
    }
  }
`;

export const GET_CITY = gql`
  query ($id: Int!, $lang: String) {
    cities(id: $id) {
      id
      name {
        lang
        value
      }
      country {
        id
      }
      lat
      lng
      objectType {
        object_type_id
        id
      }
    }
    countries {
      id
      nameString(lang: $lang)
    }
    objectType {
      id
      nameString(lang: $lang)
    }
  }
`;
