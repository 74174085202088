import React, { useEffect, useState } from "react";
import { Wrapper } from "../../../components/Wrapper";
import { PageTitle } from "../../../components/PageTitle";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Notification } from "../../../utils/Notification";
import { useTranslation } from "react-i18next";
import { Button } from "../../../components/Button";
import { MultilangTextInput } from "../../../components/Form/MultilangTextInput";
import { useNavigate, useSearchParams } from "react-router-dom";
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { Preload } from "../../../components/Preload";
import { Selected } from "../../../components/Form/Selected";
import { ESTATE_TYPE, STATUS, TARGET } from "../../../utils/Dictionaries";
import { GET_FOOTER_MENU_LINK } from "../../../graphql/Queries/FooterMenuQuery";
import { MODIFY_FOOTER_MENU_LINK } from "../../../graphql/Mutations/FooterMenuMutation";
import { TextInput } from "../../../components/Form/TextInput";
import i18next from "i18next";
import { GET_AREAS_FILTER } from "../../../graphql/Queries/AreaQuery";
import { GET_PLACES_FILTER } from "../../../graphql/Queries/PlaceQuery";

export const LinkForm = ({ id = null }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const [modify] = useMutation(MODIFY_FOOTER_MENU_LINK);
  const { load, error, data } = useQuery(GET_FOOTER_MENU_LINK, {
    variables: {
      id: Number(searchParams.get("id")),
      lang: i18next.language,
    },
    fetchPolicy: "no-cache",
  });
  if (error) {
    toast.error(error.message);
  }
  const [objectTypes] = useLazyQuery(
    gql(`query($lang: String!, $target: Int!, $estate_type: Int!) {
    objectType(target: $target, estate_type: $estate_type) {
      id
      nameString(lang: $lang)
    }
  }`),
    {
      variables: {
        lang: i18next.language,
      },
    },
  );
  const [queryProps] = useLazyQuery(
    gql(`query($lang: String!, $id: Int!) {
    property(object_type_id: $id) {
      id
      nameString(lang: $lang)
      type
      dictionary {
        options {
          id
          valueString(lang: $lang)
        }
      }
    }
  }`),
    {
      variables: {
        lang: i18next.language,
      },
    },
  );
  const [areaQuery] = useLazyQuery(GET_AREAS_FILTER, {
    variables: {
      lang: i18next.language,
    },
  });
  const [placeQuery] = useLazyQuery(GET_PLACES_FILTER, {
    variables: {
      lang: i18next.language,
    },
  });
  const formik = useFormik({
    initialValues: {
      id: 0,
      title: {
        ru: "",
        az: "",
        en: "",
      },
      link: "",
      target: null,
      estate_type: null,
      object_type_id: null,
      props: {},
      status: 1,
      sort: "",
      city_id: "",
      area_id: "",
      place_id: "",
      point_id: "",
    },
    validationSchema: Yup.object({
      title: Yup.object().shape({
        ru: Yup.string().required(t("Объязательное поле")),
      }),
      link: Yup.string().required(t("Объязательное поле")),
    }),
    onSubmit: (values) => {
      setLoading(true);
      modify({
        variables: {
          id: Number(values.id),
          title: JSON.stringify(values.title),
          link: values.link,
          footer_menu_id: Number(searchParams.get("footer_menu_id")),
          target: Number(values.target),
          estate_type: Number(values.estate_type),
          object_type_id: Number(values.object_type_id),
          props: JSON.stringify(values.props),
          sort: values.sort !== "" ? Number(values.sort) : "",
          status: Number(values.status),
          city_id: Number(values.city_id),
          area_id: Number(values.area_id),
          place_id: Number(values.place_id),
          point_id: Number(values.point_id),
        },
      })
        .then((response) => {
          if (response.data.modifyFooterMenuLink) {
            navigate(
              "/footer-menu/links?footer_menu_id=" +
                searchParams.get("footer_menu_id"),
            );
            Notification(t("Все хорошо!"), "success");
          } else {
            toast.error(t("Ошибка сохранения"));
          }
        })
        .catch((error) => {
          Notification(error);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });
  const [listTypeObject, setListTypeObject] = useState([]);
  const [props, setProps] = useState([]);
  const [listCities, setListCities] = useState([]);
  const [listAreas, setListAreas] = useState([]);
  const [listPlace, setListPlace] = useState([]);
  const [listPoint, setListPoint] = useState([]);

  useEffect(() => {
    if (data && data.footerMenuLink.length > 0) {
      data.footerMenuLink[0].title.map((dic) => {
        formik.setFieldValue(`title.${dic.lang}`, dic.value);
      });
      formik.setFieldValue("id", data.footerMenuLink[0].id);
      formik.setFieldValue("link", data.footerMenuLink[0].link);
      formik.setFieldValue("link", data.footerMenuLink[0].link);
      formik.setFieldValue("target", data.footerMenuLink[0].target);
      formik.setFieldValue("estate_type", data.footerMenuLink[0].estate_type);
      formik.setFieldValue("sort", data.footerMenuLink[0].sort);
      formik.setFieldValue("status", data.footerMenuLink[0].status);
      formik.setFieldValue("city_id", String(data.footerMenuLink[0].city_id));
      formik.setFieldValue("area_id", String(data.footerMenuLink[0].area_id));
      formik.setFieldValue("place_id", String(data.footerMenuLink[0].place_id));
      formik.setFieldValue("point_id", String(data.footerMenuLink[0].point_id));
      formik.setFieldValue(
        "object_type_id",
        data.footerMenuLink[0].object_type_id,
      );
      const pr = {};
      data.footerMenuLink[0].props.map((prop) => {
        const vl = JSON.parse(prop.value);
        pr[prop.property_id] =
          vl.from === null && vl.to === null ? Number(vl.fix) : vl;
      });
      formik.setFieldValue("props", pr);
      objectTypes({
        variables: {
          lang: i18next.language,
          target: data.footerMenuLink[0].target,
          estate_type: data.footerMenuLink[0].estate_type,
        },
      }).then((resp) => {
        setListTypeObject(resp.data.objectType);
      });
      queryProps({
        variables: {
          lang: i18next.language,
          id: data.footerMenuLink[0].object_type_id,
        },
      }).then((resp) => {
        setProps(resp.data.property);
      });
      if (data.footerMenuLink[0].city_id !== "") {
        areaQuery({
          variables: {
            lang: i18next.language,
            city_id: data.footerMenuLink[0].city_id,
          },
        }).then((resp) => {
          setListAreas(resp.data.areas);
        });
      }
      if (data.footerMenuLink[0].area_id !== "") {
        placeQuery({
          variables: {
            lang: i18next.language,
            area_id: data.footerMenuLink[0].area_id,
          },
        }).then((resp) => {
          setListPlace(resp.data.places);
        });
      }
    }
    if (data) {
      setListCities(data.cities);
      setListPoint(data.point);
    }
  }, [data]);

  if (!data) {
    return <Preload />;
  }

  return (
    <Wrapper>
      <PageTitle
        title={id === null ? "Добавить блок" : "Редактировать блок"}
        breadcrumbs={[
          {
            slug: "/footer-menu",
            name: "Ссылки в футере",
          },
          {
            slug:
              "/footer-menu/links?footer_menu_id=" +
              searchParams.get("footer_menu_id"),
            name: "Ссылки для блока",
          },
        ]}
      />
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <form className="row" onSubmit={formik.handleSubmit}>
                <div className="col-md-6">
                  <MultilangTextInput
                    label={"Название"}
                    value={formik.values.title}
                    onChange={(text, lang) => {
                      formik.setFieldValue(`title.${lang}`, text);
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.errors.title}
                    touched={formik.touched.title}
                    name={"title"}
                  />
                </div>
                <div className="col-md-6 mt-4">
                  <TextInput
                    label={"Ссылка"}
                    value={formik.values.link}
                    onChange={(event) => {
                      formik.setFieldValue(`link`, event.target.value);
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.errors.link}
                    touched={formik.touched.link}
                    name={"link"}
                  />
                </div>
                <div className="col-md-4">
                  <Selected
                    label={"Цель"}
                    onChange={(value) => {
                      formik.setFieldValue(`target`, value);
                    }}
                    error={formik.errors.target}
                    touched={formik.touched.target}
                    name={"target"}
                    staticOptions={TARGET}
                    style={{ paddingRight: 0 }}
                    value={formik.values.target}
                  />
                </div>
                <div className="col-md-4">
                  <Selected
                    label={"Тип недвижиности"}
                    onChange={(value) => {
                      formik.setFieldValue(`estate_type`, value);
                      objectTypes({
                        variables: {
                          lang: i18next.language,
                          target: formik.values.target,
                          estate_type: value,
                        },
                      }).then((resp) => {
                        setListTypeObject(resp.data.objectType);
                      });
                    }}
                    error={formik.errors.estate_type}
                    touched={formik.touched.estate_type}
                    name={"estate_type"}
                    staticOptions={ESTATE_TYPE}
                    style={{ paddingRight: 0 }}
                    value={formik.values.estate_type}
                  />
                </div>
                <div className="col-md-4">
                  <Selected
                    label={"Тип объекта"}
                    onChange={(value) => {
                      formik.setFieldValue(`object_type_id`, value);
                      queryProps({
                        variables: {
                          lang: i18next.language,
                          id: value,
                        },
                      }).then((resp) => {
                        setProps(resp.data.property);
                      });
                    }}
                    error={formik.errors.object_type_id}
                    touched={formik.touched.object_type_id}
                    name={"object_type_id"}
                    staticOptions={listTypeObject}
                    style={{ paddingRight: 0 }}
                    rows={{ value: "id", label: "nameString" }}
                    value={formik.values.object_type_id}
                  />
                </div>
                <div className="col-md-4">
                  <Selected
                    label={"Город"}
                    onChange={(value) => {
                      formik.setFieldValue(`city_id`, value);
                      areaQuery({
                        variables: {
                          lang: i18next.language,
                          city_id: Number(value),
                        },
                      }).then((resp) => {
                        setListAreas(resp.data.areas);
                      });
                    }}
                    error={formik.errors.city_id}
                    touched={formik.touched.city_id}
                    name={"city_id"}
                    staticOptions={listCities}
                    style={{ paddingRight: 0 }}
                    rows={{ value: "id", label: "nameString" }}
                    value={formik.values.city_id}
                  />
                </div>
                <div className="col-md-4">
                  <Selected
                    label={"Район"}
                    onChange={(value) => {
                      formik.setFieldValue(`area_id`, value);
                      placeQuery({
                        variables: {
                          lang: i18next.language,
                          area_id: Number(value),
                        },
                      }).then((resp) => {
                        setListPlace(resp.data.places);
                      });
                    }}
                    error={formik.errors.area_id}
                    touched={formik.touched.area_id}
                    name={"area_id"}
                    staticOptions={listAreas}
                    style={{ paddingRight: 0 }}
                    rows={{ value: "id", label: "nameString" }}
                    value={formik.values.area_id}
                  />
                </div>
                <div className="col-md-4">
                  <Selected
                    label={"Поселок"}
                    onChange={(value) => {
                      formik.setFieldValue(`place_id`, value);
                    }}
                    error={formik.errors.place_id}
                    touched={formik.touched.place_id}
                    name={"place_id"}
                    staticOptions={listPlace}
                    style={{ paddingRight: 0 }}
                    rows={{ value: "id", label: "nameString" }}
                    value={formik.values.place_id}
                  />
                </div>
                <div className="col-md-4">
                  <Selected
                    label={"Ориентир"}
                    onChange={(value) => {
                      formik.setFieldValue(`point_id`, value);
                    }}
                    error={formik.errors.point_id}
                    touched={formik.touched.point_id}
                    name={"point_id"}
                    staticOptions={listPoint}
                    style={{ paddingRight: 0 }}
                    rows={{ value: "id", label: "nameString" }}
                    value={formik.values.point_id}
                  />
                </div>
                <div className="col-md-4">
                  <Selected
                    label={"Состояние"}
                    onChange={(value) => {
                      formik.setFieldValue(`status`, value);
                    }}
                    error={formik.errors.status}
                    touched={formik.touched.status}
                    name={"status"}
                    staticOptions={STATUS}
                    style={{ paddingRight: 0 }}
                    value={formik.values.status}
                  />
                </div>
                <div className="col-md-4">
                  <TextInput
                    label={"Порядок вывода"}
                    value={formik.values.sort}
                    onChange={(event) => {
                      formik.setFieldValue(`sort`, event.target.value);
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.errors.sort}
                    touched={formik.touched.sort}
                    name={"sort"}
                  />
                </div>
                {props.map((prop) => {
                  if (prop.type === 3 || prop.type === 6) {
                    return "";
                  }
                  return (
                    <div className="mb-2 row" key={prop.id}>
                      <label className="col-md-3 col-form-label">
                        {prop.nameString}
                      </label>
                      {(prop.type === 1 || prop.type === 5) && (
                        <div className={"col-md-9"}>
                          <div className={"row"}>
                            <div className="col-md-3">
                              <input
                                type="text"
                                name={"prop[" + prop.id + "][from]"}
                                className="form-control"
                                placeholder={t("от...")}
                                onChange={(event) => {
                                  const _props = { ...formik.values.props };
                                  if (_props[prop.id] === undefined) {
                                    _props[prop.id] = {};
                                  }
                                  _props[prop.id].from = event.target.value;
                                  formik.setFieldValue("props", _props);
                                }}
                              />
                            </div>
                            <div className="col-md-3">
                              <input
                                type="text"
                                name={"prop[" + prop.id + "][to]"}
                                className="form-control"
                                placeholder={t("...до")}
                                onChange={(event) => {
                                  const _props = { ...formik.values.props };
                                  if (_props[prop.id] === undefined) {
                                    _props[prop.id] = {};
                                  }
                                  _props[prop.id].to = event.target.value;
                                  formik.setFieldValue("props", _props);
                                }}
                              />
                            </div>
                            <div className="col-md-3">
                              <input
                                type="text"
                                className="form-control"
                                placeholder={t("фиксировано")}
                                name={"prop[" + prop.id + "][fix]"}
                                onChange={(event) => {
                                  const _props = { ...formik.values.props };
                                  if (_props[prop.id] === undefined) {
                                    _props[prop.id] = {};
                                  }
                                  _props[prop.id].fix = event.target.value;
                                  formik.setFieldValue("props", _props);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      {prop.type === 7 && (
                        <div className={"col-md-9"}>
                          {prop.dictionary.options.map((option) => {
                            return (
                              <label style={{ marginTop: 10 }}>
                                <input
                                  type={"radio"}
                                  name={"prop[" + prop.id + "]"}
                                  checked={
                                    formik.values.props[prop.id] &&
                                    formik.values.props[prop.id] === option.id
                                  }
                                  value={option.id}
                                  onClick={() => {
                                    const _props = { ...formik.values.props };
                                    _props[prop.id] = option.id;
                                    formik.setFieldValue("props", _props);
                                  }}
                                />
                                <span
                                  style={{
                                    marginLeft: 2,
                                    marginRight: 10,
                                    top: -1,
                                    position: "relative",
                                  }}
                                >
                                  {option.valueString}
                                </span>
                              </label>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  );
                })}
                <div className="col-md-12 text-center">
                  <Button
                    label={"Сохранить"}
                    loading={loading}
                    style={"btn btn-primary w-md"}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
