import { gql } from "@apollo/client";

export const MODIFY_OBJECT_TYPE = gql`
  mutation (
    $id: Int!
    $name: String!
    $target: Int!
    $estate_type: Int!
    $complexes: String
    $steps: String
    $propertyGroups: String
    $properties: String
    $enable_compare: Int!
    $icon: String!
    $words: String
    $about_title: String
    $status: Int!
    $status_dev: Int!
    $sort: Int!
    $squad_type: Int!
  ) {
    modifyObjectType(
      id: $id
      name: $name
      target: $target
      estate_type: $estate_type
      complexes: $complexes
      steps: $steps
      propertyGroups: $propertyGroups
      properties: $properties
      enable_compare: $enable_compare
      icon: $icon
      words: $words
      about_title: $about_title
      status: $status
      status_dev: $status_dev
      sort: $sort
      squad_type: $squad_type
    )
  }
`;

export const CHANGE_SORT_STEP = gql`
  mutation ($arr: String!) {
    changeSortStep(arr: $arr)
  }
`;

export const CHANGE_SORT_FILTER_PROPS = gql`
  mutation ($arr: String!, $object_type_id: Int!) {
    changeSortFilterProps(arr: $arr, object_type_id: $object_type_id)
  }
`;

export const CHANGE_SORT_ITEM_PROPS = gql`
  mutation ($arr: String!, $object_type_id: Int!) {
    changeSortItemProps(arr: $arr, object_type_id: $object_type_id)
  }
`;

export const CHANGE_SORT_GROUP = gql`
  mutation ($arr: String!) {
    changeSortGroup(arr: $arr)
  }
`;

export const CHANGE_SORT_PROPS = gql`
  mutation ($arr: String!) {
    changeSortProps(arr: $arr)
  }
`;

export const DELETE_OBJECT_TYPE = gql`
  mutation ($id: Int!) {
    deleteObjectType(id: $id) {
      id
      name {
        lang
        value
      }
    }
  }
`;

export const TITLE_SETTING = gql`
  mutation (
    $tags: String!
    $tagsSearch: String!
    $id: Int!
    $tagsTitle: String!
    $tagsDesc: String!
  ) {
    titleSetting(
      tags: $tags
      id: $id
      tagsSearch: $tagsSearch
      tagsTitle: $tagsTitle
      tagsDesc: $tagsDesc
    )
  }
`;

export const THUMB_PROPS = gql`
  mutation ($props: String!, $id: Int!) {
    thumbProps(props: $props, id: $id)
  }
`;

export const QUICK_FILTERS = gql`
  mutation ($props: String!, $id: Int!) {
    modifyQuickFilter(props: $props, id: $id)
  }
`;
