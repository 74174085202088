import { gql } from "@apollo/client";

export const GET_SETTING = gql`
  query {
    setting {
      id
      name
      value
      type
    }
  }
`;
