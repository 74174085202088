import { Selected } from "../Form/Selected";
import { Button } from "../Button";
import { MultilangTextInput } from "../Form/MultilangTextInput";
import { useFormik } from "formik";
import * as Yup from "yup";
import { CountryFormComponent } from "./CountryForm";
import { Notification } from "../../utils/Notification";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "@apollo/client";
import { MODIFY_CITY } from "../../graphql/Mutations/CityMutation";
import { TextInput } from "../Form/TextInput";
import { Col, Modal } from "react-bootstrap";
import { Loader } from "@googlemaps/js-api-loader";

export const CityFormComponent = ({ onSuccess, onError, url, data }) => {
  const { t } = useTranslation();
  const [modify] = useMutation(MODIFY_CITY);
  const formik = useFormik({
    initialValues: {
      id: 0,
      name: {
        ru: "",
        az: "",
        en: "",
      },
      country_id: 0,
      lat: "",
      lng: "",
      objects: [],
    },
    validationSchema: Yup.object({
      name: Yup.object().shape({
        ru: Yup.string().required(t("Объязательное поле")),
      }),
      country_id: Yup.number().required(t("Укажите страну")),
      lat: Yup.number().required(t("Объязательное поле")),
      lng: Yup.number().required(t("Объязательное поле")),
    }),
    onSubmit: (values) => {
      setLoading(true);
      modify({
        variables: {
          id: Number(values.id),
          name: JSON.stringify(values.name),
          country_id: Number(values.country_id),
          lat: String(values.lat),
          lng: String(values.lng),
          objects: JSON.stringify(values.objects),
        },
      })
        .then((response) => {
          onSuccess(response);
        })
        .catch((error) => {
          onError(error);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });
  const [loading, setLoading] = useState(false);
  const [showModal, toggleModal] = useState("");

  const loader = new Loader({
    apiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
    version: "weekly",
    libraries: ["places", "marker"],
  });

  useEffect(() => {
    if (data && data.cities[0]) {
      const obj = [];
      data.cities[0].objectType.map((objectType) => {
        obj.push(objectType.object_type_id);
      });
      formik.setFieldValue("objects", obj);
      data.cities[0].name.map((dic) => {
        formik.setFieldValue(`name.${dic.lang}`, dic.value);
      });
      formik.setFieldValue("id", data.cities[0].id);
      formik.setFieldValue("country_id", data.cities[0].country.id);
      formik.setFieldValue("lat", data.cities[0].lat);
      formik.setFieldValue("lng", data.cities[0].lng);
    }
  }, [data]);

  return (
    <form className="row" onSubmit={formik.handleSubmit}>
      <div className="col-md-6">
        <MultilangTextInput
          label={"Название"}
          value={formik.values.name}
          onChange={(text, lang) => {
            formik.setFieldValue(`name.${lang}`, text);
          }}
          onBlur={formik.handleBlur}
          error={formik.errors.name}
          name={"name"}
        />
      </div>
      <div className="col-md-6">
        <Selected
          label={"Страна"}
          style={{ marginTop: "38px" }}
          onChange={(value) => {
            console.log("[Log]", value);
            formik.setFieldValue(`country_id`, value);
          }}
          error={formik.errors.country_id}
          name={"country_id"}
          remotePath={"/countries"}
          modalForm={
            <CountryFormComponent
              data={false}
              url={"/countries"}
              onSuccess={() => {
                toast.success(t("Страна создана"));
              }}
            />
          }
          staticOptions={data?.countries}
          rows={{ value: "id", label: "nameString" }}
          value={formik.values.country_id}
        />
      </div>
      <div className="col-md-4">
        <TextInput
          label={"Lat"}
          value={formik.values.lat}
          onChange={(event) => {
            formik.setFieldValue(`lat`, event.target.value);
          }}
          onBlur={formik.handleBlur}
          error={formik.errors.lat}
          touched={formik.touched.lat}
          name={"lat"}
        />
      </div>
      <div className="col-md-4">
        <TextInput
          label={"Lng"}
          value={formik.values.lng}
          onChange={(event) => {
            formik.setFieldValue(`lng`, event.target.value);
          }}
          onBlur={formik.handleBlur}
          error={formik.errors.lng}
          touched={formik.touched.lng}
          name={"lng"}
        />
      </div>
      <Col md={4}>
        <button
          type={"button"}
          className={"btn btn-success"}
          style={{ height: 56, width: "49%", marginRight: "2%" }}
          onClick={() => {
            const address = formik.values.name.az;
            if (address === "") {
              toast.error(t("Сначала укажите адрес"));
            } else {
              axios
                .get(
                  `https://maps.googleapis.com/maps/api/geocode/json?address=Azerbaijan,+${address}&key=${process.env.REACT_APP_GOOGLE_MAP_KEY}`,
                )
                .then((response) => {
                  formik.setFieldValue(
                    "lat",
                    response.data?.results[0].geometry.location.lat,
                  );
                  formik.setFieldValue(
                    "lng",
                    response.data?.results[0].geometry.location.lng,
                  );
                })
                .catch((error) => toast.error(error.message));
            }
          }}
        >
          {t("Определить координати")}
        </button>
        <button
          type={"button"}
          className={"btn btn-warning"}
          style={{ height: 56, width: "49%" }}
          onClick={() => {
            toggleModal(true);
            loader
              .load()
              .then((google) => {
                const map = new google.maps.Map(
                  document.getElementById("map"),
                  {
                    center: {
                      lat: Number(
                        formik.values.lat === ""
                          ? 40.401006011050455
                          : formik.values.lat,
                      ),
                      lng: Number(
                        formik.values.lng === ""
                          ? 49.85458884979265
                          : formik.values.lng,
                      ),
                    },
                    zoom: 16,
                  },
                );
                const draggableMarker = new google.maps.Marker({
                  position: {
                    lat: Number(
                      formik.values.lat === ""
                        ? 40.401006011050455
                        : formik.values.lat,
                    ),
                    lng: Number(
                      formik.values.lng === ""
                        ? 49.85458884979265
                        : formik.values.lng,
                    ),
                  },
                  map,
                  title: "Hello World!",
                  draggable: true,
                });
                // const draggableMarker =
                //   new google.maps.AdvancedMarkerElement({
                //     map,
                //     position: {
                //       lat: 40.401006011050455,
                //       lng: 49.85458884979265,
                //     },
                //     gmpDraggable: true,
                //     title: "This marker is draggable.",
                //   });
                //
                draggableMarker.addListener("dragend", (event) => {
                  const position = draggableMarker.position;
                  formik.setFieldValue("lat", position.lat());
                  formik.setFieldValue("lng", position.lng());
                });
              })
              .catch((e) => {
                // do something
              });
          }}
        >
          {t("Указать на карте")}
        </button>
      </Col>
      <Modal show={showModal}>
        <Modal.Header closeButton onClick={() => toggleModal(false)}>
          <Modal.Title>{t("Координаты")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div id={"map"} style={{ width: "100%", height: 300 }} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={() => formik.handleSubmit()}>
            {t("Сохранить")}
          </Button>
          <Button variant="secondary" onClick={() => toggleModal(false)}>
            {t("Закрыть")}
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="col-md-12">
        <Selected
          label={"Типы объектов для SITEMAP"}
          style={{ marginTop: "38px" }}
          onChange={(value) => {
            const data = value.map((val) => {
              return val.value;
            });
            formik.setFieldValue(`objects`, data);
          }}
          error={formik.errors.objects}
          name={"objects"}
          rows={{ value: "id", label: "nameString" }}
          staticOptions={data?.objectType}
          value={formik.values.objects}
          tags={true}
        />
      </div>
      <div className="col-md-12 text-center">
        <Button
          label={"Сохранить"}
          loading={loading}
          style={"btn btn-primary w-md"}
        />
      </div>
    </form>
  );
};
