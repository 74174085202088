import { gql } from "@apollo/client";

export const GET_FAQ_TABS = gql`
  query {
    faqTabs {
      id
      name {
        lang
        value
      }
      page
    }
  }
`;

export const GET_FAQ_TAB = gql`
  query ($id: Int) {
    faqTabs(id: $id) {
      id
      name {
        lang
        value
      }
      page
    }
  }
`;

export const GET_FAQS = gql`
  query {
    faq {
      id
      title {
        lang
        value
      }
      tab {
        name {
          lang
          value
        }
      }
    }
  }
`;

export const GET_FAQ = gql`
  query ($id: Int, $lang: String!) {
    faq(id: $id) {
      id
      title {
        lang
        value
      }
      text {
        lang
        value
      }
      tab {
        id
      }
    }
    faqTabs {
      id
      nameString(lang: $lang)
    }
  }
`;
