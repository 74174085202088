import React, { useEffect, useState } from "react";
import { Wrapper } from "../../components/Wrapper";
import { PageTitle } from "../../components/PageTitle";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Notification } from "../../utils/Notification";
import { useTranslation } from "react-i18next";
import { Button } from "../../components/Button";
import { MultilangTextInput } from "../../components/Form/MultilangTextInput";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { Preload } from "../../components/Preload";
import { Selected } from "../../components/Form/Selected";
import { SIZE_FOOTER_MENU, STATUS } from "../../utils/Dictionaries";
import { GET_FOOTER_MENU_ONE } from "../../graphql/Queries/FooterMenuQuery";
import { MODIFY_FOOTER_MENU } from "../../graphql/Mutations/FooterMenuMutation";
import { TextInput } from "../../components/Form/TextInput";

export const FooterMenuForm = ({ id = null }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const [modify] = useMutation(MODIFY_FOOTER_MENU);
  const { load, error, data } = useQuery(GET_FOOTER_MENU_ONE, {
    variables: {
      id: Number(searchParams.get("id")),
    },
    fetchPolicy: "no-cache",
  });
  if (error) {
    toast.error(error.message);
  }
  const formik = useFormik({
    initialValues: {
      id: 0,
      title: {
        ru: "",
        az: "",
        en: "",
      },
      size: 1,
      sort: "",
      status: 1,
    },
    validationSchema: Yup.object({
      title: Yup.object().shape({
        az: Yup.string().required(t("Объязательное поле")),
      }),
    }),
    onSubmit: (values) => {
      setLoading(true);
      modify({
        variables: {
          id: Number(values.id),
          title: JSON.stringify(values.title),
          size: values.size,
          status: Number(values.status),
          sort: values.sort !== "" ? Number(values.sort) : "",
        },
      })
        .then((response) => {
          navigate("/footer-menu");
          Notification(t("Все хорошо!"), "success");
        })
        .catch((error) => {
          Notification(error);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  useEffect(() => {
    if (data && data.footerMenu.length > 0) {
      data.footerMenu[0].title.map((dic) => {
        formik.setFieldValue(`title.${dic.lang}`, dic.value);
      });
      formik.setFieldValue("id", data.footerMenu[0].id);
      formik.setFieldValue("size", data.footerMenu[0].size);
      formik.setFieldValue("sort", data.footerMenu[0].sort);
      formik.setFieldValue("status", data.footerMenu[0].status);
    }
  }, [data]);

  if (!data) {
    return <Preload />;
  }

  return (
    <Wrapper>
      <PageTitle
        title={id === null ? "Добавить блок" : "Редактировать блок"}
        breadcrumbs={[
          {
            slug: "/footer-menu",
            name: "Ссылки в футере",
          },
        ]}
      />
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <form className="row" onSubmit={formik.handleSubmit}>
                <div className="col-md-6">
                  <MultilangTextInput
                    label={"Название"}
                    value={formik.values.title}
                    onChange={(text, lang) => {
                      formik.setFieldValue(`title.${lang}`, text);
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.errors.title}
                    touched={formik.touched.title}
                    name={"title"}
                  />
                </div>
                <div className="col-md-6 mt-4">
                  <Selected
                    label={"Размер"}
                    onChange={(value) => {
                      formik.setFieldValue(`size`, value);
                    }}
                    error={formik.errors.size}
                    touched={formik.touched.size}
                    name={"size"}
                    staticOptions={SIZE_FOOTER_MENU}
                    style={{ paddingRight: 0 }}
                    value={formik.values.size}
                  />
                </div>
                <div className="col-md-6">
                  <TextInput
                    label={"Порядок вывода"}
                    value={formik.values.sort}
                    onChange={(event) => {
                      formik.setFieldValue(`sort`, event.target.value);
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.errors.sort}
                    touched={formik.touched.sort}
                    name={"sort"}
                  />
                </div>
                <div className="col-md-6">
                  <Selected
                    label={"Состояние"}
                    onChange={(value) => {
                      formik.setFieldValue(`status`, value);
                    }}
                    error={formik.errors.status}
                    touched={formik.touched.status}
                    name={"size"}
                    staticOptions={STATUS}
                    style={{ paddingRight: 0 }}
                    value={formik.values.status}
                  />
                </div>
                <div className="col-md-12 text-center">
                  <Button
                    label={"Сохранить"}
                    loading={loading}
                    style={"btn btn-primary w-md"}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
