import { gql } from "@apollo/client";

export const GET_PROPERTY_GROUPS = gql`
  query {
    property_group {
      id
      name {
        lang
        value
      }
      name_system {
        lang
        value
      }
      step {
        id
        name {
          lang
          value
        }
      }
    }
  }
`;

export const GET_PROPERTY_GROUP = gql`
  query ($id: Int!) {
    property_group(id: $id) {
      id
      name {
        lang
        value
      }
      show_in_page
      name_system {
        lang
        value
      }
      step {
        id
        name {
          lang
          value
        }
      }
    }
  }
`;
