import React, { useEffect, useState } from "react";
import { Wrapper } from "../../components/Wrapper";
import { PageTitle } from "../../components/PageTitle";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Col, Row } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { Preload } from "../../components/Preload";
import { GET_DATA_OBJECT_DESIGN } from "../../graphql/Queries/ObjectQuery";
import { CHANGE_SORT_STEP } from "../../graphql/Mutations/ObjectTypeMutation";
import Nestable from "react-nestable";
import { Step } from "../../components/Sort/Step";
import { SortableContainer, SortableElement } from "react-sortable-hoc";

export const Design = ({ id = null }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const [changeSortStep] = useMutation(CHANGE_SORT_STEP);
  const { load, error, data } = useQuery(GET_DATA_OBJECT_DESIGN, {
    variables: {
      id: Number(searchParams.get("id")),
    },
    fetchPolicy: "no-cache",
  });
  if (error) {
    toast.error(error.message);
  }
  const languages = [
    {
      url: "ru",
      label: t("Ру"),
    },
    {
      url: "az",
      label: t("Az"),
    },
    {
      url: "en",
      label: t("En"),
    },
  ];
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    if (data) {
      setSteps(data.objectType[0].stepsFull);
    }
  }, [data]);

  const SortableItem = SortableElement(({ item }) => {
    return <Step item={item} />;
  });

  const SortableList = SortableContainer(({ items }) => {
    return (
      <di>
        {items.map((item, index) => {
          return (
            <SortableItem key={`item-${index}`} index={index} item={item} />
          );
        })}
      </di>
    );
  });

  if (!data) {
    return <Preload />;
  }

  return (
    <Wrapper>
      <PageTitle
        title={"Редактировать расположение"}
        breadcrumbs={[
          {
            slug: "/type-objects",
            name: "Типы объектов",
          },
        ]}
      />
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <Row>
                <Col md={12}>
                  <SortableList
                    items={steps}
                    onSortEnd={({
                      oldIndex,
                      newIndex,
                      collection,
                      isKeySorting,
                    }) => {
                      const _steps = [...steps];
                      const el = _steps[oldIndex];
                      _steps.splice(oldIndex, 1);
                      _steps.splice(newIndex, 0, el);
                      setSteps(_steps);
                      const arr = [];
                      _steps.map((item, index) => {
                        arr.push({ id: item.id, sort: index + 1 });
                      });
                      changeSortStep({
                        variables: {
                          arr: JSON.stringify(arr),
                        },
                      })
                        .then((response) => {
                          console.log("[Log]", response.data);
                        })
                        .catch((error) => {
                          console.log("[Err]", error);
                        });
                    }}
                  />
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
