import { gql } from "@apollo/client";

export const GET_COUNTRIES = gql`
  query {
    countries {
      id
      name {
        lang
        value
      }
    }
  }
`;

export const GET_COUNTRY = gql`
  query ($id: Int!) {
    countries(id: $id) {
      id
      name {
        lang
        value
      }
    }
  }
`;
