import { gql } from "@apollo/client";

export const MODIFY_COUNTRY = gql`
  mutation ($id: Int!, $name: String!) {
    modifyCountry(id: $id, name: $name)
  }
`;

export const DELETE_COUNTRY = gql`
  mutation ($id: Int!) {
    deleteCountry(id: $id) {
      id
      name {
        lang
        value
      }
    }
  }
`;
