import { gql } from "@apollo/client";

export const EDIT_PAGE = gql`
  mutation (
    $id: Int!
    $name: String!
    $slug: String!
    $position: Int!
    $template: Int!
    $status: Int!
    $icon: String
    $drop_template: Int
    $parent_id: Int
    $target: Int
    $estate_type: Int
    $object_type_id: Int
    $text: String
  ) {
    updateMenu(
      id: $id
      name: $name
      slug: $slug
      position: $position
      status: $status
      template: $template
      icon: $icon
      drop_template: $drop_template
      parent_id: $parent_id
      object_type_id: $object_type_id
      target: $target
      estate_type: $estate_type
      text: $text
    )
  }
`;

export const ADD_PAGE = gql`
  mutation (
    $name: String!
    $slug: String!
    $position: Int!
    $template: Int!
    $status: Int!
    $icon: String
    $drop_template: Int
    $parent_id: Int
    $target: Int
    $estate_type: Int
    $object_type_id: Int
    $text: String
  ) {
    addMenu(
      name: $name
      slug: $slug
      position: $position
      status: $status
      template: $template
      icon: $icon
      drop_template: $drop_template
      parent_id: $parent_id
      object_type_id: $object_type_id
      target: $target
      estate_type: $estate_type
      text: $text
    )
  }
`;

export const DELETE_PAGE = gql`
  mutation ($id: Int!) {
    deleteMenu(id: $id) {
      id
      name {
        lang
        value
      }
      slug {
        lang
        value
      }
      sort
      position
      template
      status
    }
  }
`;
