import React, { useState } from "react";
import { Wrapper } from "../../components/Wrapper";
import { PageTitle } from "../../components/PageTitle";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Preload } from "../../components/Preload";
import { useQuery } from "@apollo/client";
import { GET_PROMO_CODE_STAT } from "../../graphql/Queries/PromoCodeQuery";
import moment from "moment";

export const Stat = ({ url }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  let location = useLocation();
  const id = location.state ? location.state.id : null;
  let [searchParams, setSearchParams] = useSearchParams();
  const { data } = useQuery(GET_PROMO_CODE_STAT, {
    variables: {
      id: Number(searchParams.get("code_id")),
    },
  });

  if (!data) {
    return <Preload />;
  }

  return (
    <Wrapper>
      <PageTitle
        title={"Статистика промокода"}
        breadcrumbs={[
          {
            slug: url,
            name: "Промокоды",
          },
        ]}
      />
      <div className="row">
        <div className="col-xl-3 col-md-6">
          <div className="widget-simple text-center card">
            <div className="card-body">
              <h3 className="text-success mt-0">
                <span data-plugin="counterup">{data.promoCodeUser.length}</span>
              </h3>
              <p className="text-muted mb-0">{t("всего применений")}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <table className={"table mb-0"}>
                <thead className={"table-light"}>
                  <tr>
                    <th>{t("Дата")}</th>
                    <th>{t("Имя")}</th>
                    <th>{t("Номер")}</th>
                    <th>{t("ID пользователя")}</th>
                  </tr>
                </thead>
                <tbody>
                  {data.promoCodeUser.map((item) => {
                    return (
                      <tr>
                        <td>
                          {moment(item.updated_at).format("HH:MM DD.MM.YYYY")}
                        </td>
                        <td>{item.user.name}</td>
                        <td>{item.user.phone}</td>
                        <td>{item.user.id}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
