import React from "react";
import { Dropdown } from "react-bootstrap";
import us from "../../assets/images/flags/us.jpg";
import ru from "../../assets/images/flags/russia.jpg";
import az from "../../assets/images/flags/azer.jpeg";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setLang } from "../../redux/slices/configSlice";

export const Lang = () => {
  const { t } = useTranslation();
  const lang = i18next.language;
  const languages = {
    en: {
      icon: us,
      name: "English",
    },
    ru: {
      icon: ru,
      name: "Русский",
    },
    az: {
      icon: az,
      name: "Azerbaijan",
    },
  };
  const dispatch = useDispatch();

  return (
    <>
      <Dropdown
        className="dropdown d-none d-lg-inline-block topbar-dropdown"
        as={"li"}
      >
        <Dropdown.Toggle
          className={
            "nav-link dropdown-toggle arrow-none waves-effect waves-light"
          }
          as={"a"}
        >
          <img src={languages[lang].icon} alt="user-image" height="14" />
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu dropdown-menu-end">
          {Object.keys(languages).map((key) => {
            if (key !== lang) {
              const l = languages[key];
              return (
                <Dropdown.Item
                  key={key}
                  className="dropdown-item"
                  aria-expanded={"false"}
                  aria-haspopup={"false"}
                  onClick={() => {
                    i18next.changeLanguage(key);
                    dispatch(setLang(key));
                  }}
                >
                  <img
                    src={l.icon}
                    alt="user-image"
                    className="me-1"
                    height="12"
                  />{" "}
                  <span className="align-middle">{l.name}</span>
                </Dropdown.Item>
              );
            }
          })}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};
