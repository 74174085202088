import React, { useEffect, useState } from "react";
import { Wrapper } from "../../components/Wrapper";
import { PageTitle } from "../../components/PageTitle";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Notification } from "../../utils/Notification";
import { useTranslation } from "react-i18next";
import { Button } from "../../components/Button";
import { MultilangTextInput } from "../../components/Form/MultilangTextInput";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Preload } from "../../components/Preload";
import { Selected } from "../../components/Form/Selected";
import { useMutation, useQuery } from "@apollo/client";
import i18next from "i18next";
import { GET_FAQ } from "../../graphql/Queries/FaqQuery";
import { FaqTabsFormComponent } from "../../components/forms/FaqTabsFormComponent";
import { MODIFY_FAQ } from "../../graphql/Mutations/FaqMutation";

export const FaqForm = ({ url }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  let location = useLocation();
  const id = location.state ? location.state.id : null;
  let [searchParams, setSearchParams] = useSearchParams();
  const [modify] = useMutation(MODIFY_FAQ);
  const { data } = useQuery(GET_FAQ, {
    variables: {
      id: Number(searchParams.get("id")),
      lang: i18next.language,
    },
  });
  const formik = useFormik({
    initialValues: {
      id: 0,
      title: {
        ru: "",
        az: "",
        en: "",
      },
      text: {
        ru: "",
        az: "",
        en: "",
      },
      tab_id: 0,
    },
    validationSchema: Yup.object({
      title: Yup.object().shape({
        az: Yup.string().required(t("Объязательное поле")),
      }),
      text: Yup.object().shape({
        az: Yup.string().required(t("Объязательное поле")),
      }),
      tab_id: Yup.number().required(t("Объязательное поле")),
    }),
    onSubmit: (values) => {
      setLoading(true);
      modify({
        variables: {
          id: Number(values.id),
          title: JSON.stringify(values.title),
          text: JSON.stringify(values.text),
          tab_id: Number(values.tab_id),
        },
      })
        .then((response) => {
          navigate(url);
          Notification(t("Все хорошо!"), "success");
        })
        .catch((error) => {
          Notification(error);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  useEffect(() => {
    if (data && data.faq[0]) {
      data.faq[0].title.map((dic) => {
        formik.setFieldValue(`title.${dic.lang}`, dic.value);
      });
      data.faq[0].text.map((dic) => {
        formik.setFieldValue(`text.${dic.lang}`, dic.value);
      });
      formik.setFieldValue("id", data.faq[0].id);
      formik.setFieldValue("tab_id", data.faq[0].tab.id);
    }
  }, [data]);

  if (!data) {
    return <Preload />;
  }

  return (
    <Wrapper>
      <PageTitle
        title={id === null ? "Добавить faq" : "Редактировать faq"}
        breadcrumbs={[
          {
            slug: url,
            name: "FAQ",
          },
        ]}
      />
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <form className="row" onSubmit={formik.handleSubmit}>
                <div className="col-md-12">
                  <MultilangTextInput
                    label={"Заголовок"}
                    value={formik.values.title}
                    onChange={(text, lang) => {
                      formik.setFieldValue(`title.${lang}`, text);
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.errors.title}
                    touched={formik.touched.title}
                    name={"title"}
                  />
                </div>
                <div className="col-md-12">
                  <MultilangTextInput
                    type={"textarea"}
                    label={"Текст"}
                    value={formik.values.text}
                    onChange={(text, lang) => {
                      formik.setFieldValue(`text.${lang}`, text);
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.errors.text}
                    touched={formik.touched.text}
                    name={"text"}
                  />
                </div>
                <div className="col-md-6">
                  <Selected
                    label={"Группа"}
                    onChange={(value) => {
                      formik.setFieldValue(`tab_id`, value);
                    }}
                    error={formik.errors.tab_id}
                    name={"tab_id"}
                    remotePath={"/faqTabs"}
                    modalForm={
                      <FaqTabsFormComponent
                        data={{ name: { ru: "", en: "", az: "" }, page: 1 }}
                        url={"/faq-tabs"}
                        onSuccess={() => {
                          Notification(t("Группа создана"));
                        }}
                      />
                    }
                    rows={{ value: "id", label: "nameString" }}
                    staticOptions={data?.faqTabs}
                    value={formik.values.tab_id}
                  />
                </div>
                <div className="col-md-12 text-center">
                  <Button
                    label={"Сохранить"}
                    loading={loading}
                    style={"btn btn-primary w-md"}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
