import React, { useState, useRef, useMemo } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export const RichEditor = ({ value, onChange }) => {
  const editor = useRef(null);
  const [content, setContent] = useState("");

  return (
    <ReactQuill
      theme="snow"
      value={value}
      onChange={onChange}
      style={{ height: 300, marginBottom: 80 }}
    />
  );
};
