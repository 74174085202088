import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export const PageTitle = ({ title, breadcrumbs = [] }) => {
  const { t } = useTranslation();
  return (
    <div className="row">
      <div className="col-12">
        <div className="page-title-box">
          <h4 className="page-title">{t(title)}</h4>
          <div className="page-title-right">
            <ol className="breadcrumb m-0">
              {breadcrumbs.map((item, index) => {
                return (
                  <li key={index} className="breadcrumb-item">
                    <Link to={item.slug}>{item.name}</Link>
                  </li>
                );
              })}
              <li className="breadcrumb-item active">{t(title)}</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
};
