import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  complaint: 0,
  estates: 0,
};

const badgeSlice = createSlice({
  name: "badge",
  initialState,
  reducers: {
    addComplaint: (state, action) => {
      state.complaint = action.payload;
    },
    setBadge: (state, action) => {
      Object.keys(action.payload).map((key) => {
        state[key] = action.payload[key];
      });
    },
  },
});

export const { addComplaint, setBadge } = badgeSlice.actions;

export default badgeSlice.reducer;
