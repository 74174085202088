import React, { useEffect, useState } from "react";
import { Wrapper } from "../../components/Wrapper";
import { PageTitle } from "../../components/PageTitle";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Col, Row } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { Preload } from "../../components/Preload";
import { SEPARATORS, WithContext as ReactTags } from "react-tag-input";
import { TITLE_SETTING } from "../../graphql/Mutations/ObjectTypeMutation";
import { GET_LIST_PROPERTIES } from "../../graphql/Queries/PropertyQuery";
import i18next from "i18next";
import { Button } from "../../components/Button";

export const Title = ({ id = null }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const [save] = useMutation(TITLE_SETTING);
  const { load, error, data } = useQuery(GET_LIST_PROPERTIES, {
    variables: {
      lang: i18next.language,
      id: Number(searchParams.get("id")),
    },
    fetchPolicy: "no-cache",
  });
  if (error) {
    toast.error(error.message);
  }

  const KeyCodes = {
    comma: 188,
    enter: [10, 13],
  };

  const delimiters = [KeyCodes.enter];
  const [tags, setTags] = React.useState([]);
  const [tagsSearch, setTagsSearch] = React.useState([]);
  const [tagsTitle, setTagsTitle] = React.useState([]);
  const [tagsDesc, setTagsDesc] = React.useState([]);
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    if (data) {
      const arr = [{ id: "0", text: t("Адрес") }];
      data.property.map((prop) => {
        arr.push({
          id: String(prop.id),
          text: String(prop.nameString),
        });
      });
      setSuggestions(arr);
      if (data.objectType[0].title) {
        setTags(JSON.parse(data.objectType[0].title));
      }
      if (data.objectType[0].title_from_search) {
        setTagsSearch(JSON.parse(data.objectType[0].title_from_search));
      }
      if (data.objectType[0].seo_title) {
        setTagsTitle(JSON.parse(data.objectType[0].seo_title));
      }
      if (data.objectType[0].seo_desc) {
        setTagsDesc(JSON.parse(data.objectType[0].seo_desc));
      }
    }
  }, [data]);

  if (!data) {
    return <Preload />;
  }

  return (
    <Wrapper>
      <PageTitle
        title={"Формирование заголовка"}
        breadcrumbs={[
          {
            slug: "/type-objects",
            name: "Типы объектов",
          },
        ]}
      />
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <ul>
                <li>
                  -{" "}
                  {t(
                    "Начинайте вводить названия свойств для поиска, выбирайте нужное и формируйте заголовок",
                  )}
                </li>
                <li>
                  - {t("Если нужно просто слово - введите и нажмите энтер")}
                </li>
                <li>
                  - {t("Если вам нужен пробел - используйте подчеркивание _")}
                </li>
                <li>
                  -{" "}
                  {t(
                    "Если вам нужена запятая и пробел после нее - используйте двойное подчеркивание __",
                  )}
                </li>
                <li>
                  -{" "}
                  {t(
                    "Если вам нужна точка и пробел после нее - используйте тройное подчеркивание ___",
                  )}
                </li>
                <li>
                  -{" "}
                  {t(
                    "Если вам нужен номер обявления - просто укажите фразу ID",
                  )}
                </li>
                <li>
                  - {t("Если вам нужен город - просто укажите фразу CITY")}
                </li>
                <li>
                  - {t("Если вам нужна цена - просто укажите фразу PRICE")}
                </li>
                <li>
                  -{" "}
                  {t(
                    "Для аренды: если нужно, что бы подставлялось AZN/день или AZN/месяц, укажите RENT_TYPE",
                  )}
                </li>
              </ul>
              <Row>
                <h3>{t("Заголовок для объвления")}</h3>
                <Col md={12} style={{ marginBottom: 30 }}>
                  <ReactTags
                    tags={tags}
                    allowUnique={false}
                    suggestions={suggestions}
                    separators={[SEPARATORS.ENTER]}
                    handleDelete={(i) => {
                      setTags(tags.filter((tag, index) => index !== i));
                    }}
                    handleAddition={(tag) => {
                      setTags([...tags, tag]);
                    }}
                    placeholder={t("Введите фразу для добавления")}
                    allowDragDrop={true}
                    handleDrag={() => (tag, currPos, newPos) => {
                      console.log("[Log]", "Drag");
                      const newTags = tags.slice();

                      newTags.splice(currPos, 1);
                      newTags.splice(newPos, 0, tag);

                      // re-render
                      setTags(newTags);
                    }}
                    handleTagClick={() => {}}
                    inputFieldPosition="bottom"
                    autocomplete={false}
                    classNames={{
                      tags: "tagsClass",
                      tagInput: "tagInputClass",
                      tagInputField: "form-control",
                      selected: "selectedClass",
                      tag: "tagClass",
                      remove: "removeClass",
                      suggestions: "suggestionsClass",
                      activeSuggestion: "activeSuggestionClass",
                      editTagInput: "editTagInputClass",
                      editTagInputField: "editTagInputField",
                      clearAll: "clearAllClass",
                    }}
                  />
                </Col>
                <h3>{t("Заголовок для сохранения поиска")}</h3>
                <Col md={12} style={{ marginBottom: 30 }}>
                  <ReactTags
                    tags={tagsSearch}
                    allowUnique={false}
                    suggestions={suggestions}
                    separators={[SEPARATORS.ENTER]}
                    handleDelete={(i) => {
                      setTagsSearch(
                        tagsSearch.filter((tag, index) => index !== i),
                      );
                    }}
                    handleAddition={(tag) => {
                      setTagsSearch([...tagsSearch, tag]);
                    }}
                    placeholder={t("Введите фразу для добавления")}
                    handleDrag={() => (tag, currPos, newPos) => {
                      const newTags = tagsSearch.slice();

                      newTags.splice(currPos, 1);
                      newTags.splice(newPos, 0, tag);

                      // re-render
                      setTagsSearch(newTags);
                    }}
                    handleTagClick={() => {}}
                    inputFieldPosition="bottom"
                    autocomplete={false}
                    classNames={{
                      tags: "tagsClass",
                      tagInput: "tagInputClass",
                      tagInputField: "form-control",
                      selected: "selectedClass",
                      tag: "tagClass",
                      remove: "removeClass",
                      suggestions: "suggestionsClass",
                      activeSuggestion: "activeSuggestionClass",
                      editTagInput: "editTagInputClass",
                      editTagInputField: "editTagInputField",
                      clearAll: "clearAllClass",
                    }}
                  />
                </Col>
                <h3>{t("SEO Title")}</h3>
                <Col md={12} style={{ marginBottom: 30 }}>
                  <ReactTags
                    tags={tagsTitle}
                    allowUnique={false}
                    suggestions={suggestions}
                    separators={[SEPARATORS.ENTER]}
                    handleDelete={(i) => {
                      setTagsTitle(
                        tagsTitle.filter((tag, index) => index !== i),
                      );
                    }}
                    handleAddition={(tag) => {
                      setTagsTitle([...tagsTitle, tag]);
                    }}
                    placeholder={t("Введите фразу для добавления")}
                    handleDrag={() => (tag, currPos, newPos) => {
                      const newTags = tagsTitle.slice();

                      newTags.splice(currPos, 1);
                      newTags.splice(newPos, 0, tag);

                      // re-render
                      setTagsTitle(newTags);
                    }}
                    handleTagClick={() => {}}
                    inputFieldPosition="bottom"
                    autocomplete={false}
                    classNames={{
                      tags: "tagsClass",
                      tagInput: "tagInputClass",
                      tagInputField: "form-control",
                      selected: "selectedClass",
                      tag: "tagClass",
                      remove: "removeClass",
                      suggestions: "suggestionsClass",
                      activeSuggestion: "activeSuggestionClass",
                      editTagInput: "editTagInputClass",
                      editTagInputField: "editTagInputField",
                      clearAll: "clearAllClass",
                    }}
                  />
                </Col>
                <h3>{t("SEO Description")}</h3>
                <Col md={12}>
                  <ReactTags
                    tags={tagsDesc}
                    allowUnique={false}
                    suggestions={suggestions}
                    separators={[SEPARATORS.ENTER]}
                    handleDelete={(i) => {
                      setTagsDesc(tagsDesc.filter((tag, index) => index !== i));
                    }}
                    handleAddition={(tag) => {
                      setTagsDesc([...tagsDesc, tag]);
                    }}
                    placeholder={t("Введите фразу для добавления")}
                    handleDrag={() => (tag, currPos, newPos) => {
                      const newTags = tagsDesc.slice();

                      newTags.splice(currPos, 1);
                      newTags.splice(newPos, 0, tag);

                      // re-render
                      setTagsDesc(newTags);
                    }}
                    handleTagClick={() => {}}
                    inputFieldPosition="bottom"
                    autocomplete={false}
                    classNames={{
                      tags: "tagsClass",
                      tagInput: "tagInputClass",
                      tagInputField: "form-control",
                      selected: "selectedClass",
                      tag: "tagClass",
                      remove: "removeClass",
                      suggestions: "suggestionsClass",
                      activeSuggestion: "activeSuggestionClass",
                      editTagInput: "editTagInputClass",
                      editTagInputField: "editTagInputField",
                      clearAll: "clearAllClass",
                    }}
                  />
                </Col>
                <Col md={12} style={{ textAlign: "center", marginTop: 20 }}>
                  <Button
                    label={"Сохранить"}
                    loading={loading}
                    style={"btn btn-primary w-md"}
                    onClick={() => {
                      save({
                        variables: {
                          tags: JSON.stringify(tags),
                          tagsSearch: JSON.stringify(tagsSearch),
                          tagsTitle: JSON.stringify(tagsTitle),
                          tagsDesc: JSON.stringify(tagsDesc),
                          id: Number(searchParams.get("id")),
                        },
                      })
                        .then((response) => {
                          toast.success(t("Настройки сохранены"));
                        })
                        .catch((error) => {
                          toast.error(error.message);
                        });
                    }}
                  />
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
