import React, { useEffect, useState } from "react";
import { Wrapper } from "../components/Wrapper";
import { PageTitle } from "../components/PageTitle";
import { CustomTable } from "../components/CustomTable";
import { useNavigate } from "react-router-dom";
import { Notification } from "../utils/Notification";
import { useMutation, useQuery } from "@apollo/client";
import { Preload } from "../components/Preload";
import { SIZE_FOOTER_MENU } from "../utils/Dictionaries";
import { GET_FOOTER_MENU } from "../graphql/Queries/FooterMenuQuery";
import { DELETE_FOOTER_MENU } from "../graphql/Mutations/FooterMenuMutation";

export const FooterMenu = ({ url }) => {
  const [items, setItems] = useState([]);
  const navigator = useNavigate();
  const { loading, error, data } = useQuery(GET_FOOTER_MENU, {
    fetchPolicy: "no-cache",
  });
  const [drop] = useMutation(DELETE_FOOTER_MENU);

  const remove = (id) => {
    drop({
      variables: {
        id: Number(id),
      },
    })
      .then((response) => {
        setItems(response.data.deleteFooterMenu);
      })
      .catch((error) => {
        Notification(error);
      });
  };

  const edit = (id) => {
    navigator(`${url}/form?id=${id}`);
  };

  useEffect(() => {
    if (data) {
      setItems(data.footerMenu);
    }
  }, [data]);

  if (!data) {
    return <Preload />;
  }

  return (
    <Wrapper>
      <PageTitle title={"Меню в футере"} />
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <CustomTable
                data={items}
                columns={[
                  { key: "id", label: "ID" },
                  { key: "title", label: "Название" },
                  {
                    key: "size",
                    label: "Размер",
                    callback: (value) => {
                      const data = SIZE_FOOTER_MENU.find((type) => {
                        return type.id === value;
                      });
                      return data.name;
                    },
                  },
                ]}
                search={true}
                sort={true}
                pagination={{
                  limit: 10,
                }}
                buttons={[
                  {
                    onClick: (row) => {
                      navigator(`${url}/links?footer_menu_id=${row.id}`);
                    },
                    icon: "mdi mdi-link-variant",
                    title: "Добавление ссылок",
                  },
                ]}
                actions={[
                  {
                    slug: `${url}/form`,
                    icon: "fe-plus",
                    name: "Создать",
                  },
                ]}
                onClick={(id) => edit(id)}
                onDelete={(id) => remove(id)}
              />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
