import React, { useEffect, useState } from "react";
import { Wrapper } from "../components/Wrapper";
import { PageTitle } from "../components/PageTitle";
import { CustomTable } from "../components/CustomTable";
import { useNavigate } from "react-router-dom";
import { Notification } from "../utils/Notification";
import { useMutation, useQuery } from "@apollo/client";
import { GET_AREAS } from "../graphql/Queries/AreaQuery";
import { DELETE_AREA } from "../graphql/Mutations/AreaMutation";
import i18next from "i18next";

export const Areas = ({ url }) => {
  const [items, setItems] = useState([]);
  const navigator = useNavigate();

  const { data } = useQuery(GET_AREAS, {
    fetchPolicy: "no-cache",
  });
  const [removeCity] = useMutation(DELETE_AREA);

  useEffect(() => {
    if (data) {
      setItems(data.areas);
    }
  }, [data]);

  const remove = (id) => {
    removeCity({
      variables: {
        id: Number(id),
      },
    })
      .then((response) => {
        setItems(response.data.deleteArea);
      })
      .catch((error) => {
        Notification(error);
      });
  };

  const edit = (id) => {
    navigator(`${url}/form?id=${id}`);
  };

  return (
    <Wrapper>
      <PageTitle title={"Районы"} />
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <CustomTable
                data={items}
                columns={[
                  { key: "id", label: "ID" },
                  { key: "name", label: "Название" },
                  {
                    key: "city",
                    label: "Город",
                    callback: (city) => {
                      const c = city?.name.find((item) => {
                        return item.lang === i18next.language;
                      });
                      return c?.value;
                    },
                    sort: "city.name",
                  },
                ]}
                search={true}
                sort={true}
                pagination={{
                  limit: 10,
                }}
                actions={[
                  {
                    slug: `${url}/form`,
                    icon: "fe-plus",
                    name: "Создать",
                  },
                ]}
                onClick={(id) => edit(id)}
                onDelete={(id) => remove(id)}
              />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
