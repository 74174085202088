import styles from "./Piccer.module.scss";
import { useCallback, useEffect, useRef, useState } from "react";
import Cropper from "react-easy-crop";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import axios from "axios";

const Piccer = ({ data, onComplete }) => {
  const [file, setFile] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [activeTab, setActiveTab] = useState(null);
  const [cropArea, setCropArea] = useState(null);
  const [quality, setQuality] = useState(1);
  const [format, setFormat] = useState("image/jpg");
  const [contrast, setContrast] = useState(1);
  const [brightness, setBrightness] = useState(1);
  const [grayscale, setGrayscale] = useState(0);
  const [opacity, setOpacity] = useState(1);
  const [originalFile, setOriginalFile] = useState(null);
  const inputElement = useRef(null);
  const { t } = useTranslation();
  const [resurseUrl, setResurseUrl] = useState("");

  useEffect(() => {
    if (data) {
      if (data.indexOf("https://") !== -1) {
        toDataURL(data).then((res) => {
          getSize(res);
        });
      } else {
        getSize(data);
      }
    }
  }, [data]);

  const toDataURL = (url) => {
    return new Promise((resolve, reject) => {
      let xhr = new XMLHttpRequest();
      xhr.onload = function () {
        let reader = new FileReader();
        reader.onloadend = function () {
          resolve(reader.result);
        };
        reader.onerror = function (error) {
          reject(error);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.onerror = function (error) {
        reject(error);
      };
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.send();
    });
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCropArea(croppedAreaPixels);
  }, []);

  function handleFiles() {
    Array.from(this.files).forEach((file) => {
      getBase64(file);
    });
  }
  const getBase64 = (file) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      if (file.type === "image/svg+xml") {
        onComplete(reader.result);
      } else {
        getSize(reader.result);
      }
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };
  useEffect(() => {
    // const inputElement = document.getElementById("input");
    // inputElement.addEventListener("change", handleFiles, false);
    if (inputElement.current) {
      inputElement.current.addEventListener("change", handleFiles, false);
    }
    return () => {
      if (inputElement.current) {
        inputElement.current.removeEventListener("change", handleFiles, false);
      }
    };
  }, []);

  const getSize = (data) => {
    let img = new Image();
    img.src = data;
    img.onload = function () {
      let canvas = document.createElement("canvas");
      let ctx = canvas.getContext("2d");
      const _file = { ...file };
      _file.size = {
        width: img.width,
        height: img.height,
        ratio: img.width / img.height,
      };
      _file.source = data;
      setFile(_file);
    };
  };

  const cropFunc = () => {
    let img = new Image();
    img.src = file.source;
    img.onload = function () {
      let canvas = document.createElement("canvas");
      let ctx = canvas.getContext("2d");
      let width = img.width;
      let height = img.height;

      // Встановіть бажані розміри
      canvas.width = width; // нова ширина
      canvas.height = height; // нова висота

      // Намалюйте зображення на canvas
      ctx.drawImage(
        img,
        cropArea.x,
        cropArea.y,
        cropArea.width,
        cropArea.height,
        0,
        0,
        canvas.width,
        canvas.height,
      );
      let resizedImage = canvas.toDataURL();
      setActiveTab(null);
      const _file = { ...file };
      _file.source = resizedImage;
      setFile(_file);
    };
  };

  const changeQuality = (q) => {
    setQuality(q);
    let img = new Image();
    img.src = file.source;
    img.onload = function () {
      let canvas = document.createElement("canvas");
      let ctx = canvas.getContext("2d");
      let width = img.width;
      let height = img.height;

      // Встановіть бажані розміри
      canvas.width = width; // нова ширина
      canvas.height = height; // нова висота

      // Намалюйте зображення на canvas
      ctx.drawImage(
        img,
        0,
        0,
        canvas.width,
        canvas.height,
        0,
        0,
        canvas.width,
        canvas.height,
      );
      let resizedImage = canvas.toDataURL("image/jpeg", q);
      const _file = { ...file };
      _file.source = resizedImage;
      setFile(_file);
    };
  };

  const changeSize = (type, value) => {
    const _file = { ...file };
    _file.size[type] = value;
    if (type === "width") {
      _file.size["height"] = Math.round(value / _file.size.ratio);
    } else {
      _file.size["width"] = Math.round(value * _file.size.ratio);
    }
    setFile(_file);
  };

  const settingDone = () => {
    let img = new Image();
    img.src = file.source;
    img.onload = function () {
      let canvas = document.createElement("canvas");
      let ctx = canvas.getContext("2d");
      let width = file.size.width;
      let height = file.size.height;

      // Встановіть бажані розміри
      canvas.width = width; // нова ширина
      canvas.height = height; // нова висота

      // Намалюйте зображення на canvas
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      let resizedImage = canvas.toDataURL(format, quality);
      setActiveTab(null);
      const _file = { ...file };
      _file.source = resizedImage;
      setFile(_file);
    };
  };

  const changeFilter = (param, value) => {
    let img = new Image();
    if (originalFile === null) {
      setOriginalFile(file);
      img.src = file.source;
    } else {
      img.src = originalFile.source;
    }
    img.onload = function () {
      let canvas = document.createElement("canvas");
      let ctx = canvas.getContext("2d");
      let width = img.width;
      let height = img.height;

      // Встановіть бажані розміри
      canvas.width = width; // нова ширина
      canvas.height = height; // нова висота
      switch (param) {
        case "contrast":
          setContrast(value);
          break;

        case "brightness":
          setBrightness(value);
          break;

        case "grayscale":
          setGrayscale(value);
          break;

        case "opacity":
          setOpacity(value);
          break;
      }
      ctx.filter =
        "contrast(" +
        contrast * 100 +
        "%) brightness(" +
        brightness * 100 +
        "%) grayscale(" +
        grayscale * 100 +
        "%) opacity(" +
        opacity * 100 +
        "%)";
      // Намалюйте зображення на canvas
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      let resizedImage = canvas.toDataURL();
      const _file = { ...file };
      _file.source = resizedImage;
      setFile(_file);
    };
  };

  const done = () => {
    onComplete(file);
    setFile(null);
    inputElement.current.value = "";
  };

  const downloadFile = () => {
    if (resurseUrl === "") {
      toast.error(t("Укажите ссылку на файл"));
    } else {
      axios
        .get("https://source.city.az/copy.php", {
          params: {
            url: resurseUrl,
          },
        })
        .then((response) => {
          onComplete(response.data);
          setResurseUrl("");
        })
        .catch((error) => {
          toast.error(error.message);
        });
    }
  };

  return (
    <div className={styles.upload}>
      <div className={styles.uploadControl}>
        <input type={"file"} ref={inputElement} />
        <div className={styles.linkCopy}>
          {t("или вставьте ссылку")}
          <input
            type={"text"}
            className={"form-control " + styles.urlLink}
            value={resurseUrl}
            onChange={(event) => setResurseUrl(event.target.value)}
          />
          <button
            onClick={downloadFile}
            type={"button"}
            className={styles.urlButton}
          >
            {t("загрузить")}
          </button>
        </div>
      </div>
      {file && (
        <div className={styles.container}>
          <div className={styles.picture}>
            {activeTab !== "crop" && <img src={file.source} />}
            {activeTab === "crop" && (
              <Cropper
                image={file.source}
                crop={crop}
                zoom={zoom}
                aspect={4 / 3}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
              />
            )}
          </div>
          <div className={styles.panel}>
            <ul>
              <li
                className={activeTab === "crop" ? styles.active : {}}
                onClick={() => setActiveTab("crop")}
              >
                Crop
              </li>
              <li
                className={activeTab === "filter" ? styles.active : {}}
                onClick={() => setActiveTab("filter")}
              >
                Filter
              </li>
              <li
                className={activeTab === "setting" ? styles.active : {}}
                onClick={() => setActiveTab("setting")}
              >
                Setting
              </li>
            </ul>
            {!activeTab && (
              <span className={styles.successBtn} onClick={() => done()}>
                <i className={"fe-check"} /> Сохранить
              </span>
            )}
            {activeTab === "crop" && (
              <div className={styles.tabContent}>
                <div className={styles.footer}>
                  <span className={styles.btn} onClick={() => cropFunc()}>
                    Save crop
                  </span>
                  <span
                    className={styles.btnCancel}
                    onClick={() => {
                      setActiveTab(null);
                    }}
                  >
                    Cancel
                  </span>
                </div>
              </div>
            )}
            {activeTab === "filter" && (
              <div className={styles.tabContent}>
                <div className={styles.formGroup}>
                  <label>Контрастность: {contrast}</label>
                  <input
                    type={"range"}
                    max={2}
                    min={0}
                    step={0.1}
                    value={contrast}
                    onChange={(event) =>
                      changeFilter("contrast", event.target.value)
                    }
                  />
                </div>
                <div className={styles.formGroup}>
                  <label>Яркость: {brightness}</label>
                  <input
                    type={"range"}
                    max={2}
                    min={0}
                    step={0.1}
                    value={brightness}
                    onChange={(event) =>
                      changeFilter("brightness", event.target.value)
                    }
                  />
                </div>
                <div className={styles.formGroup}>
                  <label>Оттенки серого: {grayscale}</label>
                  <input
                    type={"range"}
                    max={1}
                    min={0}
                    step={0.1}
                    value={grayscale}
                    onChange={(event) =>
                      changeFilter("grayscale", event.target.value)
                    }
                  />
                </div>
                <div className={styles.formGroup}>
                  <label>Прозрачность: {opacity}</label>
                  <input
                    type={"range"}
                    max={1}
                    min={0}
                    step={0.1}
                    value={opacity}
                    onChange={(event) =>
                      changeFilter("opacity", event.target.value)
                    }
                  />
                </div>
                <div className={styles.footer}>
                  <span className={styles.btn} onClick={() => cropFunc()}>
                    Save filter
                  </span>
                  <span
                    className={styles.btnCancel}
                    onClick={() => {
                      setActiveTab(null);
                    }}
                  >
                    Cancel
                  </span>
                </div>
              </div>
            )}
            {activeTab === "setting" && (
              <div className={styles.tabContent}>
                <div className={styles.formGroup}>
                  <label>Качество фото: {quality}</label>
                  <input
                    type={"range"}
                    max={1}
                    min={0}
                    step={0.1}
                    value={quality}
                    onChange={(event) => changeQuality(event.target.value)}
                  />
                </div>
                <div className={styles.formGroup}>
                  <label>Формат:</label>
                  <select
                    onChange={(event) => setFormat(event.target.value)}
                    defaultValue={format}
                  >
                    <option value={"image/jpg"}>JPG</option>
                    <option value={"image/webp"}>WEBP</option>
                  </select>
                </div>
                <div className={styles.formGroup}>
                  <label>Размер:</label>
                  <div className={styles.doubleInput}>
                    <input
                      type={"text"}
                      value={file.size.width}
                      onChange={(event) =>
                        changeSize("width", event.target.value)
                      }
                    />
                    <span>x</span>
                    <input
                      type={"text"}
                      value={file.size.height}
                      onChange={(event) =>
                        changeSize("height", event.target.value)
                      }
                    />
                  </div>
                </div>
                <div className={styles.footer}>
                  <span className={styles.btn} onClick={() => settingDone()}>
                    Save setting
                  </span>
                  <span
                    className={styles.btnCancel}
                    onClick={() => {
                      setActiveTab(null);
                    }}
                  >
                    Cancel
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Piccer;
