import React, { useEffect, useState } from "react";
import { Wrapper } from "../../components/Wrapper";
import { PageTitle } from "../../components/PageTitle";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Col, Form } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { Preload } from "../../components/Preload";
import { THUMB_PROPS } from "../../graphql/Mutations/ObjectTypeMutation";
import { GET_LIST_PROPERTIES } from "../../graphql/Queries/PropertyQuery";
import i18next from "i18next";
import { Button } from "../../components/Button";
import { SortableContainer, SortableElement } from "react-sortable-hoc";

export const ThumbProps = ({ id = null }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const [save] = useMutation(THUMB_PROPS);
  const { load, error, data } = useQuery(GET_LIST_PROPERTIES, {
    variables: {
      lang: i18next.language,
      id: Number(searchParams.get("id")),
    },
    fetchPolicy: "no-cache",
  });
  if (error) {
    toast.error(error.message);
  }
  const [props, setProps] = useState([]);
  const [arr, setArr] = useState([]);

  const SortableItem = SortableElement(({ item }) => {
    return (
      <Form.Check
        key={item.id}
        type="switch"
        checked={arr.includes(item.id)}
        value={arr.includes(item.id)}
        label={item.nameString}
        onChange={() => {
          if (arr.includes(item.id)) {
            const _arr = [...arr];
            _arr.splice(_arr.indexOf(item.id), 1);
            setArr(_arr);
          } else {
            setArr([...arr, item.id]);
          }
        }}
      />
    );
  });

  const SortableList = SortableContainer(({ items }) => {
    return (
      <di>
        {items.map((item, index) => {
          return (
            <SortableItem key={`item-${index}`} index={index} item={item} />
          );
        })}
      </di>
    );
  });

  const compare = (a, b) => {
    if (
      Number(a.sort_in_thumb.sort_in_thumb) >
      Number(b.sort_in_thumb.sort_in_thumb)
    ) {
      return -1;
    }
    if (
      Number(a.sort_in_thumb.sort_in_thumb) <
      Number(b.sort_in_thumb.sort_in_thumb)
    ) {
      return 1;
    }
    return 0;
  };

  useEffect(() => {
    if (data) {
      const isThumb = [];
      data.property.map((e) => {
        if (Number(e.is_thumb) === 1) {
          isThumb.push(e.id);
        }
      });
      setArr(isThumb);
      const _sort = [...data.property];
      _sort.sort(compare);
      setProps(_sort);
    }
  }, [data]);

  if (!data) {
    return <Preload />;
  }

  return (
    <Wrapper>
      <PageTitle
        title={"Свойства для блока объявления"}
        breadcrumbs={[
          {
            slug: "/type-objects",
            name: "Типы объектов",
          },
        ]}
      />
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <Form>
                <SortableList
                  items={props}
                  onSortEnd={({
                    oldIndex,
                    newIndex,
                    collection,
                    isKeySorting,
                  }) => {
                    const _steps = [...props];
                    const el = _steps[oldIndex];
                    _steps.splice(oldIndex, 1);
                    _steps.splice(newIndex, 0, el);
                    setProps(_steps);
                    const _arr = [];
                    _steps.map((item, index) => {
                      if (Number(item.is_thumb) === 1) {
                        _arr.push(item.id);
                      }
                    });
                    setArr(_arr);
                  }}
                />
                <Col md={12} style={{ textAlign: "center", marginTop: 20 }}>
                  <Button
                    label={"Сохранить"}
                    loading={loading}
                    type={"button"}
                    style={"btn btn-primary w-md"}
                    onClick={() => {
                      console.log("[Log]", arr);
                      save({
                        variables: {
                          props: JSON.stringify(arr),
                          id: Number(searchParams.get("id")),
                        },
                      })
                        .then((response) => {
                          toast.success(t("Настройки сохранены"));
                        })
                        .catch((error) => {
                          toast.error(error.message);
                        });
                    }}
                  />
                </Col>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
