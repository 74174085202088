import React from "react";
import { useTranslation } from "react-i18next";
import { Tab, Tabs } from "react-bootstrap";

export const MultilangTextInput = ({
  name,
  label,
  value,
  onChange,
  onBlur,
  error = false,
  touched,
  type = "text",
  fullError = false,
}) => {
  const { t } = useTranslation();
  const languages = [
    {
      url: "az",
      label: t("Azerbaijan"),
    },
    {
      url: "ru",
      label: t("Русский"),
    },
    {
      url: "en",
      label: t("English"),
    },
  ];
  return (
    <Tabs defaultActiveKey={languages[0].url} className={"multilang-tab"}>
      {languages.map((lang) => {
        return (
          <Tab
            eventKey={lang.url}
            key={lang.url}
            title={lang.label}
            style={{ position: "relative" }}
          >
            <div className="form-floating mb-3">
              {type === "text" && (
                <input
                  type="text"
                  className={
                    error && touched && error[lang.url] !== undefined
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                  value={value[lang.url]}
                  onChange={(event) => onChange(event.target.value, lang.url)}
                  onBlur={onBlur}
                  placeholder={t(label)}
                  id={name + "_" + lang.url}
                />
              )}
              {type === "textarea" && (
                <textarea
                  style={{ height: 200 }}
                  className={
                    error && touched && error[lang.url] !== undefined
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                  onChange={(event) => onChange(event.target.value, lang.url)}
                  onBlur={onBlur}
                  placeholder={t(label)}
                  id={name + "_" + lang.url}
                  value={value[lang.url]}
                />
              )}
              <label htmlFor={name}>{t(label)}</label>
            </div>
            <div
              style={{
                position: "absolute",
                top: "100%",
                color: "#f1556c",
                fontSize: 12,
              }}
            >
              {fullError}
            </div>
          </Tab>
        );
      })}
    </Tabs>
  );
};
