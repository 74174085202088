import React from "react";
import { useTranslation } from "react-i18next";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export const TextInput = ({
  name,
  label,
  value,
  onChange,
  onBlur,
  error = false,
  touched,
  hint = false,
  readOnly = false,
  type = "input",
  boxClass = "mb-3",
}) => {
  const { t } = useTranslation();
  return (
    <div className={"form-floating " + boxClass}>
      {type === "input" && (
        <input
          type="text"
          className={
            error && touched ? "form-control is-invalid" : "form-control"
          }
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          placeholder={t(label)}
          id={name}
          readOnly={readOnly}
        />
      )}
      {type === "textarea" && (
        <textarea
          className={
            error && touched ? "form-control is-invalid" : "form-control"
          }
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          placeholder={t(label)}
          id={name}
          readOnly={readOnly}
          rows={5}
          style={{ height: 100 }}
        ></textarea>
      )}
      <label htmlFor={name}>{t(label)}</label>
      {hint && (
        <OverlayTrigger placement={"top"} overlay={<Tooltip>{hint}</Tooltip>}>
          <div
            className={"text-warning"}
            style={{ position: "absolute", fontSize: 20, right: 10, top: 14 }}
          >
            <i className="fe-alert-circle"></i>
          </div>
        </OverlayTrigger>
      )}
    </div>
  );
};
