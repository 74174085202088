import React, { useEffect, useState } from "react";
import { Wrapper } from "../../components/Wrapper";
import { PageTitle } from "../../components/PageTitle";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Col, Row } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import { gql, useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { Preload } from "../../components/Preload";
import i18next from "i18next";
import { Button } from "../../components/Button";
import { Selected } from "../../components/Form/Selected";
import { MODIFY_AROUND_PROPS } from "../../graphql/Mutations/EstateMutation";

export const PropsAround = ({ id = null }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const [save] = useMutation(MODIFY_AROUND_PROPS);
  const { load, error, data } = useQuery(
    gql`
      query ($lang: String!, $id: Int!) {
        property(object_type_id: $id) {
          id
          nameString(lang: $lang)
          is_thumb(object_type_id: $id)
        }
        estateAroundParam(object_type_id: $id) {
          property_id
          type
        }
      }
    `,
    {
      variables: {
        lang: i18next.language,
        id: Number(searchParams.get("id")),
      },
      fetchPolicy: "no-cache",
    },
  );
  const [propsRecommended, setPropsRecommended] = useState([]);
  const [propsAround, setPropsAround] = useState([]);
  const [propsArea, setPropsArea] = useState([]);
  const [propsMetro, setPropsMetro] = useState([]);

  useEffect(() => {
    if (data?.estateAroundParam) {
      data.estateAroundParam.map((item) => {
        switch (item.type) {
          case 1:
            setPropsRecommended([...propsRecommended, item.property_id]);
            break;
          case 2:
            setPropsAround([...propsAround, item.property_id]);
            break;
          case 3:
            setPropsArea([...propsArea, item.property_id]);
            break;
          case 4:
            setPropsMetro([...propsMetro, item.property_id]);
            break;
        }
      });
    }
  }, [data]);

  if (error) {
    toast.error(error.message);
  }

  if (!data) {
    return <Preload />;
  }

  return (
    <Wrapper>
      <PageTitle
        title={"Настройка свойств для похожих обявлений"}
        breadcrumbs={[
          {
            slug: "/type-objects",
            name: "Типы объектов",
          },
        ]}
      />
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <Row>
                <Col md={12}>
                  <Selected
                    label={"Свойства для рекомендуемых"}
                    staticOptions={data.property}
                    rows={{ value: "id", label: "nameString" }}
                    tags={true}
                    value={propsRecommended}
                    onChange={(value) => {
                      const data = value.map((val) => {
                        return val.value;
                      });
                      setPropsRecommended(data);
                    }}
                    style={{ paddingRight: 0 }}
                  />
                  <Selected
                    label={"Свойства для похожих рядом"}
                    staticOptions={data.property}
                    rows={{ value: "id", label: "nameString" }}
                    tags={true}
                    value={propsAround}
                    onChange={(value) => {
                      const data = value.map((val) => {
                        return val.value;
                      });
                      setPropsAround(data);
                    }}
                    style={{ paddingRight: 0 }}
                  />
                  <Selected
                    label={"Свойства для поиска по району"}
                    staticOptions={data.property}
                    rows={{ value: "id", label: "nameString" }}
                    tags={true}
                    value={propsArea}
                    onChange={(value) => {
                      const data = value.map((val) => {
                        return val.value;
                      });
                      setPropsArea(data);
                    }}
                    style={{ paddingRight: 0 }}
                  />
                  <Selected
                    label={"Свойства для поиска по метро"}
                    staticOptions={data.property}
                    rows={{ value: "id", label: "nameString" }}
                    tags={true}
                    value={propsMetro}
                    onChange={(value) => {
                      const data = value.map((val) => {
                        return val.value;
                      });
                      setPropsMetro(data);
                    }}
                    style={{ paddingRight: 0 }}
                  />
                </Col>
                <Col md={12} style={{ textAlign: "center", marginTop: 20 }}>
                  <Button
                    label={"Сохранить"}
                    loading={loading}
                    style={"btn btn-primary w-md"}
                    onClick={() => {
                      save({
                        variables: {
                          object_type_id: Number(searchParams.get("id")),
                          recommendedProps: JSON.stringify(propsRecommended),
                          aroundProps: JSON.stringify(propsAround),
                          areaProps: JSON.stringify(propsArea),
                          metroProps: JSON.stringify(propsMetro),
                        },
                      })
                        .then((response) => {
                          toast.success(t("Настройки сохранены"));
                        })
                        .catch((error) => {
                          toast.error(error.message);
                        });
                    }}
                  />
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
