import { gql } from "@apollo/client";

export const GET_AGENT_REQUESTS = gql`
  query ($lang: String!) {
    agentRequest {
      id
      user {
        id
        email
        phone
      }
      status
      created_at
    }
    list(method: "AgentRequest::listStatus", lang: $lang) {
      id
      name
    }
  }
`;

export const GET_AGENT_REQUEST = gql`
  query ($lang: String!, $id: Int!) {
    agentRequest(id: $id) {
      id
      user {
        id
        email
        phone
        name
      }
      status
      comment
      created_at
    }
    list(method: "AgentRequest::listStatus", lang: $lang) {
      id
      name
    }
  }
`;
