import React, { useEffect, useState } from "react";
import { Wrapper } from "../../components/Wrapper";
import { PageTitle } from "../../components/PageTitle";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Notification } from "../../utils/Notification";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Button } from "../../components/Button";
import { MultilangTextInput } from "../../components/Form/MultilangTextInput";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import i18next from "i18next";
import { Preload } from "../../components/Preload";
import { GET_FAST_MENU_ONE } from "../../graphql/Queries/FastMenuQuery";
import { MODIFY_FAST_MENU } from "../../graphql/Mutations/FastMenuMutation";
import { Selected } from "../../components/Form/Selected";
import { STATUS, TYPE_FAST_LINK } from "../../utils/Dictionaries";
import { TextInput } from "../../components/Form/TextInput";
import { FileInput } from "../../components/Form/FileInput";

export const FastLinksForm = ({ id = null }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [value, setValue] = useState({ ru: "", en: "", az: "" });
  const [key, setKey] = useState("");
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const [modifyFastMenu] = useMutation(MODIFY_FAST_MENU);
  const { load, error, data } = useQuery(GET_FAST_MENU_ONE, {
    variables: {
      id: Number(searchParams.get("id")),
    },
    fetchPolicy: "no-cache",
  });
  if (error) {
    toast.error(error.message);
  }
  const languages = [
    {
      url: "ru",
      label: t("Ру"),
    },
    {
      url: "az",
      label: t("Az"),
    },
    {
      url: "en",
      label: t("En"),
    },
  ];
  const defaultLang = i18next.language;
  const formik = useFormik({
    initialValues: {
      id: 0,
      title: {
        ru: "",
        az: "",
        en: "",
      },
      url: {
        ru: "",
        az: "",
        en: "",
      },
      type: 1,
      sort: 1,
      color: "",
      color_hover: "",
      status: 1,
      icon: "",
    },
    validationSchema: Yup.object({
      title: Yup.object().shape({
        ru: Yup.string().required(t("Объязательное поле")),
      }),
      url: Yup.object().shape({
        ru: Yup.string().required(t("Объязательное поле")),
      }),
      sort: Yup.string().required(t("Не может быть пустым")),
    }),
    onSubmit: (values) => {
      setLoading(true);
      modifyFastMenu({
        variables: {
          id: Number(values.id),
          title: JSON.stringify(values.title),
          url: JSON.stringify(values.url),
          sort: values.sort,
          type: values.type,
          color: values.color,
          color_hover: values.color_hover,
          status: values.status,
          icon: values.icon,
        },
      })
        .then((response) => {
          navigate("/fast-links");
          Notification(t("Все хорошо!"), "success");
        })
        .catch((error) => {
          Notification(error);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  useEffect(() => {
    if (data && data.fastMenu.length > 0) {
      data.fastMenu[0].title.map((dic) => {
        formik.setFieldValue(`title.${dic.lang}`, dic.value);
      });
      data.fastMenu[0].url.map((dic) => {
        formik.setFieldValue(`url.${dic.lang}`, dic.value);
      });
      formik.setFieldValue("id", data.fastMenu[0].id);
      formik.setFieldValue("sort", data.fastMenu[0].sort);
      formik.setFieldValue("status", data.fastMenu[0].status);
      formik.setFieldValue("color", data.fastMenu[0].color);
      formik.setFieldValue("color_hover", data.fastMenu[0].color_hover);
      if (data.fastMenu[0].icon) {
        const icons = JSON.parse(data.fastMenu[0].icon);
        formik.setFieldValue("icon", icons[0]);
      }
      formik.setFieldValue("type", data.fastMenu[0].type);
    }
  }, [data]);

  if (!data) {
    return <Preload />;
  }

  return (
    <Wrapper>
      <PageTitle
        title={
          id === null
            ? "Добавить быструю ссылку"
            : "Редактировать быструю ссылку"
        }
        breadcrumbs={[
          {
            slug: "/dictionaries",
            name: "Быстрые ссылки",
          },
        ]}
      />
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <form className="row" onSubmit={formik.handleSubmit}>
                <div className="col-md-6">
                  <MultilangTextInput
                    label={"Название"}
                    value={formik.values.title}
                    onChange={(text, lang) => {
                      formik.setFieldValue(`title.${lang}`, text);
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.errors.title}
                    touched={formik.touched.title}
                    name={"title"}
                  />
                </div>
                <div className="col-md-6">
                  <MultilangTextInput
                    label={"Ссылка"}
                    value={formik.values.url}
                    onChange={(text, lang) => {
                      formik.setFieldValue(`url.${lang}`, text);
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.errors.url}
                    touched={formik.touched.url}
                    name={"url"}
                  />
                </div>
                <div className="col-md-6">
                  <Selected
                    label={"Тип"}
                    onChange={(value) => {
                      formik.setFieldValue(`type`, value);
                    }}
                    error={formik.errors.type}
                    touched={formik.touched.type}
                    name={"type"}
                    staticOptions={TYPE_FAST_LINK}
                    style={{ paddingRight: 0 }}
                    value={formik.values.type}
                  />
                </div>
                <div className="col-md-6">
                  <Selected
                    label={"Состояние"}
                    onChange={(value) => {
                      formik.setFieldValue(`status`, value);
                    }}
                    error={formik.errors.status}
                    touched={formik.touched.status}
                    name={"position"}
                    staticOptions={STATUS}
                    style={{ paddingRight: 0 }}
                    value={formik.values.status}
                  />
                </div>
                <div className="col-md-6">
                  <TextInput
                    label={"Порядок вывода"}
                    value={formik.values.sort}
                    onChange={(text, lang) => {
                      formik.setFieldValue(`sort`, text);
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.errors.sort}
                    touched={formik.touched.sort}
                    name={"sort"}
                  />
                </div>
                <div className="col-md-6">
                  <TextInput
                    label={"Цвет фона"}
                    value={formik.values.color}
                    onChange={(text) => {
                      formik.setFieldValue(`color`, text.target.value);
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.errors.color}
                    touched={formik.touched.color}
                    name={"color"}
                  />
                </div>
                <div className="col-md-6">
                  <TextInput
                    label={"Цвет фона при наведении"}
                    value={formik.values.color_hover}
                    onChange={(text) => {
                      formik.setFieldValue(`color_hover`, text.target.value);
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.errors.color_hover}
                    touched={formik.touched.color_hover}
                    name={"color_hover"}
                  />
                </div>
                <div className="col-md-12">
                  <FileInput
                    label={"Иконка"}
                    onChange={(file, lang) => {
                      formik.setFieldValue(`icon`, file);
                    }}
                    value={formik.values.icon}
                    multilang={false}
                  />
                </div>
                <div className="col-md-12 text-center">
                  <Button
                    label={"Сохранить"}
                    loading={loading}
                    style={"btn btn-primary w-md"}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
