import React, { useEffect, useState } from "react";
import { Wrapper } from "../components/Wrapper";
import { PageTitle } from "../components/PageTitle";
import { CustomTable } from "../components/CustomTable";
import { useNavigate } from "react-router-dom";
import { Notification } from "../utils/Notification";
import { useMutation, useQuery } from "@apollo/client";
import { GET_AREAS } from "../graphql/Queries/AreaQuery";
import { DELETE_AREA } from "../graphql/Mutations/AreaMutation";
import i18next from "i18next";
import { FAQ_PAGE, finder } from "../utils/Dictionaries";
import { GET_FAQ_TABS } from "../graphql/Queries/FaqQuery";
import { getField } from "../utils/Helper";
import { DELETE_FAQ_TAB } from "../graphql/Mutations/FaqMutation";

export const FaqTabs = ({ url }) => {
  const [items, setItems] = useState([]);
  const navigator = useNavigate();

  const { data } = useQuery(GET_FAQ_TABS, {
    fetchPolicy: "no-cache",
  });
  const [drop] = useMutation(DELETE_FAQ_TAB);

  useEffect(() => {
    if (data) {
      setItems(data.faqTabs);
    }
  }, [data]);

  const remove = (id) => {
    drop({
      variables: {
        id: Number(id),
      },
    })
      .then((response) => {
        setItems(response.data.deleteFaqTab);
      })
      .catch((error) => {
        Notification(error);
      });
  };

  const edit = (id) => {
    navigator(`${url}/form?id=${id}`);
  };

  return (
    <Wrapper>
      <PageTitle title={"Группы для FAQ"} />
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <CustomTable
                data={items}
                columns={[
                  { key: "id", label: "ID" },
                  {
                    key: `name`,
                    label: "Название",
                    callback: (item) => {
                      return getField(item, "value");
                    },
                  },
                  {
                    key: "page",
                    label: "Где выводить",
                    callback: (item) => {
                      return finder(FAQ_PAGE, item);
                    },
                    list: FAQ_PAGE,
                    listProp: {
                      value: "id",
                      label: "name",
                    },
                  },
                ]}
                search={true}
                sort={true}
                pagination={{
                  limit: 10,
                }}
                actions={[
                  {
                    slug: `${url}/form`,
                    icon: "fe-plus",
                    name: "Создать",
                  },
                ]}
                onClick={(id) => edit(id)}
                onDelete={(id) => remove(id)}
              />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
