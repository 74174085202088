import React, { useEffect, useState } from "react";
import { Wrapper } from "../../components/Wrapper";
import { PageTitle } from "../../components/PageTitle";
import { useFormik } from "formik";
import { Notification } from "../../utils/Notification";
import { useTranslation } from "react-i18next";
import { Button } from "../../components/Button";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Preload } from "../../components/Preload";
import { Selected } from "../../components/Form/Selected";
import { useMutation, useQuery } from "@apollo/client";
import i18next from "i18next";
import { TextInput } from "../../components/Form/TextInput";
import { GET_AGENT_REQUEST } from "../../graphql/Queries/AgentRequestQuery";
import { MODIFY_AGENT_REQUEST } from "../../graphql/Mutations/AgentRequestMutation";

export const AgentRequestForm = ({ url }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  let location = useLocation();
  const id = location.state ? location.state.id : null;
  let [searchParams, setSearchParams] = useSearchParams();
  const [modify] = useMutation(MODIFY_AGENT_REQUEST);
  const { data } = useQuery(GET_AGENT_REQUEST, {
    variables: {
      id: Number(searchParams.get("id")),
      lang: i18next.language,
    },
    fetchPolicy: "no-cache",
  });
  const formik = useFormik({
    initialValues: {
      id: 0,
      user: {},
      status: null,
      comment: "",
      created_at: null,
    },
    onSubmit: (values) => {
      setLoading(true);
      modify({
        variables: {
          id: Number(values.id),
          status: Number(values.status),
          comment: values.comment,
        },
      })
        .then((response) => {
          navigate(url);
          Notification(t("Все хорошо!"), "success");
        })
        .catch((error) => {
          Notification(error);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  useEffect(() => {
    if (data && data.agentRequest[0]) {
      formik.setFieldValue("id", data.agentRequest[0].id);
      formik.setFieldValue("created_at", data.agentRequest[0].created_at);
      formik.setFieldValue("user", data.agentRequest[0].user);
      formik.setFieldValue("status", String(data.agentRequest[0].status));
      formik.setFieldValue("comment", data.agentRequest[0].comment);
    }
  }, [data]);

  if (!data) {
    return <Preload />;
  }

  return (
    <Wrapper>
      <PageTitle
        title={"Просмотр запроса"}
        breadcrumbs={[
          {
            slug: url,
            name: "Запросы на агента",
          },
        ]}
      />
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <form className="row" onSubmit={formik.handleSubmit}>
                <div className="col-md-6">
                  <div className="table-responsive mb-2">
                    <table className="table table-centered border mb-0">
                      <thead className="bg-light">
                        <tr>
                          <th colSpan="2">{t("Данные обращения")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">ID:</th>
                          <td>{formik.values.id}</td>
                        </tr>
                        <tr>
                          <th scope="row">{t("Пользователь")}:</th>
                          <td>
                            <div>ID: {formik.values.user.id}</div>
                            <div>Email: {formik.values.user.email}</div>
                            <div>
                              {t("Имя")}: {formik.values.user.name}
                            </div>
                            <div>
                              {t("Телефон")}: {formik.values.user.phone}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">{t("Дата и время")}:</th>
                          <td>{formik.values.created_at}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="col-md-6">
                  <Selected
                    label={"Состояние"}
                    onChange={(value) => {
                      formik.setFieldValue(`status`, value);
                    }}
                    error={formik.errors.status}
                    name={"status"}
                    staticOptions={data?.list}
                    value={formik.values.status}
                    style={{ paddingRight: 0 }}
                  />
                  <TextInput
                    label={"Комментарий"}
                    value={formik.values.comment}
                    onChange={(event) => {
                      formik.setFieldValue(`comment`, event.target.value);
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.errors.comment}
                    touched={formik.touched.comment}
                    name={"comment"}
                  />
                </div>
                <div className="col-md-12 text-center">
                  <Button
                    label={"Сохранить"}
                    loading={loading}
                    style={"btn btn-primary w-md"}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
