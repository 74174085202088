import React, { useEffect, useState } from "react";
import { Wrapper } from "../components/Wrapper";
import { PageTitle } from "../components/PageTitle";
import { CustomTable } from "../components/CustomTable";
import { useNavigate } from "react-router-dom";
import { Notification } from "../utils/Notification";
import { useMutation, useQuery } from "@apollo/client";
import { GET_COMPLAINTS } from "../graphql/Queries/ComplaintQuery";
import { DELETE_COMPLAINT } from "../graphql/Mutations/ComplaintMutation";
import { finder, STATUS_COMPLAINT } from "../utils/Dictionaries";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setBadge } from "../redux/slices/badgeSlice";

export const Complaint = ({ url }) => {
  const [items, setItems] = useState([]);
  const navigator = useNavigate();
  const { t } = useTranslation();
  const { data } = useQuery(GET_COMPLAINTS, {
    fetchPolicy: "no-cache",
  });
  const [drop] = useMutation(DELETE_COMPLAINT);
  const dispatch = useDispatch();

  useEffect(() => {
    if (data) {
      setItems(data.complaint);
      const badge = JSON.parse(data.badge);
      dispatch(setBadge(badge));
    }
  }, [data]);

  const remove = (id) => {
    drop({
      variables: {
        id: Number(id),
      },
    })
      .then((response) => {
        setItems(response.data.deleteComplaint);
      })
      .catch((error) => {
        Notification(error);
      });
  };

  const edit = (id) => {
    navigator(`${url}/form?id=${id}`);
  };

  return (
    <Wrapper>
      <PageTitle title={"Жалобы"} />
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <CustomTable
                data={items}
                columns={[
                  { key: "id", label: "ID" },
                  {
                    key: `created_at`,
                    label: "Дата",
                    callback: (item) => {
                      return moment(item).format("HH:mm DD.MM.YYYY");
                    },
                  },
                  {
                    key: `estate`,
                    label: "Объявление",
                    callback: (item) => {
                      return item !== null ? item.title : t("удалено");
                    },
                  },
                  {
                    key: "status",
                    label: "Состояние",
                    callback: (item) => {
                      return finder(STATUS_COMPLAINT, item);
                    },
                    list: STATUS_COMPLAINT,
                    listProp: {
                      value: "id",
                      label: "name",
                    },
                  },
                  {
                    key: "text",
                    label: "Сообщение",
                  },
                ]}
                search={true}
                sort={true}
                pagination={{
                  limit: 10,
                }}
                actions={[
                  {
                    slug: `${url}/form`,
                    icon: "fe-plus",
                    name: "Создать",
                  },
                ]}
                onClick={(id) => edit(id)}
                onDelete={(id) => remove(id)}
              />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
