import React, { useEffect, useState } from "react";
import { Wrapper } from "../components/Wrapper";
import { PageTitle } from "../components/PageTitle";
import { Notification } from "../utils/Notification";
import { useMutation, useQuery } from "@apollo/client";
import { Preload } from "../components/Preload";
import { Form } from "react-bootstrap";
import { GET_HOME_MODULES } from "../graphql/Queries/HomeModuleQuery";
import { EDIT_HOME_MODULE } from "../graphql/Mutations/HomeModuleMutation";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export const HomeSetting = () => {
  const { loading, error, data } = useQuery(GET_HOME_MODULES, {
    fetchPolicy: "no-cache",
  });
  const [edit] = useMutation(EDIT_HOME_MODULE);
  const [modules, setModules] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (data && data.home_module) {
      setModules(data.home_module);
    }
  }, [data]);

  if (!data) {
    return <Preload />;
  }

  return (
    <Wrapper>
      <PageTitle title={"Настройка главной"} />
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <Form>
                {modules.map((item) => {
                  return (
                    <Form.Check
                      type="switch"
                      checked={item.is_enable === 1}
                      label={item.name}
                      onChange={() => {
                        edit({
                          variables: {
                            id: item.id,
                            is_enable: item.is_enable === 1 ? 2 : 1,
                          },
                        })
                          .catch((error) => {
                            Notification(error);
                          })
                          .then((res) => {
                            toast.success(t("Сохранено"));
                            setModules(res.data.updateHomeModule);
                          });
                      }}
                    />
                  );
                })}
              </Form>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
