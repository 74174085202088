import React, { useEffect, useState } from "react";
import { Wrapper } from "../components/Wrapper";
import { PageTitle } from "../components/PageTitle";
import { CustomTable } from "../components/CustomTable";
import { useNavigate } from "react-router-dom";
import { Notification } from "../utils/Notification";
import { useMutation, useQuery } from "@apollo/client";
import { GET_AREAS } from "../graphql/Queries/AreaQuery";
import { DELETE_AREA } from "../graphql/Mutations/AreaMutation";
import i18next from "i18next";
import { GET_NEWS } from "../graphql/Queries/NewsQuery";
import { DELETE_NEWS } from "../graphql/Mutations/NewsMutation";

export const News = ({ url }) => {
  const [items, setItems] = useState([]);
  const navigator = useNavigate();

  const { data } = useQuery(GET_NEWS, {
    fetchPolicy: "no-cache",
  });
  const [drop] = useMutation(DELETE_NEWS);

  useEffect(() => {
    if (data) {
      setItems(data.news);
    }
  }, [data]);

  const remove = (id) => {
    drop({
      variables: {
        id: Number(id),
      },
    })
      .then((response) => {
        setItems(response.data.deleteNews);
      })
      .catch((error) => {
        Notification(error);
      });
  };

  const edit = (id) => {
    navigator(`${url}/form?id=${id}`);
  };

  return (
    <Wrapper>
      <PageTitle title={"Новости"} />
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <CustomTable
                data={items}
                columns={[
                  { key: "id", label: "ID" },
                  { key: "title", label: "Название" },
                ]}
                search={true}
                sort={true}
                pagination={{
                  limit: 10,
                }}
                actions={[
                  {
                    slug: `${url}/form`,
                    icon: "fe-plus",
                    name: "Создать",
                  },
                ]}
                onClick={(id) => edit(id)}
                onDelete={(id) => remove(id)}
              />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
