import { Selected } from "../Form/Selected";
import { Button } from "../Button";
import { MultilangTextInput } from "../Form/MultilangTextInput";
import { useFormik } from "formik";
import * as Yup from "yup";
import { CountryFormComponent } from "./CountryForm";
import { Notification } from "../../utils/Notification";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { GET_STEPS } from "../../graphql/Queries/Steps";
import i18next from "i18next";
import {
  ADD_PROPERTY_GROUP,
  EDIT_PROPERTY_GROUP,
} from "../../graphql/Mutations/PropertyGroupMutation";
import { StepFormComponent } from "./StepFormComponent";
import { toast } from "react-toastify";

export const PropertyGroupForm = ({ onSuccess, onError, data }) => {
  const { t } = useTranslation();
  const [updatePropertyGroup] = useMutation(EDIT_PROPERTY_GROUP);
  const [addPropertyGroup] = useMutation(ADD_PROPERTY_GROUP);
  const steps = useQuery(GET_STEPS);
  const formik = useFormik({
    initialValues: {
      name: {
        ru: "",
        az: "",
        en: "",
      },
      name_system: {
        ru: "",
        az: "",
        en: "",
      },
      step_id: null,
      show_in_page: 2,
    },
    validationSchema: Yup.object({
      name: Yup.object().shape({
        ru: Yup.string().required(t("Объязательное поле")),
      }),
      step_id: Yup.number().required(t("Укажите шаг")),
    }),
    onSubmit: (values) => {
      setLoading(true);
      if (data) {
        updatePropertyGroup({
          variables: {
            name: JSON.stringify(values.name),
            name_system: JSON.stringify(values.name_system),
            step_id: Number(values.step_id),
            show_in_page: Number(values.show_in_page),
            id: Number(data.id),
          },
        })
          .then((response) => {
            onSuccess(response);
          })
          .catch((error) => {
            onError(error);
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        addPropertyGroup({
          variables: {
            name: JSON.stringify(values.name),
            name_system: JSON.stringify(values.name_system),
            step_id: Number(values.step_id),
            show_in_page: Number(values.show_in_page),
          },
        })
          .then((response) => {
            onSuccess(response);
          })
          .catch((error) => {
            onError(error);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    },
  });
  const [loading, setLoading] = useState(false);
  const [stepOptions, setStepOptions] = useState([]);

  useEffect(() => {
    if (data) {
      formik.setFieldValue("id", data.id);
      data.name.map((dic) => {
        formik.setFieldValue(`name.${dic.lang}`, dic.value);
      });
      data.name_system.map((dic) => {
        formik.setFieldValue(`name_system.${dic.lang}`, dic.value);
      });
      formik.setFieldValue("step_id", data.step.id);
      formik.setFieldValue("show_in_page", data.show_in_page);
    }
  }, [data]);

  useEffect(() => {
    if (steps.data) {
      // перетворення масиву
      const _arr = [];
      steps.data.steps.map((step) => {
        const name = step.name.find((n) => {
          return n.lang === i18next.language;
        });
        _arr.push({
          id: step.id,
          name: name.value,
        });
      });
      setStepOptions(_arr);
    }
  }, [steps]);

  if (!steps) {
    return null;
  }

  return (
    <form className="row" onSubmit={formik.handleSubmit}>
      <div className="col-md-6">
        <MultilangTextInput
          label={"Название"}
          value={formik.values.name}
          onChange={(text, lang) => {
            formik.setFieldValue(`name.${lang}`, text);
          }}
          onBlur={formik.handleBlur}
          error={formik.errors.name}
          name={"name"}
        />
      </div>
      <div className="col-md-6">
        <MultilangTextInput
          label={"Название (системное)"}
          value={formik.values.name_system}
          onChange={(text, lang) => {
            formik.setFieldValue(`name_system.${lang}`, text);
          }}
          onBlur={formik.handleBlur}
          error={formik.errors.name_system}
          name={"name_system"}
        />
      </div>
      <div className="col-md-6">
        <Selected
          label={"Шаг/блок"}
          onChange={(value) => {
            formik.setFieldValue(`step_id`, value);
          }}
          error={formik.errors.step_id}
          touched={formik.touched.step_id}
          name={"step_id"}
          staticOptions={stepOptions}
          modalForm={
            <StepFormComponent
              data={false}
              url={"/steps"}
              onSuccess={() => {
                toast.success(t("Шаг создан"));
              }}
            />
          }
          value={formik.values.step_id}
          onRefresh={() => steps.refetch()}
        />
      </div>
      <div className="col-md-6">
        <Selected
          label={"Показывать на странице объявления?"}
          onChange={(value) => {
            formik.setFieldValue(`show_in_page`, value);
          }}
          error={formik.errors.show_in_page}
          touched={formik.touched.show_in_page}
          name={"show_in_page"}
          staticOptions={[
            { id: 1, name: "да" },
            { id: 2, name: "нет" },
          ]}
          value={formik.values.show_in_page}
        />
      </div>
      <div className="col-md-12 text-center">
        <Button
          label={"Сохранить"}
          loading={loading}
          style={"btn btn-primary w-md"}
        />
      </div>
    </form>
  );
};
