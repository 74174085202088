import React, { useEffect, useState } from "react";
import { Wrapper } from "../components/Wrapper";
import { PageTitle } from "../components/PageTitle";
import { CustomTable } from "../components/CustomTable";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Notification } from "../utils/Notification";
import { useMutation, useQuery } from "@apollo/client";
import { GET_DICTIONARIES } from "../graphql/Queries/Dictionaries";
import { GET_STEPS } from "../graphql/Queries/Steps";
import { DELETE_DICTIONARY } from "../graphql/Mutations/DictionaryMutation";
import { DELETE_STEP } from "../graphql/Mutations/StepMutation";
import { Preload } from "../components/Preload";

export const Steps = () => {
  const [items, setItems] = useState([]);
  const navigator = useNavigate();
  const { loading, error, data } = useQuery(GET_STEPS, {
    fetchPolicy: "no-cache",
  });
  const [deleteDictionary] = useMutation(DELETE_STEP);

  const remove = (id) => {
    deleteDictionary({
      variables: {
        id: Number(id),
      },
    })
      .then((response) => {
        setItems(response.data.deleteStep);
      })
      .catch((error) => {
        Notification(error);
      });
  };

  const edit = (id) => {
    navigator("/steps/form?id=" + id);
  };

  useEffect(() => {
    if (data) {
      setItems(data.steps);
    }
  }, [data]);

  if (!data) {
    return <Preload />;
  }

  return (
    <Wrapper>
      <PageTitle title={"Шаги/Блоки"} />
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <CustomTable
                data={items}
                columns={[
                  { key: "id", label: "ID" },
                  { key: "name", label: "Название", isMultilang: true },
                  {
                    key: "name_system",
                    label: "Название (системное)",
                    isMultilang: true,
                  },
                ]}
                search={true}
                sort={true}
                pagination={{
                  limit: 10,
                }}
                actions={[
                  {
                    slug: "/steps/form",
                    icon: "fe-plus",
                    name: "Создать",
                  },
                ]}
                onClick={(id) => edit(id)}
                onDelete={(id) => remove(id)}
                canDelete={(row) => {
                  return row.is_system === 1;
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
