import React, { Component, useEffect, useState } from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import styles from "./SelectSidebar.module.scss";

export const SelectSidebar = ({
  items,
  values = [],
  value = "id",
  label = "name",
  onChange,
}) => {
  const [selected, setSelected] = useState(values);

  useEffect(() => {
    setSelected(values);
  }, [values]);

  const SortableItem = SortableElement(({ item }) => {
    return (
      <li
        onClick={() => {
          setSelected(selected.filter((el) => el !== item));
        }}
      >
        {item[label]}
      </li>
    );
  });

  const SortableList = SortableContainer(({ items }) => {
    return (
      <ul className={styles.list}>
        {items.map((item, index) => {
          return (
            <SortableItem key={`item-${index}`} index={index} item={item} />
          );
        })}
      </ul>
    );
  });

  useEffect(() => {
    onChange(selected);
  }, [selected]);

  return (
    <div className={styles.selectSidebar}>
      <div className={styles.side}>
        <div className={styles.title}>Все значения</div>
        <ul className={styles.list}>
          {items.map((item) => {
            if (!selected.includes(item)) {
              return (
                <li
                  key={item[value]}
                  onClick={() => {
                    setSelected([...selected, item]);
                  }}
                >
                  {item[label]}
                </li>
              );
            }
          })}
        </ul>
      </div>
      <div className={styles.side}>
        <div className={styles.title}>Выбранные значения</div>
        <SortableList
          pressDelay={200}
          items={selected}
          onSortEnd={({ oldIndex, newIndex, collection, isKeySorting }) => {
            let _items = [...selected];
            let item = _items.splice(oldIndex, 1)[0];
            _items.splice(newIndex, 0, item);
            setSelected(_items);
          }}
        />
      </div>
    </div>
  );
};
