import { gql } from "@apollo/client";

export const EDIT_DICTIONARY = gql`
  mutation ($id: Int!, $name: String!, $options: String!) {
    updateDictionary(id: $id, name: $name, options: $options) {
      id
    }
  }
`;

export const ADD_DICTIONARY = gql`
  mutation ($name: String!, $options: String!) {
    addDictionary(name: $name, options: $options) {
      id
    }
  }
`;

export const DELETE_DICTIONARY = gql`
  mutation ($id: Int!) {
    deleteDictionary(id: $id) {
      id
      name {
        lang
        value
      }
      options {
        id
        key
        value {
          lang
          value
        }
      }
    }
  }
`;
