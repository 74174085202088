import React, { useEffect, useState } from "react";
import { Wrapper } from "../../components/Wrapper";
import { PageTitle } from "../../components/PageTitle";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Notification } from "../../utils/Notification";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Button } from "../../components/Button";
import { Slug } from "../../utils/Slug";
import { MultilangTextInput } from "../../components/Form/MultilangTextInput";
import { Tab, Tabs } from "react-bootstrap";
import { isEmpty } from "lodash";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { GET_DICTIONARY } from "../../graphql/Queries/Dictionaries";
import { toast } from "react-toastify";
import i18next from "i18next";
import {
  ADD_DICTIONARY,
  EDIT_DICTIONARY,
} from "../../graphql/Mutations/DictionaryMutation";
import { Preload } from "../../components/Preload";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import { setItem } from "localforage";

export const Form = ({ id = null }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [value, setValue] = useState({ ru: "", en: "", az: "" });
  const [forSitemap, setForSitemap] = useState({ ru: "", en: "", az: "" });
  const [key, setKey] = useState("");
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const [editDictionary] = useMutation(EDIT_DICTIONARY);
  const [addDictionary] = useMutation(ADD_DICTIONARY);
  const { load, error, data } = useQuery(GET_DICTIONARY, {
    variables: {
      id: Number(searchParams.get("id")),
    },
    fetchPolicy: "no-cache",
  });
  if (error) {
    toast.error(error.message);
  }
  const languages = [
    {
      url: "ru",
      label: t("Ру"),
    },
    {
      url: "az",
      label: t("Az"),
    },
    {
      url: "en",
      label: t("En"),
    },
  ];
  const defaultLang = i18next.language;
  const formik = useFormik({
    initialValues: {
      name: {
        ru: "",
        az: "",
        en: "",
      },
      options: [],
    },
    validationSchema: Yup.object({
      name: Yup.object().shape({
        ru: Yup.string().required(t("Объязательное поле")),
      }),
    }),
    onSubmit: (values) => {
      if (values.options.length === 0) {
        Notification(t("Вы должны добавить хотя бы одно значение"));
        return;
      }
      setLoading(true);
      if (Number(formik.values.id) > 0) {
        editDictionary({
          variables: {
            id: Number(searchParams.get("id")),
            name: JSON.stringify(formik.values.name),
            options: JSON.stringify(formik.values.options),
          },
        })
          .then((response) => {
            if (!response.data.updateDictionary) {
              Notification(t("Ошибка сохранения"));
            } else {
              navigate("/dictionaries");
              Notification(t("Элемент отредактирован"), "success");
            }
          })
          .catch((error) => {
            Notification(error);
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        addDictionary({
          variables: {
            name: JSON.stringify(formik.values.name),
            options: JSON.stringify(formik.values.options),
          },
        })
          .then((response) => {
            if (!response.data.addDictionary) {
              Notification(t("Ошибка сохранения"));
            } else {
              navigate("/dictionaries");
              Notification(t("Элемент добавлен"), "success");
            }
          })
          .catch((error) => {
            Notification(error);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    },
  });
  const DragHandle = SortableHandle(() => (
    <span style={{ marginRight: "10px", cursor: "move" }}>
      <i className={"fe-menu"} />{" "}
    </span>
  ));
  const SortableItem = SortableElement(({ option, sortIndex }) => {
    return (
      <div className={"drag-row-flex"} key={sortIndex}>
        <div>
          <DragHandle />
          {option.value[defaultLang]}
        </div>
        <div>{option.key}</div>
        <div>
          <button
            className={"btn btn-info btn-sm"}
            style={{ marginRight: 5 }}
            type={"button"}
            onClick={() => {
              let indx = null;
              formik.values.options.map((item, i) => {
                if (option.id === item.id) {
                  indx = i;
                }
              });
              setValue(formik.values.options[indx].value);
              setKey(formik.values.options[indx].key);
              setForSitemap(formik.values.options[indx].for_sitemap);
              const _options = [...formik.values.options];
              _options.splice(indx, 1);
              formik.setFieldValue("options", _options);
            }}
          >
            <i className={"fe-edit"} />
          </button>
          <button
            className={"btn btn-danger btn-sm"}
            type={"button"}
            onClick={() => {
              let indx = null;
              formik.values.options.map((item, i) => {
                if (option.id === item.id) {
                  indx = i;
                }
              });
              const _options = [...formik.values.options];
              _options.splice(indx, 1);
              formik.setFieldValue("options", _options);
            }}
          >
            <i className={"fe-trash"} />
          </button>
        </div>
      </div>
    );
  });
  const SortableList = SortableContainer(({ items }) => {
    return (
      <div className={"table-flex"}>
        {items.map((value, index) => {
          return (
            <SortableItem
              key={`item-${index}`}
              option={value}
              index={index}
              sortIndex={index}
            />
          );
        })}
      </div>
    );
  });

  useEffect(() => {
    if (data && data.dictionaries.length > 0) {
      data.dictionaries[0].name.map((dic) => {
        formik.setFieldValue(`name.${dic.lang}`, dic.value);
      });
      const opt = data.dictionaries[0].options.map((op) => {
        const _val = {};
        const _sitemap = {};
        op.value.map((op_value) => {
          _val[op_value.lang] = op_value.value;
        });
        op.for_sitemap.map((op_value) => {
          _sitemap[op_value.lang] = op_value.value;
        });
        op.value = _val;
        op.for_sitemap = _sitemap;
        return op;
      });
      formik.setFieldValue("options", opt);
      formik.setFieldValue("id", data.dictionaries[0].id);
    }
  }, [data]);

  if (!data) {
    return <Preload />;
  }

  return (
    <Wrapper>
      <PageTitle
        title={id === null ? "Добавить справочник" : "Редактировать справочник"}
        breadcrumbs={[
          {
            slug: "/dictionaries",
            name: "Справочники",
          },
        ]}
      />
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <form className="row" onSubmit={formik.handleSubmit}>
                <div className="col-md-6">
                  <MultilangTextInput
                    label={"Название"}
                    value={formik.values.name}
                    onChange={(text, lang) => {
                      formik.setFieldValue(`name.${lang}`, text);
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.errors.name}
                    name={"name"}
                  />
                </div>
                <div className="col-md-12">
                  <div style={{ marginBottom: "30px" }}>
                    <SortableList
                      lockAxis={"y"}
                      useDragHandle
                      helperClass={"drag-row-flex"}
                      items={formik.values.options}
                      onSortEnd={({ oldIndex, newIndex, item }) => {
                        const _options = [...formik.values.options];
                        const _tmp = _options[oldIndex];
                        _options[oldIndex] = _options[newIndex];
                        _options[newIndex] = _tmp;
                        formik.setFieldValue("options", _options);
                      }}
                    />
                  </div>
                  <div className="row mb-5">
                    <div className="col-md-5">
                      <div className="row">
                        <label
                          className="col-md-4 col-form-label text-end mt-4"
                          htmlFor="simpleinput"
                        >
                          {t("Значение")}
                        </label>
                        <div className="col-md-8">
                          <Tabs
                            defaultActiveKey={languages[0].url}
                            className={"multilang-tab"}
                          >
                            {languages.map((lang) => {
                              return (
                                <Tab
                                  eventKey={lang.url}
                                  key={lang.url}
                                  title={lang.label}
                                >
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={value[lang.url]}
                                    onChange={(event) => {
                                      const _value = { ...value };
                                      _value[lang.url] = event.target.value;
                                      setValue(_value);
                                    }}
                                  />
                                </Tab>
                              );
                            })}
                          </Tabs>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <label
                          className="col-md-4 col-form-label text-end mt-4"
                          htmlFor="simpleinput"
                        >
                          {t("Для sitemap")}
                        </label>
                        <div className="col-md-8">
                          <Tabs
                            defaultActiveKey={languages[0].url}
                            className={"multilang-tab"}
                          >
                            {languages.map((lang) => {
                              return (
                                <Tab
                                  eventKey={lang.url}
                                  key={lang.url}
                                  title={lang.label}
                                >
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={forSitemap[lang.url]}
                                    onChange={(event) => {
                                      const _value = { ...forSitemap };
                                      _value[lang.url] = event.target.value;
                                      setForSitemap(_value);
                                    }}
                                  />
                                </Tab>
                              );
                            })}
                          </Tabs>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="row mt-4">
                        <label
                          className="col-md-4 col-form-label text-end"
                          htmlFor="simpleinput"
                        >
                          {t("Ключ")}
                        </label>
                        <div className="col-md-8">
                          <div className="input-group input-group-merge">
                            <input
                              type="text"
                              onInput={(event) => setKey(event.target.value)}
                              className="form-control"
                              value={key}
                            />
                            <div
                              className="input-group-text"
                              onClick={() => {
                                setKey(Slug(value[defaultLang]));
                              }}
                            >
                              <span className="fe-link"></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <button
                        className={"btn btn-info mt-4"}
                        type={"button"}
                        onClick={() => {
                          if (isEmpty(value) || key === "") {
                            Notification(t("Укажите значение и ключ"));
                          } else {
                            formik.values.options.push({
                              value,
                              key,
                              for_sitemap: forSitemap,
                            });
                            setValue({ ru: "", en: "", az: "" });
                            setForSitemap({ ru: "", en: "", az: "" });
                            setKey("");
                          }
                        }}
                      >
                        {t("Добавить")}
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 text-center">
                  <Button
                    label={"Сохранить"}
                    loading={loading}
                    style={"btn btn-primary w-md"}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
