import { gql } from "@apollo/client";

export const GET_NEWS = gql`
  query {
    news {
      id
      title {
        lang
        value
      }
    }
  }
`;

export const GET_ONE_NEWS = gql`
  query ($id: Int!, $lang: String) {
    news(id: $id) {
      id
      title {
        lang
        value
      }
      text {
        lang
        value
      }
      image
      slug
      categories
      tags
      public_datetime
      description {
        lang
        value
      }
    }
    categoryNews {
      id
      nameString(lang: $lang)
    }
    tagsNews {
      id
      nameString(lang: $lang)
    }
  }
`;

export const GET_CATEGORY_NEWS = gql`
  query ($id: Int) {
    categoryNews(id: $id) {
      id
      name {
        lang
        value
      }
    }
  }
`;

export const GET_TAGS_NEWS = gql`
  query ($id: Int) {
    tagsNews(id: $id) {
      id
      name {
        lang
        value
      }
    }
  }
`;
