import { gql } from "@apollo/client";

export const MODIFY_PROPERTY = gql`
  mutation (
    $id: Int!
    $name: String!
    $property_group_id: Int
    $type: Int!
    $dictionary_id: Int
    $content_type: Int!
    $hint: String
    $hint_text: String
    $is_require: Boolean!
    $display: Int!
    $error_message: String
    $mask: String
    $label_icon: String
    $show_in_filter: Boolean!
    $max_length: Int
    $size: Int!
    $parent_id: Int
    $key: String!
    $raw_query: String
  ) {
    modifyProperty(
      id: $id
      name: $name
      property_group_id: $property_group_id
      type: $type
      dictionary_id: $dictionary_id
      content_type: $content_type
      hint: $hint
      is_require: $is_require
      display: $display
      error_message: $error_message
      mask: $mask
      label_icon: $label_icon
      show_in_filter: $show_in_filter
      hint_text: $hint_text
      max_length: $max_length
      size: $size
      parent_id: $parent_id
      key: $key
      raw_query: $raw_query
    )
  }
`;

export const DELETE_PROPERTY = gql`
  mutation ($id: Int!, $lang: String!) {
    deleteProperty(id: $id) {
      id
      name(lang: $lang) {
        lang
        value
      }
      property_group {
        id
        name(lang: $lang) {
          lang
          value
        }
      }
      type
      content_type
      is_require
    }
  }
`;
