import { gql } from "@apollo/client";

export const GET_SEO = gql`
  query ($table: String!, $row_id: Int!) {
    seo(table: $table, row_id: $row_id) {
      title {
        lang
        value
      }
      keywords {
        lang
        value
      }
      description {
        lang
        value
      }
      image {
        lang
        value
      }
      h1 {
        lang
        value
      }
      follow
      index
    }
  }
`;
