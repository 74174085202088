import i18next from "i18next";

export const getField = (item, field) => {
  const lang = i18next.language;
  let data = null;
  data = item.find((element) => {
    return element.lang === lang;
  });
  if (!data) {
    data = item.find((element) => {
      return element.lang === "az";
    });
  }
  return data[field];
};

export const formatRowLang = (data) => {
  if (typeof data === "object") {
    return data;
  }
  const resp = [];
  ["ru", "az", "en"].map((lang) => {
    const value = data.find((i) => i.lang === lang);
    resp[lang] = value ? value.value : "";
  });
  return resp;
};
